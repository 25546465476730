import { createContext, PropsWithChildren, useCallback, useContext, useRef } from 'react'

import md5 from 'md5'
import { CombinedError } from 'urql'

import {
  BargeNominationInput,
  CreateBargeNominationMutation,
  useCreateBargeNominationMutation,
} from '../generated/graphql'

export const getVariablesHash = (variables: BargeNominationInput) => md5(JSON.stringify(variables))

export type NominationResult = {
  data?: CreateBargeNominationMutation
  error?: CombinedError
}

type State = {
  nominate: (input: BargeNominationInput) => Promise<NominationResult>
  getNomination: (input: BargeNominationInput) => Promise<NominationResult>
}

export const Context = createContext({} as State)

type Cache = Map<string, { mutation?: Promise<NominationResult>; timestamp?: number; data?: NominationResult['data'] }>

export const useLatestNominationContext = () => useContext(Context)

async function createBargeNomination(
  cache: Cache,
  nominate: (input: {
    bargeNomination: BargeNominationInput
  }) => Promise<{ data?: CreateBargeNominationMutation; error?: CombinedError }>,
  bargeNomination: BargeNominationInput
) {
  const hash = getVariablesHash(bargeNomination)
  const mutation = nominate({ bargeNomination })

  cache.set(hash, { mutation })

  const { data, error } = await mutation

  if (data) {
    cache.set(hash, {
      timestamp: Date.now(),
      data,
    })
  }

  if (error !== undefined) {
    return { error }
  }

  return { data }
}

function getNomination(
  cache: Cache,
  nominate: (input: {
    bargeNomination: BargeNominationInput
  }) => Promise<{ data?: CreateBargeNominationMutation; error?: CombinedError }>,
  bargeNomination: BargeNominationInput
) {
  const hash = getVariablesHash(bargeNomination)

  if (cache.has(hash)) {
    const cached = cache.get(hash)!

    const { timestamp, mutation, data } = cached

    if (timestamp && Date.now() < 15 * 60_000 + timestamp) {
      return Promise.resolve({ data })
    }

    if (mutation) {
      return mutation
    }
  }

  return createBargeNomination(cache, nominate, bargeNomination)
}

export function LatestNominationProvider({ children }: PropsWithChildren) {
  const cache = useRef<Cache>(new Map())

  const [, createBargeNominationMutation] = useCreateBargeNominationMutation()

  const nominate = useCallback(
    (bargeNomination: BargeNominationInput) =>
      createBargeNomination(cache.current, createBargeNominationMutation, bargeNomination),
    [createBargeNominationMutation]
  )

  const getNominationCallback = useCallback(
    (bargeNomination: BargeNominationInput) =>
      getNomination(cache.current, createBargeNominationMutation, bargeNomination),
    [createBargeNominationMutation]
  )

  return <Context.Provider value={{ nominate, getNomination: getNominationCallback }}>{children}</Context.Provider>
}
