import { PropsWithChildren, useEffect, useMemo, useState } from 'react'

import { Delete, Download, IosShare, Loop, Save } from '@mui/icons-material'

import { isAcceptanceEnv, isProductionEnv } from '../../../constants/env-vars'
import {
  createNominationStops,
  NominatedTow,
  NominatedTowStop,
  SavedNomination,
  UserBargeNomination,
} from '../../../Domain/Nomination'
import { equals } from '../../../Domain/River'
import { GoalId, HubLikeId, LaneId, RiverLocationLite, TboSubmissionStatusId } from '../../../generated/graphql'
import { Nomination } from '../index'
import { Journey } from '../Journey'
import { setRiverCodeParam, useMilePointSelection } from '../Journey/useMilePointSelection'
import { NominationSummary } from '../NominationSummary'

import styles from './NominationPage.module.scss'

type Props = {
  nomination: UserBargeNomination | SavedNomination
  hasReviews?: boolean
  tboSubmissionStatus?: TboSubmissionStatusId
  includeTBOs?: number[]
}

const getBargesInTow = (stops: NominatedTowStop[], milePoint: RiverLocationLite) =>
  stops.find(stop => equals(milePoint, stop))?.inTowBarges

const canBeSubmitted = (tboSubmissionStatus: TboSubmissionStatusId | undefined) => {
  const submittableStatuses = [TboSubmissionStatusId.NotSubmitted, TboSubmissionStatusId.Failed]
  return tboSubmissionStatus && submittableStatuses.indexOf(tboSubmissionStatus) >= 0
}

function Page({
  children,
  hasReviews = false,
  nomination: { recordTime, userRequest, tows },
  tboSubmissionStatus = TboSubmissionStatusId.NotSubmitted,
}: PropsWithChildren<Props>) {
  const {
    towConfiguration,
    bargeFilters: { lane, towOrigin, towDestination, includeTBOs },
  } = userRequest

  const { milePoint } = useMilePointSelection()

  const timeWindowDisplay = userRequest.bargeFilters.expectedDepartureTime

  const numberOfStrings = towConfiguration.numberOfStrings ?? null

  return (
    <>
      {tows.map((tow, index) => (
        <TowNomination
          key={`${tow.boat?.name}-${index}`}
          hasReviews={hasReviews}
          tow={tow}
          selectedStop={milePoint}
          lane={lane}
          recordTime={recordTime}
          origin={towOrigin}
          destination={towDestination}
          goal={towConfiguration.goal}
          hasTurnboat={tow.hasTurnboat}
          boat={tow.boat?.name ?? undefined}
          tboSubmissionStatus={tboSubmissionStatus}
          timeWindow={timeWindowDisplay}
          numberOfStrings={numberOfStrings}
          includeTBOs={includeTBOs}>
          {children}
        </TowNomination>
      ))}
    </>
  )
}

type TowNominationProps = PropsWithChildren<{
  tow: NominatedTow
  hasReviews: boolean
  selectedStop?: RiverLocationLite
  lane: LaneId
  origin: HubLikeId
  destination: HubLikeId
  goal: GoalId
  recordTime: Date
  hasTurnboat: boolean
  boat?: string
  tboSubmissionStatus: TboSubmissionStatusId
  timeWindow?: Date
  numberOfStrings: number | null
  includeTBOs: number[]
}>

function TowNomination({
  children,
  tow,
  hasReviews,
  selectedStop,
  goal,
  lane,
  origin,
  destination,
  recordTime,
  tboSubmissionStatus,
  timeWindow,
  numberOfStrings,
  includeTBOs,
}: TowNominationProps) {
  const stops = useMemo(() => createNominationStops(tow), [tow])

  const bargesInTow = useMemo(
    () => (selectedStop ? getBargesInTow(stops, selectedStop) : undefined),
    [selectedStop, stops]
  )
  const bargesAtStop = useMemo(
    () => (bargesInTow ? tow.barges.filter(({ id }) => bargesInTow.includes(id)) : tow.barges),
    [tow, bargesInTow]
  )

  const remainingBargeMiles = useMemo(() => {
    let totalBargeMiles = 0
    let index = 0

    if (selectedStop) {
      const selectedIndex = stops.findIndex(s => equals(s, selectedStop))
      index = selectedIndex > -1 ? selectedIndex : index
    }

    for (let i = index; i < stops.length - 1; i++) {
      const stop = stops[i]
      totalBargeMiles += stop.distanceToNextStop * stop.inTowBarges.length
    }

    return totalBargeMiles
  }, [stops, selectedStop])

  const { transitTimeMinutes } = useMemo(
    () =>
      tow.stopsWithMetrics.reduce(
        (acc: { transitTimeMinutes: number }, { travelMinutesToNextStop }) => ({
          ...acc,
          transitTimeMinutes: acc.transitTimeMinutes + (travelMinutesToNextStop ?? 0),
        }),
        { transitTimeMinutes: 0 }
      ),

    [tow.stopsWithMetrics]
  )

  const bargesAtStopMax = useMemo(() => {
    return stops.reduce((max, stop) => Math.max(max, stop.inTowBarges.length), 0)
  }, [stops])

  return (
    <div className={styles.container}>
      <div className={styles.nominationSections}>
        <div className={styles.timeline}>
          <Journey
            selectedStop={selectedStop}
            handleSelectStop={setRiverCodeParam}
            stops={stops}
            boat={tow.boat?.name ?? ''}
          />
        </div>
        <Nomination hasReviews={hasReviews} barges={bargesAtStop} />
      </div>
      <aside className={styles.aside}>
        {children}
        <NominationSummary
          lane={lane}
          origin={origin}
          destination={destination}
          createdAt={recordTime}
          goal={goal}
          transitTimeMinutes={transitTimeMinutes}
          barges={bargesAtStop}
          score={tow.efficiencyMetric}
          tbnBarges={tow.tbnBarges}
          numberOfStops={tow.stopsWithMetrics.length}
          remainingBargeMiles={remainingBargeMiles}
          hasTurnboat={tow.hasTurnboat}
          boat={tow.boat?.name ?? ''}
          tboSubmissionStatus={tboSubmissionStatus}
          timeWindow={timeWindow}
          bargesAtStopMax={bargesAtStopMax}
          numberOfStrings={numberOfStrings}
          includeTBOs={includeTBOs}
        />
      </aside>
    </div>
  )
}

export const SavedNominationPage = ({
  nomination,
  handleDeleteNomination,
  isDeleting,
  isSubmitting,
  handleFeedbackExport,
  handleUpdatingNominationBarges,
  tboSubmissionStatus,
  handleSubmitNomination,
}: Props & {
  isDeleting: boolean
  isSubmitting: boolean
  handleDeleteNomination: () => void
  handleUpdatingNominationBarges: () => void
  handleFeedbackExport: () => void
  handleSubmitNomination: () => void
}) => (
  <Page nomination={nomination} hasReviews tboSubmissionStatus={tboSubmissionStatus}>
    <div className={styles.actions}>
      <button className={styles.deleteButton} onClick={handleDeleteNomination}>
        <span>Delete nomination</span>
        {isDeleting ? <span className={styles.isProcessing} /> : <Delete />}
      </button>
      <button className={styles.updateButton} onClick={handleUpdatingNominationBarges}>
        <span>Replace unavailable barges</span>
        <Loop />
      </button>
      {canBeSubmitted(tboSubmissionStatus) && !isAcceptanceEnv && !isProductionEnv ? (
        <button className={styles.updateButton} onClick={handleSubmitNomination}>
          <span>Submit to TBO</span>
          {isSubmitting ? <span className={styles.isProcessing} /> : <IosShare />}
        </button>
      ) : null}
      <ExportFeedbackButton handleClick={handleFeedbackExport} />
    </div>
  </Page>
)

export const LatestNominationPage = ({
  nomination,
  handleUpdateNomination,
  isUpdating,
  handleFeedbackExport,
}: Props & {
  isUpdating: boolean
  handleUpdateNomination: () => void
  handleFeedbackExport: () => void
}) => (
  <Page nomination={nomination}>
    <div className={styles.actions}>
      <button className={styles.updateButton} onClick={handleUpdateNomination}>
        <span>Update (last) saved nomination</span>
        {isUpdating ? <span className={styles.isProcessing} /> : <Loop />}
      </button>
      <ExportFeedbackButton handleClick={handleFeedbackExport} />
    </div>
  </Page>
)

export const NewNominationPage = ({
  nomination,
  isSaving,
  handleSaveNomination,
  handleFeedbackExport,
}: Props & {
  handleFeedbackExport: () => void
  handleSaveNomination: () => void
  isSaving: boolean
}) => {
  const [saved, setSaved] = useState(false)

  const [isNewNomination, setIsNewNomination] = useState(true)

  const handleSave = async () => {
    await handleSaveNomination()
    setSaved(true)
  }

  useEffect(() => {
    if (isSaving) {
      setSaved(false)
    }
  }, [isSaving])

  useEffect(() => {
    if (nomination) {
      setIsNewNomination(false)
    }
    setSaved(false)
  }, [nomination])

  const buttonClass = saved ? styles.disabled : ''

  return (
    <Page nomination={nomination}>
      <div className={styles.actions}>
        <button onClick={handleSave} disabled={saved} className={`${styles.saveButton} ${buttonClass}`}>
          <span>{saved && !isNewNomination ? 'Saved Nomination' : 'Save nomination'}</span>
          {isSaving ? <span className={styles.isProcessing} /> : <Save />}
        </button>
        <ExportFeedbackButton handleClick={handleFeedbackExport} />
      </div>
    </Page>
  )
}

const ExportFeedbackButton = ({ handleClick }: { handleClick: () => void }) => (
  <button onClick={handleClick}>
    <span>Export feedback data</span>
    <Download />
  </button>
)
