import { PropsWithChildren } from 'react'

import { Dialog } from '@headlessui/react'
import classnames from 'classnames'

import styles from './Modal.module.scss'

export type ModalProps = {
  isOpen: boolean
  onClose: () => void
  className?: string
}

export const Modal = ({ children, isOpen, onClose, className }: PropsWithChildren<ModalProps>) => (
  <Dialog open={isOpen} onClose={onClose}>
    <div className={styles.modalParent}>
      <div className={styles.backdrop} onClick={onClose} />
      <Dialog.Panel
        className={classnames(styles.modal, className)}
        role="dialog"
        aria-hidden={isOpen ? 'false' : 'true'}>
        {children}
      </Dialog.Panel>
    </div>
  </Dialog>
)
