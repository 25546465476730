import { useCallback } from 'react'

import classnames from 'classnames'

import { Checkbox } from '../../../ui/Checkbox/Checkbox'

import styles from './NominationForm.module.scss'
import { TowNominationFormInput } from './TowConfigurationSection'

type PreselectedBargesSectionProps = {
  className?: string
  preselectedBarges: Array<{ id: string; name: string | null }>
  towConfiguration: TowNominationFormInput
  setTowConfiguration: (config: TowNominationFormInput) => void
}

export function PreselectedBargesSection({
  className,
  preselectedBarges,
  towConfiguration,
  setTowConfiguration,
}: PreselectedBargesSectionProps) {
  const preSelect = useCallback(
    (id: string) => {
      const updatedConfig: TowNominationFormInput = {
        ...towConfiguration,
        preselectedBarges: towConfiguration.preselectedBarges.includes(id)
          ? towConfiguration.preselectedBarges.filter(b => b !== id)
          : [...towConfiguration.preselectedBarges, id],
      }

      setTowConfiguration(updatedConfig)
    },
    [setTowConfiguration, towConfiguration]
  )

  return (
    <table className={classnames(styles.matrix, className)}>
      <thead>
        <tr>
          <td>Barge</td>
        </tr>
      </thead>
      <tbody>
        {preselectedBarges.map(({ id, name }) => (
          <tr key={id} className={styles.row}>
            <td data-testid={id}>{name}</td>
            <td>
              <Checkbox
                checked={towConfiguration.preselectedBarges.includes(id)}
                label=""
                onChange={change => {
                  if (change) {
                    preSelect(id)
                  }
                }}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
