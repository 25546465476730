import React, { type FC, type PropsWithChildren } from 'react'

import CheckIcon from '@mui/icons-material/Check'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

import styles from './styles.module.scss'

import type { StageSelectionActions } from './types'

type SummaryBlockProps = StageSelectionActions & {
  title: string
  isValid: boolean
}

const SummaryBlock: FC<PropsWithChildren<SummaryBlockProps>> = ({ title, self, isValid, children }) => {
  return (
    <div onClick={self} className={styles.summaryBlock}>
      <div className={styles.blockTitle}>
        <div>{title}</div>
        <div>
          {isValid ? <CheckIcon className={styles.iconValid} /> : <ErrorOutlineIcon className={styles.iconInvalid} />}
        </div>
      </div>
      <div className={styles.summaryContainer}>{children}</div>
    </div>
  )
}

export default SummaryBlock
