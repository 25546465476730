import { PropsWithChildren, useState, useCallback, useMemo, useEffect } from 'react'

import { DownloadOutlined, CopyAll } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import classNames from 'classnames'

import styles from './Layout.module.scss'

export const Wrapper: React.FC<PropsWithChildren> = ({ children }) => <div className={styles.wrapper}>{children}</div>

export const LayoutSection: React.FC<PropsWithChildren<{ className?: string }>> = ({ children, className }) => (
  <div className={classNames(styles.section, className)}>{children}</div>
)

export const LayoutSectionHeader: React.FC<PropsWithChildren<{ className?: string }>> = ({ children, className }) => (
  <div className={classNames(styles.sectionHeader, className)}>{children}</div>
)

export const ExportCSV: React.FC<{ className?: number; exportCSV: () => void }> = ({ exportCSV }) => (
  <Tooltip title="Export data to CSV" placement="top">
    <button type="button" className={styles.tableActionButton} onClick={exportCSV}>
      <span className="sr-only">Export data to CSV</span>
      <DownloadOutlined />
    </button>
  </Tooltip>
)

export function CopyNames({ getNames }: { getNames: () => string }) {
  const [open, setOpen] = useState(false)
  const [copied, setCopied] = useState(false)

  const onOpen = useCallback(() => setOpen(true), [])
  const onClose = useCallback(() => {
    if (!copied) {
      setOpen(false)
    }
  }, [copied])

  const title = useMemo(() => (copied ? 'Copied barge names ✔' : 'Copy barge names  from list'), [copied])

  useEffect(() => {
    if (!copied) {
      return undefined
    }

    const timeout = setTimeout(() => setCopied(false), 1000)

    return () => clearTimeout(timeout)
  }, [copied])

  return (
    <Tooltip open={open} onOpen={onOpen} onClose={onClose} title={title} placement="top">
      <button
        type="button"
        className={styles.tableActionButton}
        onClick={() => {
          navigator.clipboard.writeText(getNames())
          setCopied(true)
        }}>
        <CopyAll />
      </button>
    </Tooltip>
  )
}
