import { PropsWithChildren } from 'react'

import { Link } from 'wouter'

import { isAcceptanceEnv, isTestEnv } from '../../constants/env-vars'
import { Ribbon } from '../../ui/Ribbon/Ribbon'

import styles from './Account.module.scss'

export const Layout: React.FC<PropsWithChildren> = ({ children }) => (
  <>
    <div className={styles.layout}>
      <div className={styles.splash} />
      <div className={styles.main}>
        <Link className={styles.header} to="/">
          <img className={styles.logo} alt="barge nomination" src="/assets/logo.png" />
          <h2 className={styles.title}>Barge Nomination</h2>
        </Link>
        {children}
      </div>
    </div>
    {isAcceptanceEnv || isTestEnv ? <Ribbon /> : null}
  </>
)
