import { PropsWithChildren, useState } from 'react'

import classnames from 'classnames'

import { NavigationDrawer } from '../../components/Header/NavigationDrawer/NavigationDrawer'
import { PrimaryMenu } from '../../components/Header/PrimaryMenu/PrimaryMenu'

import styles from './Layout.module.scss'

type RenderProps = { isDrawerOpen: boolean; setIsDrawerOpen: (b: boolean) => void }

type Props = PropsWithChildren<{ primaryMenu?: (p: RenderProps) => JSX.Element; className?: string }>

export function Layout({ children, primaryMenu, className }: Props) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  return (
    <main className={styles.layout}>
      {primaryMenu ? (
        <>{primaryMenu({ isDrawerOpen, setIsDrawerOpen })}</>
      ) : (
        <PrimaryMenu isDrawerOpen={isDrawerOpen} setDrawerOpen={setIsDrawerOpen} />
      )}
      <NavigationDrawer isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} />
      <div className={classnames(styles.content, className)}>{children}</div>
    </main>
  )
}
