import React, { PropsWithChildren, FC } from 'react'

import { ChevronRight } from '@mui/icons-material'
import { Link } from 'wouter'

import styles from './NavigationItem.module.scss'

type NavigationItemProps = PropsWithChildren<
  Readonly<{
    text: string
  }>
>

type NavigationButtonProps = Readonly<{
  text: string
  onClick?: () => void
}>

type NavigationLinkProps = PropsWithChildren<Readonly<{ title?: string; href: string }>>

const SideMenuItemText: FC<PropsWithChildren> = ({ children }: PropsWithChildren) => (
  <span className={styles.sideMenuItemText}>{children}</span>
)

export const NavigationItem: FC<NavigationItemProps> = ({ children, text }: NavigationItemProps) => (
  <div className={styles.sideMenuItem}>
    <div className={styles.sideMenuItemWrapper}>
      <SideMenuItemText>{text}</SideMenuItemText>
      {children}
    </div>
  </div>
)

export const NavigationButton: FC<NavigationButtonProps> = (props: NavigationButtonProps) => (
  <button className={styles.sideMenuItem} onClick={props.onClick}>
    <div className={styles.sideMenuItemWrapper}>
      <SideMenuItemText>
        <span>{props.text}</span>
        <ChevronRight />
      </SideMenuItemText>
    </div>
  </button>
)

export const NavigationLink: FC<NavigationLinkProps> = ({ title, href, children }: NavigationLinkProps) => (
  <Link href={href}>
    <a href="/" className={styles.sideMenuItem}>
      <div className={styles.sideMenuItemWrapper}>
        <SideMenuItemText>{title ?? children}</SideMenuItemText>
      </div>
    </a>
  </Link>
)
