import { Link } from 'wouter'

import { docsPagePath } from '../../paths'

import { P } from './Components/Components'

export const NominationPoliciesDoc = () => (
  <>
    <h1>Nomination Policies</h1>
    <h2 data-section="overview">Overview</h2>
    <P>
      This document explains the core set of nomination policies currently implemented in the optimization algorithm of
      the barge nomination engine. Different <Link href={docsPagePath('operational-goals')}>operational goals</Link> are
      targeted through different settings of importance weights distributed across the nomination policies.
    </P>
    <P>
      In the barge nomination engine, each operational goal is defined by a series of default weights that are allocated
      across a common list of nomination policies. Each policy gives an instruction to the engine on how strongly or
      weakly to consider that policy in the overall optimization function.
    </P>
    <P>
      In the section below are the details of the nomination policies that are given relative weights under each
      optimization goal.
    </P>
    <h2 data-section="policy-details">Policy details</h2>
    <P>
      For each policy, there is a <i>metric</i>, an <i>optimization</i> function, a <i>level</i>, and possible an
      operational goal that the it is the <i>primary policy</i> for.
    </P>
    <P>
      The metric is that calculated value that is used in the optimization function for finding the optimal nomination.
      The level at which the metric applies is further explained in the{' '}
      <Link href={`${docsPagePath('nomination-policies')}/s/advanced`}>Advance points</Link> section below.
    </P>
    <h3>Barge-level policies</h3>
    <h4>LIFO (Last in–first out) a.k.a. Time in fleet (min)</h4>
    <P>
      <ul>
        <li>
          <i>Description</i>: The most recent barges to enter an origin hub fleet are more likely to be easily
          accessible for building a tow
        </li>
        <li>
          <i>Metric</i>: “Time in fleet” – cumulative time a barge has spent in fleet at facility from its arrival event
          to the current time
        </li>
        <li>
          <i>Optimization</i>: Maximize the sum of times in fleet
        </li>
        <li>
          <i>Level</i>: Barge-level metric
        </li>
        <li>
          <i>Primary policy of</i>: <Link href={docsPagePath('fleet-efficiency')}>Fleet Efficiency</Link>
        </li>
      </ul>
    </P>
    <h4>FIFO (First in–first out) a.k.a. Time in fleet (max)</h4>
    <P>
      <ul>
        <li>
          <i>Description</i>: The barges that have been in fleet longest are more urgent to nominate so that they can
          continue their trip.
          <P>
            <i>NOTE</i>: A negative weight for this policy is identical to a positive weight in the LIFO policy, and
            vice versa.
          </P>
        </li>
        <li>
          <i>Metric</i>: “Time in fleet” – cumulative time a barge has spent in fleet at facility from its arrival event
          to the current time
        </li>
        <li>
          <i>Optimization</i>: Minimize the sum of times in fleet
        </li>
        <li>
          <i>Level</i>: Barge-level metric
        </li>
      </ul>
    </P>
    <h4>Distance to origin</h4>
    <P>
      <ul>
        <li>
          <i>Description</i>: Barges whose current location is closer to an origin hub point are typically easier to
          efficiently gather for building a tow
        </li>
        <li>
          <i>Metric</i>: “Distance to origin” – the distance from a barge’s current location and the relevant origin
          point for building the tow
        </li>
        <li>
          <i>Optimization</i>: Minimize the sum of distances to origin
        </li>
        <li>
          <i>Level</i>: Barge-level metric
        </li>
        <li>
          <i>Primary policy of</i>: <Link href={docsPagePath('fleet-efficiency')}>Fleet Efficiency</Link>
        </li>
      </ul>
    </P>
    <h4>Least buried barges</h4>
    <P>
      <ul>
        <li>
          <i>Description</i>: Barges whose buried indicator is low (or, nomination lists of barges whose buried
          indicator is low) are more accessible for building a tow
        </li>
        <li>
          <i>Metric</i>: “Barge buried indicator” –how many moves are estimated to be needed to retrieve each barge
          individually
        </li>
        <li>
          <i>Optimization</i>: Minimize the sum of barge buried indicators
        </li>
        <li>
          <i>Level</i>: Barge-level metric
        </li>
      </ul>
    </P>
    <h4>Maximize barge-miles</h4>
    <P>
      <ul>
        <li>
          <i>Description</i>: All else being equal, nomination lists should transport more barges more miles
        </li>
        <li>
          <i>Metric</i>: “Barge-miles” – the number of miles a barge is projected to be transported from pickup to
          drop-off
        </li>
        <li>
          <i>Optimization</i>: Maximize the sum of barge-miles in a plan
        </li>
        <li>
          <i>Level</i>: Barge-level metric
        </li>
      </ul>
    </P>
    <h3>Leg-level policies</h3>
    <h4>Rake evenness</h4>
    <P>
      <ul>
        <li>
          <i>Description</i>: All else being equal, a nomination list with an even number of rakes is preferable to a
          list with an odd number because it is easier to assemble a hydrodynamic tow when there are even number of
          rakes to use.
        </li>
        <li>
          <i>Metric</i>: “Number of rakes” – the count of rake barges in a list
        </li>
        <li>
          <i>Optimization</i>: Number of rakes modulo 2 (this is an equation that equals a penalty of zero when number
          of rakes is even, and penalty of one when number of rakes is odd)
        </li>
        <li>
          <i>Level</i>: Leg-level metric
        </li>
      </ul>
    </P>
    <h4>Rake–box balance</h4>
    <P>
      <ul>
        <li>
          <i>Description</i>: All else being equal, the more balanced the number of rakes and the number of boxes in a
          list, the better.
        </li>
        <li>
          <i>Metric</i>: “Rake–box difference” – absolute value of the number of rake barges minus the number of box
          barges in a list
        </li>
        <li>
          <i>Optimization</i>: Minimize the rake–box difference
        </li>
        <li>
          <i>Level</i>: Leg-level metric
        </li>
      </ul>
    </P>
    <h3>List-level policies</h3>
    <h4>Final destinations (min)</h4>
    <P>
      <ul>
        <li>
          <i>Description</i>: Keeping the number of final destinations in a list to a lower number makes it more likely
          the engine will prefer to nominate barges that can travel together for the rest of their remaining trip to
          destination
        </li>
        <li>
          <i>Metric</i>: “Number of final destinations” – the count of final destination facilities in the barges’ trip
          information
        </li>
        <li>
          <i>Optimization</i>: Minimize the number of final destinations
        </li>
        <li>
          <i>Level</i>: List-level metric
        </li>
        <li>
          <i>Primary policy of</i>: <Link href={docsPagePath('build-to-destination')}>Build to Destination</Link>
        </li>
      </ul>
    </P>
    <h4>Stopping cost</h4>
    <P>
      <ul>
        <li>
          <i>Description</i>: Nomination lists should reduce stopping costs during a route from pickups and drop-offs.
          Every list will have at least <i>two</i> stops (tow origin and tow destination), but every additional stop
          that is contained within a list incurs some kind of additional cost.
        </li>
        <li>
          <i>Metric</i>: “Stopping cost” – an opportunity cost for making a given stop
        </li>
        <li>
          <i>Optimization</i>: Minimize the total stopping costs
        </li>
        <li>
          <i>Level</i>: List-level metric
        </li>
      </ul>
    </P>
    <h2 data-section="advanced">Advanced points</h2>
    <P>
      Policy metrics are calculated as <i>barge-level</i> metrics, <i>leg-level</i> metrics, or <i>list-level</i>{' '}
      metrics.
    </P>
    <P>
      For <b>barge-level</b> metrics, the engine calculates their cost over a list as simply the sum of the metric over
      all barges. Barge-level metrics can be easily calculated for individual barges in the pool.
    </P>
    <P>
      For <b>leg-level</b> metrics, the costs are calculated over each <i>leg</i> of a potential journey. Technically,
      the "legs" are taken between <i>each</i> possible pickup and drop-off location (in order) in the entire barge
      pool; however, this ends up being almost the exact same as taking the costs over just each leg of the journey
      implied by a nomination list.
    </P>
    <P>
      For <b>list-level</b> metrics, the engine calculates their cost over a list by applying some additional
      aggregation beyond a simple sum/count over all barges. For example, the total stopping cost for a nomination list
      is calculated by taking the number of unique stops that a list requires, which is not a metric defined for
      individual barges.
    </P>
  </>
)
