import { useMemo } from 'react'

import { OverviewBarge } from '../../../../Domain/Barge'
import { Toggle } from '../../../../ui/Toggle/Toggle'

import styles from './styles.module.scss'

const PrioritizeHotBarges = ({
  barges,
  prioritizeHotBarges,
  setPrioritizeHotBarges,
}: {
  barges: OverviewBarge[]
  prioritizeHotBarges: boolean
  setPrioritizeHotBarges: (val: boolean) => void
}) => {
  const count = useMemo(() => barges.filter(b => b.isHot).length, [barges])

  return (
    <div className={styles.formRow}>
      <div className={styles.hotBargeToggle}>
        <span>Prioritize hot barges ({count})</span>
        <Toggle enabled={prioritizeHotBarges} onChange={setPrioritizeHotBarges} />
      </div>
    </div>
  )
}

export default PrioritizeHotBarges
