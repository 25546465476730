import {
  LoadStatus,
  GraphqlTripStatus as TripStatus,
  TripStatusId,
  OverviewTrip as OverviewTripGql,
} from '../generated/graphql'

import { FacilityLocation } from './Facility'

export type OverviewTrip = Omit<OverviewTripGql, 'destination' | 'origin'> & {
  origin: FacilityLocation | null
  destination: FacilityLocation | null
}

export function showShortLoadStatus(status: LoadStatus): string {
  switch (status) {
    case LoadStatus.Loaded:
      return 'LD'
    case LoadStatus.Empty:
      return 'MT'
    default:
      throw new Error(`Unexpected status: ${status}`)
  }
}

export const isTripStatus = (status: any): status is TripStatusId => status in TripStatusId

export const showTripStatus = (id: TripStatusId, statuses: Record<TripStatusId, string>) => statuses[id]

export const excludePlacedToLoadStatuses = [
  TripStatus.PlacedLoad,
  TripStatus.PlacedPartialLoad,
  TripStatus.TrampPlacedLoad,
  TripStatus.TrampPlacedPartialLoad,
  TripStatus.ArrivedForLoad,
  TripStatus.TrampArrivedForLoad,
]

export const excludePlaceToLoadTripStatusIds = [
  TripStatusId.PlacedLoad,
  TripStatusId.PlacedPartialLoad,
  TripStatusId.TrampPlacedLoad,
  TripStatusId.TrampPlacedPartialLoad,
  TripStatusId.ArrivedForLoad,
  TripStatusId.TrampArrivedForLoad,
]

// TODO: Only use Graphql trip statuses
export const isExcludingPlacedToLoad = (statuses: any[]) =>
  excludePlacedToLoadStatuses.every(status => statuses.includes(status))
