import { PropsWithChildren, useState } from 'react'

import { Close, Search } from '@mui/icons-material'
import classnames from 'classnames'

import { DebouncedInput } from '../../Input/Input'
import { THButton } from '../Table'

import styles from './FilterColumn.module.scss'

type FilterColumnProps = PropsWithChildren<{
  handleChange: (value: string | undefined) => void
  isFiltered: boolean
}>

export function FilterColunm({ children, handleChange, isFiltered }: FilterColumnProps) {
  const [isActive, setIsActive] = useState(false)
  const placeholder = typeof children === 'string' ? `${children}...` : undefined

  return (
    <div className={styles.filter}>
      {isActive ? (
        <>
          <DebouncedInput autoFocus className={styles.input} placeholder={placeholder} onChange={handleChange} />
          <THButton
            className={styles.button}
            handleClick={() => {
              handleChange(undefined)
              setIsActive(false)
            }}>
            {isFiltered ? (
              <Close className={classnames(styles.icon, styles.closeIcon)} />
            ) : (
              <Search className={classnames(styles.icon, styles.searchIcon)} />
            )}
          </THButton>
        </>
      ) : (
        <THButton handleClick={() => setIsActive(true)}>
          {children}
          <Search className={classnames(styles.icon, styles.searchIcon, styles.disabled)} />
        </THButton>
      )}
    </div>
  )
}
