import { useState, useMemo } from 'react'

import { Combobox } from '@headlessui/react'
import Fuse from 'fuse.js'

import { ELLIPSIS } from '../../../constants/constants'
import { HubLikeId, NominatableBoat, useBoatsByDestinationQuery } from '../../../generated/graphql'
import { useSettingsContext } from '../../../providers/SettingsProvider'
import {
  ComboboxWrapper,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption,
  ComboboxButton,
} from '../../../ui/Combobox/Combobox'

import styles from './NominationForm.module.scss'

const INDEX_OPTIONS: Fuse.IFuseOptions<NominatableBoat> = {
  keys: [
    { name: 'boatId', weight: 1.5 },
    { name: 'name', weight: 0.5 },
    { name: 'abbreviation', weight: 2.0 },
  ],
  includeScore: true,
  ignoreFieldNorm: true,
}

type Props = {
  value?: string
  handleChange: (value?: string) => void
  destination?: HubLikeId
  isDisabled: boolean
}

export function EditNominationBoat({ value, handleChange, destination, isDisabled }: Props) {
  const [{ data }] = useBoatsByDestinationQuery({
    pause: destination === undefined,
    variables: { destination: destination! },
  })

  return (
    <EditBoat value={value} handleChange={handleChange} isDisabled={isDisabled} recommendedBoats={data?.boats ?? []} />
  )
}

function EditBoat({
  value,
  handleChange,
  recommendedBoats,
  isDisabled,
}: Props & { isDisabled: boolean; recommendedBoats: NominatableBoat[] }) {
  const [query, setQuery] = useState('')
  const { boats } = useSettingsContext()

  const recommendedBoatIds = useMemo(() => recommendedBoats.map(({ boatId }) => boatId), [recommendedBoats])

  const allBoats = useMemo(
    () => [...recommendedBoats, ...boats.filter(({ boatId }) => !recommendedBoatIds.includes(boatId))],
    [boats, recommendedBoats, recommendedBoatIds]
  )

  const index = useMemo(() => new Fuse<NominatableBoat>(allBoats, INDEX_OPTIONS), [allBoats])

  const boatItems = useMemo(() => {
    if (query.trim() === '') {
      return allBoats
    }
    return index
      .search(query)
      .sort((a, b) => {
        if (a.score === b.score) {
          const indexA = recommendedBoatIds.findIndex(id => id === a.item.boatId)
          const indexB = recommendedBoatIds.findIndex(id => id === b.item.boatId)

          if (indexA === -1 || indexB === -1) {
            if (indexA === indexB) {
              return 0
            }
            return indexA === -1 ? 1 : -1
          }

          return indexA > indexB ? 1 : -1
        }

        if (a.score === undefined || b.score === undefined) {
          return a.score === undefined ? -1 : 1
        }
        return a.score > b.score ? 1 : -1
      })
      .map(h => h.item)
  }, [index, query, recommendedBoatIds, allBoats])

  return (
    <ComboboxWrapper className={styles.combobox}>
      <Combobox disabled={isDisabled} nullable value={value} onChange={handleChange}>
        <ComboboxInput className={styles.comboboxInput}>
          <label>
            <Combobox.Input
              className={styles.comboboxOption}
              displayValue={(boatId: string | null) => {
                const boat = boatItems.find(b => b.boatId === boatId)
                return boat ? `(${boat.abbreviation ?? ELLIPSIS}) ${boat.name ?? ELLIPSIS}` : ''
              }}
              placeholder="Select boat"
              onChange={({ target }) => setQuery(target.value)}
            />
            <ComboboxButton />
          </label>
        </ComboboxInput>
        <ComboboxOptions afterLeave={() => setQuery('')}>
          {boatItems.map(b => (
            <ComboboxOption key={b.boatId} className={styles.comboboxOption} value={b.boatId}>
              <span className={styles.boatId}>({b.abbreviation ?? ELLIPSIS})</span> {b.name ?? '…'}
            </ComboboxOption>
          ))}
        </ComboboxOptions>
      </Combobox>
    </ComboboxWrapper>
  )
}
