import React from 'react'

import { LaneId } from '../../../generated/graphql'
import ComboboxSelector from '../ComboboxSelector/ComboboxSelector'

type LaneSelectorProps = {
  lane?: LaneId
  lanes: Record<LaneId, string>
  handleChange: (item: LaneId) => void
}

const LaneSelector = ({ lane, lanes, handleChange }: LaneSelectorProps) => {
  const formattedLanes = Object.entries(lanes).reduce<Record<string, { label: string; isDisabled?: boolean }>>(
    (acc, [key, value]) => {
      acc[key] = { label: value }
      return acc
    },
    {}
  )

  return (
    <ComboboxSelector
      value={lane}
      handleChange={handleChange}
      items={formattedLanes}
      placeholder="Select a lane"
      displayValue={(item: string | undefined) =>
        item && formattedLanes[item] ? formattedLanes[item].label : ''
      }
    />
  )
}

export default LaneSelector
