import React from 'react'

import { Popover } from '@headlessui/react'
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import { Tooltip } from '@mui/material'

import styles from './Selector.module.scss'

type SelectorProps<T> = {
    label: string
    currentSelection: T
    options: T[]
    onChange: (key: T) => void
    tooltipText: string
}

const Selector = <T extends string>({ label, currentSelection, options, onChange, tooltipText }: SelectorProps<T>) => {
    return (
        <div className={styles.selectorContainer}>
            <span>{label}</span>
            <Popover>
                {({ open }) => (
                    <>
                        <Popover.Button className={styles.button}>{open ? <ArrowDropUp /> : <ArrowDropDown />}</Popover.Button>
                        <Popover.Panel className={styles.popoverPanel}>
                            <Tooltip title={tooltipText} placement="top">
                                <div className={styles.selectorOptions}>
                                    {options.map(option => (
                                        <div key={option} className={styles.selectorOption}>
                                            <input
                                                type="radio"
                                                id={`selector-${option}`}
                                                checked={currentSelection === option}
                                                onChange={() => onChange(option)}
                                                className={styles.radio}
                                                name="selector"
                                            />
                                            <label htmlFor={`selector-${option}`} className={styles.selectorLabel}>
                                                {option}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            </Tooltip>
                        </Popover.Panel>
                    </>
                )}
            </Popover>
        </div>
    )
}

export default Selector
