import { useEffect, useMemo, useState } from 'react'

import { Pending, Circle } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import classnames from 'classnames'
import { differenceInMinutes, parseISO } from 'date-fns'
import { match } from 'ts-pattern'

import { useBargesLastUpdatedQuery } from '../../../generated/graphql'

import styles from './LastBargePoolUpdate.module.scss'

export function LastBargePoolUpdate() {
  const [tick, setTick] = useState(Date.now())
  const [{ data }, refetch] = useBargesLastUpdatedQuery()

  useEffect(() => {
    const ref = setInterval(() => setTick(Date.now()), 5_000)

    return () => clearInterval(ref)
  }, [])

  useEffect(() => {
    const ref = setInterval(() => refetch({ requestPolicy: 'network-only' }), 2 * 60_000)

    return () => clearInterval(ref)
  }, [refetch])

  const lastUpdate = useMemo(
    () =>
      data && data.lanes.length && data.lanes[0].barges.length
        ? data.lanes[0].barges
            .map(_ => parseISO(_.lastUpdated))
            .sort((a, b) => {
              const aTime = a.getTime()
              const bTime = b.getTime()

              if (aTime === bTime) return 0
              return aTime > bTime ? -1 : 1
            })[0]
        : null,
    [data]
  )

  if (lastUpdate === null) {
    return <Pending className={styles.pending} />
  }

  const updateDiff = differenceInMinutes(tick, lastUpdate)

  const title = match(updateDiff)
    .with(0, () => 'Last updated: just now')
    .with(1, () => 'Last updated: one minute ago')
    .otherwise(nr => `Last updated: ${nr} minutes ago`)

  return (
    <Tooltip title={title}>
      <Circle
        className={classnames(styles.status, {
          [styles.isOk]: updateDiff <= 15,
          [styles.isStale]: updateDiff > 15 && updateDiff <= 30,
          [styles.isVeryStale]: updateDiff > 30 && updateDiff <= 45,
        })}
      />
    </Tooltip>
  )
}
