import { useMemo, useState } from 'react'

import { useLocalStorageState } from '../../hooks/useLocalStorage'

export type ColumnConfig = {
  columns: { id: string; name: string; defaultOrder: number }[]
  hidden: string[]
}
export function useColumnConfig(defaultColumns: ColumnConfig['columns'], defaultHidden: string[] = []) {
  const { getItem, setItem } = useLocalStorageState()

  const defaultOrdering = useMemo(
    () =>
      defaultColumns.reduce((acc: Record<string, number>, { id, defaultOrder }) => {
        acc[id] = defaultOrder

        return acc
      }, {}),
    [defaultColumns]
  )

  const stored = getItem('columnConfig')

  const [config, setConfig] = useState<ColumnConfig>({
    columns: defaultColumns
      .map(c => ({ ...c, defaultOrder: stored?.order[c.id] ?? c.defaultOrder }))
      .sort((a, b) => {
        if (a.defaultOrder === b.defaultOrder) return 0
        return a.defaultOrder > b.defaultOrder ? 1 : -1
      }),
    hidden: stored && stored.hidden.length ? stored.hidden : defaultHidden,
  })

  const getFormattedOptions = <T>(keys: T[]): { label: string; value: T }[] =>
    keys.map(key => {
      const column = config.columns.find(col => col.id === key)
      return {
        label: column ? column.name : String(key),
        value: key,
      }
    })

  return {
    config,
    getFormattedOptions,
    setConfig: (newConfig: ColumnConfig) => {
      const { hidden, columns: columnOrder } = newConfig
      const newColumnOrder = columnOrder.reduce((acc: Record<string, number>, { id, defaultOrder }) => {
        acc[id] = defaultOrder
        return acc
      }, {})

      const order = Object.keys(defaultOrdering).every(key => newColumnOrder[key] === defaultOrdering[key])
        ? {}
        : newColumnOrder

      setConfig(newConfig)

      setItem('columnConfig', {
        hidden,
        order,
      })
    },
  }
}
