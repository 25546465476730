import React, { FC } from 'react'

import FormOrSummary from '../FormOrSummary'
import { StageData } from '../types'
import { includeTBOsValidationSchema } from '../viewModel'

import IncludeTBO from './IncludeTBOs'
import MaxDraftForm from './MaxDraftForm'
import PoolFiltersSelectorBlock from './PoolFiltersSelector'
import TooltipIcons from './ToolTipIcons'

type PoolFiltersSelectionSummaryProps = {
  description: string[]
  nominatedBarges: boolean
  tanks: boolean
  openHopper: boolean
  havingTboInfo: boolean
  placedToLoad: boolean
  shuttleMoves: boolean
}

const PoolFiltersSelectionSummary: FC<PoolFiltersSelectionSummaryProps> = ({
  description,
  nominatedBarges,
  tanks,
  openHopper,
  havingTboInfo,
  placedToLoad,
  shuttleMoves,
}) => {
  return (
    <div>
      {description.map((desc, index) => (
        <div key={index}>{desc}</div>
      ))}
      <TooltipIcons
        nominatedBarges={nominatedBarges}
        tanks={tanks}
        openHopper={openHopper}
        havingTboInfo={havingTboInfo}
        placedToLoad={placedToLoad}
        shuttleMoves={shuttleMoves}
      />
    </div>
  )
}

const PoolFiltersSelectionBlock: FC<StageData<any, any, any>> = ({ isSelected, isValid, actions, form, summary }) => {
  const { data, onChange } = form
  const { description } = summary

  const includeTBOs = data.includeTBOs || []

  const handleAddTbo = (order: number) => {
    if (!includeTBOs.includes(order)) {
      onChange({
        ...data,
        includeTBOs: [...includeTBOs, order],
      })
    }
  }

  const handleRemoveTbo = (number: number) => {
    onChange({
      ...data,
      includeTBOs: includeTBOs.filter((tbo: number) => tbo !== number),
    })
  }

  const handleDraftChange = (newFeet: number | null, newInch: number | null) => {
    onChange({ feet: newFeet, inch: newInch })
  }

  const handleFilterToggle = (key: string, value: boolean) => {
    onChange({ ...data, [key]: value })
  }

  const handleFeetReset = () => {
    handleDraftChange(null, data.inch)
  }

  const handleInchReset = () => {
    handleDraftChange(data.feet, null)
  }

  return (
    <FormOrSummary
      title="Filters"
      isSelected={isSelected}
      isValid={isValid}
      actions={actions}
      form={
        <>
          <MaxDraftForm
            feet={data.feet}
            inch={data.inch}
            onChange={handleDraftChange}
            handleFeetReset={handleFeetReset}
            handleInchReset={handleInchReset}
            readOnly={false}
          />
          <PoolFiltersSelectorBlock
            nominatedBarges={data.nominatedBarges}
            tanks={data.tanks}
            openHopper={data.openHopper}
            havingTboInfo={data.havingTboInfo}
            placedToLoad={data.placedToLoad}
            shuttleMoves={data.shuttleMoves}
            onToggle={handleFilterToggle}
          />
          <IncludeTBO
            tboNumbers={includeTBOs}
            onAddTbo={handleAddTbo}
            onRemoveTbo={handleRemoveTbo}
            readOnly={false}
            validationSchema={includeTBOsValidationSchema}
          />
        </>
      }
      summary={
        <PoolFiltersSelectionSummary
          description={description}
          nominatedBarges={data.nominatedBarges}
          tanks={data.tanks}
          openHopper={data.openHopper}
          havingTboInfo={data.havingTboInfo}
          placedToLoad={data.placedToLoad}
          shuttleMoves={data.shuttleMoves}
        />
      }
    />
  )
}

export default PoolFiltersSelectionBlock
