import { useCallback } from 'react'

import { DownloadOutlined, Help, SvgIconComponent } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import classNames from 'classnames'

import { CopyNames } from '../../components/BargeListing/Layout'
import { getDataByColumns } from '../../components/Table/NominatedBargesTable'
import { NominatedTow } from '../../Domain/Nomination'
import { GoalId } from '../../generated/graphql'
import { useSettingsContext } from '../../providers/SettingsProvider'
import { handleCSVDownload } from '../../utils/downloadFile'
import { Popover } from '../Popover/Popover'

import styles from './NominationListItem.module.scss'

function TowMetric({ metric }: { metric: number | null }) {
  const hue = Math.max(Math.min((metric ?? 0) - 0.5, 0.5), 0) * 240

  return (
    <div className={styles.metricWrapper}>
      <span style={{ backgroundColor: `hsl(${hue}, 100%, 66%)` }} className={styles.metric}>
        {typeof metric === 'number' ? `${(metric * 100).toFixed(2)}%` : 'N/A'}
      </span>
      <Popover button={<Help />} placement="top" theme={{ popover: styles.popover }}>
        <h3>Total barge-miles</h3>
        <p>
          Total barge-miles (the number of miles each barge travels) in the itinerary of a nomination list divided by
          the maximum barge-miles that can be pushed over the same tow route
        </p>
      </Popover>
    </div>
  )
}

type TowActionButtonProps = {
  label: string
  className?: string | undefined
  Icon: SvgIconComponent
  onClick: () => void
}
const TowActionButton = ({ label, onClick, Icon, className }: TowActionButtonProps) => (
  <Tooltip title={label} placement="top">
    <button className={classNames(styles.towActionButton, className)} type="button" onClick={onClick}>
      <span className="sr-only">{label}</span>
      <Icon />
    </button>
  </Tooltip>
)

type TowItemProps = NominatedTow & { goal: GoalId }

export function NominationListItemHeader({
  goal,
  efficiencyMetric,
  boat,
  barges,
  className,
}: TowItemProps & { className?: string }) {
  const { goals } = useSettingsContext()
  const bargeNamesCallback = useCallback(() => barges.map(({ name }) => name).join(', '), [barges])

  return (
    <div className={classNames(styles.nominationItemHeader, className)}>
      <div className={styles.nominationItemActions}>
        <span>
          {goals[goal].label} ({barges.length}){boat ? `| #${boat.abbreviation} - ${boat.name}` : null}
        </span>
        <div className={styles.nominationItemRightActions}>
          <TowMetric metric={efficiencyMetric} />
          <TowActionButton
            label="Export data to CSV"
            onClick={() => handleCSVDownload({ data: getDataByColumns(barges) })}
            Icon={DownloadOutlined}
          />
          <CopyNames getNames={bargeNamesCallback} />
        </div>
      </div>
    </div>
  )
}
