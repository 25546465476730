import React from 'react'

import * as R from 'ramda'

import { formatBoat } from '../../../lib/formatters'
import ComboboxSelector from '../ComboboxSelector/ComboboxSelector'

import type { NominatableBoat } from '../../../Domain/Nomination'

type BoatSelectorComboboxProps = {
  boat?: string
  boats: Record<string, NominatableBoat>
  handleChange: (item: string) => void
  isDisabled?: boolean
}

const BoatSelectorCombobox: React.FC<BoatSelectorComboboxProps> = ({
  boat,
  boats,
  handleChange,
  isDisabled = false,
}) => {
  const boatList = R.values(boats)

  const options: Record<string, { label: string }> = R.pipe(
    R.map(
      ({ boatId, abbreviation, name }: NominatableBoat): Record<string, { label: string }> => ({
        [boatId]: { label: `(${abbreviation}) ${name}` },
      })
    ),
    R.mergeAll<Record<string, { label: string }>>
  )(boatList)

  const displayValue = (boatId: string | undefined) =>
    boatId ? formatBoat(boatId, boatList) : ''

  return (
    <ComboboxSelector
      value={boat}
      handleChange={handleChange}
      items={options}
      displayValue={displayValue}
      placeholder="Select boat"
      isDisabled={isDisabled}
    />
  )
}

export default BoatSelectorCombobox
