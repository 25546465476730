import { Link } from 'wouter'

import { docsPagePath } from '../../paths'

import { P } from './Components/Components'

export const BuildToDestinationDoc = () => (
  <>
    <h1>Build to Destination</h1>
    <h2 data-section="overview">Overview</h2>
    <h3>Goal</h3>
    <P>Create a nomination list that leads to better operations and business conditions at the tow destination</P>
    <h3>Description</h3>
    <P>
      Building tows that are easier to break up when dropping-off/picking-up barges or when passing through a lock can
      move more efficiently. Tows that can be easily disassembled and handled will improve the efficiency of fleet
      operations at destination. Under the Build to Destination goal we are looking for a list that is most likely to
      make such a tow.
    </P>
    <P>
      A good way to think of the difference between Fleet Efficiency and Build to Destination is that Fleet Efficiency
      is most concerned with fleet conditions <b>at origin</b> whereas Build to Destination is more concerned with
      conditions at <b>at destination</b>.
    </P>
    <h2 data-section="goal-profile">Goal profile</h2>
    <h3>Examples</h3>
    <P>
      Some examples of what this goal is trying to achieve:
      <ul>
        <li>
          Reduce the amount of handling and work needed to break up tows at their destination. For example, suppose we
          have two different tows that each have 35 barges all destined to be dropped off at GRMS-Columbus.
          <ul>
            <li>
              The barges in the first tow have (among them) 30 different final destinations they will ultimately be
              delivered to
            </li>
            <li>The barges in the second tow have (among them) 5 different final destinations</li>
          </ul>
          This means that the second tow should be easier to handle at GRMS because many of the barges in that tow can
          continue to travel together in later tows since they share final destinations, and therefore this is the
          scenario this operational goal would seek to nominate.
        </li>
        <li>
          Optimize a tow build to make the tow <i>slick</i>. The most optimal hydrodynamics for the boat come through
          having a good balance of rakes and boxes, and through maintaining hydrodynamic{' '}
          <Link href={docsPagePath('barge-groups')}>barge groupings</Link> that are already coupled together.
          <P>
            <i>NOTE</i>: The actual building plan and diagram is out of scope, but we can use nomination policies to
            generate lists that will more easily <i>facilitate</i> good tow configurations for fleet dispatchers and
            boat captains.
          </P>
        </li>
      </ul>
    </P>
    <h3>Comparisons to other goals</h3>
    <h4>
      ...compared to <Link href={docsPagePath('fleet-efficiency')}>Fleet Efficiency</Link>
    </h4>
    <P>
      <ul>
        <li>
          Build to Destination makes grouping barges by final destination its primary focus... Fleet Efficiency has a
          secondary focus on this policy
        </li>
        <li>
          Build to Destination has a minor focus on LIFO selection... Fleet Efficiency has a primary focus on FIFO
          selection for barges in fleet
        </li>
        <li>
          Build to Destination has a minor focus in keeping rakes and boxes balanced in the list... Fleet Efficiency
          also considers the imbalance between rakes and boxes in the pool
        </li>
      </ul>
    </P>
    <h4>...compared to Linehaul Turn Time</h4>
    <P>
      <ul>
        <li>
          Build to Destination does <i>not</i> use <i>any</i> time values in optimization... Linehaul Turn Time uses
          time window deadlines
        </li>
        <li>
          Build to Destination makes grouping barges by final destination its primary focus.... Linehaul Turn Time gives
          only a minor focus toward this policy
        </li>
      </ul>
    </P>
    <h2 data-section="policies">Policies</h2>
    <P>
      The nomination policies that are used within the Build to Destination goal are list below. In the{' '}
      <Link href={`${docsPagePath('nomination-policies')}/s/policy-details`}>Policy details</Link> section of the
      Nomination Policies page you can find a bit more information on each policy, such as the specific metric used and
      the way that metric is used in the optimization.
    </P>
    <h3 data-section="core-policies">Core policies</h3>
    <h4>Primary</h4>
    <P>
      <ul>
        <li>
          <b>Final destinations (min)</b> – Keeping the number of final destinations in a list to a lower number makes
          it more likely the engine will prefer to nominate barges that can travel together for the rest of their
          remaining trip to destination
        </li>
      </ul>
    </P>
    <h4>Secondary</h4>
    <P>
      <ul>
        <li>
          <b>Maximize barge-miles</b> – All else being equal, nomination lists should transport more barges more miles
        </li>
      </ul>
    </P>
    <h3 data-section="other-policies">Other policies</h3>
    <h4>Minor</h4>
    <P>
      <ul>
        <li>
          <b>Stopping cost</b> – Nomination lists should reduce stopping costs during a route from pickups and
          drop-offs. Every list will have at least <i>two</i> stops (tow origin and tow destination), but every
          additional stop that is contained within a list incurs some kind of additional cost
        </li>
        <li>
          <b>Distance to origin</b> – Barges whose current location is closer to an origin hub point are typically
          easier to efficiently gather for building a tow
        </li>
        <li>
          <b>FIFO (First in–first out)</b> a.k.a. <b>Time in fleet (max)</b> – The barges that have been in fleet
          longest are more urgent to nominate so that they can continue their trip.
          <P>
            <i>NOTE</i>: A negative weight for this policy is identical to a positive weight in the LIFO policy, and
            vice versa.
          </P>
        </li>
      </ul>
    </P>
    <h4>General</h4>
    <P>
      <ul>
        <li>
          <b>Rake evenness</b> – All else being equal, a nomination list with an even number of rakes is preferable to a
          list with an odd number because it is easier to assemble a hydrodynamic tow when there are even number of
          rakes to use.
        </li>
        <li>
          <b>Rake–box balance</b> – All else being equal, the more balanced the number of rakes and the number of boxes
          in a list, the better.
        </li>
      </ul>
    </P>
  </>
)
