import { SUPPORT_EMAIL_ADDRESS, SUPPORT_PHONE_NUMBER } from '../../constants/constants'
import { SubMenuForm } from '../Header/SubMenu/SubMenuForm/SubMenuForm'
import { SubMenuHeader } from '../Header/SubMenu/SubMenuHeader/SubMenuHeader'

import styles from './BugReporter.module.scss'

interface IBugReporterProps {
  onBack: () => void
  isActive: boolean
}

export const BugReporter = (props: IBugReporterProps) => {
  return (
    <div className={styles.container}>
      <SubMenuHeader onBack={props.onBack} />
      <SubMenuForm>
        <h3 className={styles.heading}>Request support</h3>
        <p>
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
          consequat.
        </p>
        <p>
          Please email{' '}
          <a className={styles.link} href={`mailto:${SUPPORT_EMAIL_ADDRESS}`}>
            {SUPPORT_EMAIL_ADDRESS}
          </a>{' '}
          or call {SUPPORT_PHONE_NUMBER} if you have any questions.
        </p>
      </SubMenuForm>
    </div>
  )
}
