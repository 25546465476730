import React, { useState, useMemo, FC, ChangeEvent } from 'react'

import { Combobox } from '@headlessui/react'
import classNames from 'classnames'
import Fuse from 'fuse.js'

import { isNonHub } from '../../../Domain/Hub'
import { showRiverLocation } from '../../../Domain/River'
import { LaneId } from '../../../generated/graphql'
import {
  ComboboxWrapper,
  ComboboxButton,
  ComboboxOptions,
  ComboboxOption,
  ComboboxInput,
} from '../../../ui/Combobox/Combobox'

import styles from './ComboboxSelector.module.scss'

type ComboboxItem = {
  label: string
  riverLocation?: { code: string; mileagePoint: number }
  isDisabled?: boolean
  __typename?: string
}

type ComboboxSelectorProps = {
  value?: string
  handleChange: (item: LaneId) => void
  items: Record<string, ComboboxItem>
  placeholder: string
  displayValue?: (item: string | undefined) => string
  isDisabled?: boolean
}

const ComboboxSelector: FC<ComboboxSelectorProps> = ({
  value,
  handleChange,
  items,
  placeholder,
  isDisabled = false,
  displayValue,
}) => {
  const [query, setQuery] = useState('')

  const fuse = useMemo(
    () =>
      new Fuse(Object.entries(items), {
        keys: ['1.label'],
        threshold: 0.3,
      }),
    [items]
  )

  const filteredItems = useMemo(() => {
    if (!query) return Object.entries(items)
    return fuse.search(query).map((result) => result.item)
  }, [query, fuse, items])

  const handleInputChange = ({ target: { value: newValue } }: ChangeEvent<HTMLInputElement>) => setQuery(newValue)

  const splitLabel = (label: string) => {
    const prefixMatch = label.match(/^\(([^)]+)\)/)
    const prefix = prefixMatch ? prefixMatch[0] : ''
    const rest = prefix ? label.replace(prefix, '').trim() : label
    return { prefix, rest }
  }

  return (
    <ComboboxWrapper className={styles.menu}>
      <Combobox disabled={isDisabled} value={value ?? ''} onChange={handleChange}>
        <ComboboxInput className={styles.comboboxInput}>
          <label>
            <Combobox.Input
              placeholder={placeholder}
              onChange={handleInputChange}
              autoFocus
              displayValue={(id) => displayValue?.(id as string | undefined) || ''}
            />
            <ComboboxButton />
          </label>
        </ComboboxInput>

        <ComboboxOptions className={styles.menuPanel} afterLeave={() => setQuery('')}>
          {filteredItems.length === 0 ? (
            <div className={styles.noResults}>No options available</div>
          ) : (
            filteredItems.map(([key, val]) => {
              const { prefix, rest } = splitLabel(val.label)
              return (
                <ComboboxOption
                  key={key}
                  value={key}
                  className={classNames(styles.menuItem, { [styles.isDisabled]: val.isDisabled })}
                  aria-disabled={val.isDisabled}
                >
                  {val.riverLocation && (
                    <span className={classNames(styles.icon, { [styles.hub]: !isNonHub(val) })} />
                  )}
                  {val.riverLocation && (
                    <span className={styles.entryCode}>{showRiverLocation(val.riverLocation)}</span>
                  )}
                  {prefix && <span className={styles.prefix}>{prefix}</span>} {rest}
                </ComboboxOption>
              )
            })
          )}
        </ComboboxOptions>
      </Combobox>
    </ComboboxWrapper>
  )
}

export default ComboboxSelector
