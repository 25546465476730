import React, { type FC } from 'react'

import classnames from 'classnames'

import styles from './styles.module.scss'

const LabelValuePair: FC<{
  label: string
  value: string | number
  labelStyle?: string
  valueStyle?: string
}> = ({ label, value, labelStyle, valueStyle }) => {
  return (
    <div className={styles.labelValuePair}>
      <span className={classnames([styles.label, labelStyle])}>{label}</span>
      <span>:</span>
      <span className={classnames([styles.value, valueStyle])}>{value}</span>
    </div>
  )
}

export default LabelValuePair
