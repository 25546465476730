import { PropsWithChildren } from 'react'

import classNames from 'classnames'

import { ELLIPSIS } from '../../constants/constants'

import styles from './Table.module.scss'

export function TableCell({
  className,
  children,
  colSpan,
}: PropsWithChildren<{
  className?: string
  colSpan?: number
}>) {
  return (
    <td colSpan={colSpan} className={classNames(styles.cell, className)}>
      {children ?? ELLIPSIS}
    </td>
  )
}
