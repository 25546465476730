import { useState, useEffect, useRef } from 'react'

import classNames from 'classnames'

import styles from './Input.module.scss'

type InputProps = {
  value: string
  onChange: (value: string) => void
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>

export const Input = ({ value, onChange, ...props }: InputProps) => (
  <input
    {...props}
    value={value}
    onChange={e => onChange(e.target.value)}
    className={classNames(styles.input, props.className)}
  />
)

export const DebouncedInput = ({
  onChange,
  initialValue = '',
  debounce = 500,
  ...props
}: Omit<InputProps, 'value'> & { debounce?: number; initialValue?: string }) => {
  const [value, setValue] = useState(initialValue)
  const timeout = useRef<NodeJS.Timeout>()

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    return () => {
      if (timeout.current) clearTimeout(timeout.current)
    }
  }, [])

  return (
    <Input
      {...props}
      value={value}
      onChange={val => {
        setValue(val)

        if (timeout.current) clearTimeout(timeout.current)

        timeout.current = setTimeout(() => {
          onChange(val)
        }, debounce)
      }}
    />
  )
}
