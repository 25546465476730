import { AccessTime } from '@mui/icons-material'
import classnames from 'classnames'
import { NumberFormatValues, PatternFormat } from 'react-number-format'

import styles from './TimeInput.module.scss'

type Props = { handleChange: (val: string) => void; isDisabled?: boolean; value?: string }

const PATTERN = ['[0-2]', '[0-9]', '[0-5]', '[0-9]']

function isAllowed({ value }: NumberFormatValues) {
  const pat = PATTERN.slice(0, value.length)

  if (pat.length > 1 && value.startsWith('2')) {
    pat[1] = '[0-3]'
  }

  return value.match(new RegExp(pat.join(''))) !== null
}

export const TimeInput = ({ handleChange, isDisabled, value }: Props) => (
  <PatternFormat
    value={value ?? ''}
    disabled={isDisabled}
    onValueChange={v => handleChange(v.value)}
    className={styles.timeInput}
    format="##:##"
    mask="_"
    placeholder="HH:MM"
    isAllowed={isAllowed}
  />
)

export const TimeInputElement = (props: Props) => (
  <label htmlFor="time-input" className={classnames(styles.label, { [styles.isDisabled]: props.isDisabled })}>
    <AccessTime />
    <TimeInput {...props} />
  </label>
)
