import React, { FC } from 'react'

import { Tooltip } from '@mui/material'

import styles from '../../Pages/Nominations/Form/PoolFilters/styles.module.scss'


type TooltipIconProps = {
    isActive: boolean
    tooltip: string
    icon: React.ReactNode
}

const TooltipIcon: FC<TooltipIconProps> = ({ isActive, tooltip, icon }) => {
    return (
        <Tooltip title={tooltip}>
            <span className={`${styles.icon} ${isActive ? styles.active : styles.inactive}`}>{icon}</span>
        </Tooltip>
    )
}

export default TooltipIcon
