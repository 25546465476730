import { Check } from '@mui/icons-material'
import classnames from 'classnames'

import { TestId } from '../../utils/testid'

import styles from './Checkbox.module.scss'

interface IProps {
  label: string
  checked?: boolean
  disabled?: boolean
  className?: string
  onChange?: (checked: boolean) => void
}

export const Checkbox = ({ checked, onChange, label, disabled, className }: IProps) => (
  <label
    className={classnames(styles.checkboxLabel, className, { [styles.checkboxLabelDisabled]: disabled })}
    data-testid={TestId.CheckBoxLabel}>
    <input
      type="checkbox"
      checked={checked}
      onChange={() => !disabled && onChange?.(!checked)}
      data-testid={TestId.CheckBoxInput}
    />
    <span
      className={classnames(styles.checkboxButton, {
        [styles.checkboxChecked]: checked,
        [styles.checkboxButtonDisabled]: disabled,
        [styles.checkboxCheckedDisabled]: checked && disabled,
      })}>
      {checked && <Check data-testid={TestId.CheckBoxCheckMark} />}
    </span>
    {label}
  </label>
)
