import { ElementType, PropsWithChildren } from 'react'

import { Menu } from '@headlessui/react'
import classnames from 'classnames'

import styles from './Dropdown.module.scss'

export const DropdownButton = ({
  className,
  children,
  isDisabled = false,
  as,
}: PropsWithChildren<{ className?: string; as?: ElementType; isDisabled?: boolean }>) => (
  <Menu.Button disabled={isDisabled} as={as} className={classnames(styles.menuButton, className)}>
    {children}
  </Menu.Button>
)

export const DropdownButtonLabel = ({ children, className }: PropsWithChildren<{ className?: string }>) => (
  <div className={classnames(styles.menuButtonLabel, className)}>{children}</div>
)

export const DropdownMenu = ({ children, className }: PropsWithChildren<{ className?: string }>) => (
  <Menu.Items className={classnames(styles.menuPanel, className)}>{children}</Menu.Items>
)

export const DropdownWrapper = ({ children }: PropsWithChildren<{}>) => (
  <div className={styles.menu}>
    <Menu>{children}</Menu>
  </div>
)

export const DropdownMenuItem = ({
  className,
  handleClick,
  isDisabled = false,
  children,
}: PropsWithChildren<{ className?: string; handleClick: () => void; isDisabled?: boolean }>) => (
  <Menu.Item disabled={isDisabled}>
    <div
      className={classnames(styles.menuItem, className)}
      onClick={e => {
        if (isDisabled) {
          e.preventDefault()
        } else {
          handleClick()
        }
      }}>
      {children}
    </div>
  </Menu.Item>
)
