import { format } from 'date-fns'
import { utils, writeFileXLSX } from 'xlsx'

import { browserInfo } from '../../../lib/browser-info'
import { toString } from '../../../utils/date'

import type { NominationData, NominationVersionData } from '../../../models/models'
import type { UserDetails } from '../../Account/AuthorizationContext'


export const collectVersionedFeedbackData = (
  userInfo: UserDetails,
  nomination: NominationData,
  versions: NominationVersionData[],
  exportTime: Date
) => {
  const workbook = utils.book_new()
  const { email, cognitoUsername } = userInfo
  const info = browserInfo()

  const NOT_AVAILABLE = 'N/A'

  const feedbackSheetData = [
    ['User Info'],
    ['Email', email],
    ['Username', cognitoUsername],
    [],
    ['Browser Info'],
    ['Browser', info.browser],
    ['Browser Version', info.browserVersion],
    ['OS', info.os],
    ['Device', info.device],
    [],
    ['Nomination Info'],
    ['Nomination ID', nomination.id],
    ['Record Time', toString(exportTime || new Date())],
    ['TBO Link Status', nomination.tboLinkStatus],
    ['TBO Number', nomination.tboNumber ?? NOT_AVAILABLE],
  ]
  const feedbackSheet = utils.aoa_to_sheet(feedbackSheetData)
  utils.book_append_sheet(workbook, feedbackSheet, 'Feedback')

  versions.forEach((version) => {
    const versionData = [
      [`Version (${version.id})`],
      ['Type', version.type],
      ['Record Time', toString(version.recordTime)],
      ['Tow Boat', version.tow.boat?.name ?? NOT_AVAILABLE],
      ['Total Barges', version.tow.barges.length],
      ['Turnboat', version.tow.hasTurnboat ? 'Yes' : 'No'],
      [],
      ['Barges'],
      ['ID', 'Name', 'Load Status'],
      ...version.tow.barges.map(barge => [barge.id, barge.name ?? NOT_AVAILABLE, barge.loadStatus]),
      [],
      ['TBN Barges'],
      ['Pickup Facility', 'Dropoff Facility', 'Expected Load Status'],
      ...(version.tow.tbnBarges || []).map(tbn => [
        tbn.pickupFacility.name,
        tbn.dropOffFacility.name,
        tbn.expectedLoadStatus,
      ]),
    ]

    const versionSheet = utils.aoa_to_sheet(versionData)
    const creationDate =
      toString(version.recordTime || new Date())
        .replace(/[:\\/?*[\]]/g, '-')
        .trim() 
    
    utils.book_append_sheet(workbook, versionSheet, `Version ${creationDate}`)
  })

  const exportDate = format(new Date(), 'yyyy-MM-dd')
  const exportFileName = `feedback-${nomination.id}-${exportDate}.xlsx`
  writeFileXLSX(workbook, exportFileName)
}
