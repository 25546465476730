import { useCallback, useState } from 'react'

import { Nullable } from './Nullable'

function basicSort<A>(valA: A, valB: A): -1 | 0 | 1 {
  const a = valA ?? null
  const b = valB ?? null

  if (a === b) return 0

  if (a === null || b === null) {
    return a === null ? 1 : -1
  }

  return a > b ? 1 : -1
}
 
export interface Sortable<A> {
  isSortable: boolean

  sort(a: A, b: A): -1 | 0 | 1

  sortBy(xs: A[]): A[]
}

type Dir = 'asc' | 'desc'
export type SortingState<A> = { sortable: Sortable<A>; dir: Dir }

export function useSorting<A>() {
  const [sorting, setSorting] = useState<SortingState<A>>()

  const setSortingCallback = useCallback(
    (sortable: Sortable<A>) => {
      if (sorting === undefined || sorting?.sortable !== sortable) {
        return setSorting({ sortable, dir: 'asc' })
      }

      if (sorting.dir === 'asc') {
        return setSorting({ sortable, dir: 'desc' })
      }
      return setSorting(undefined)
    },
    [sorting]
  )

  return { sorting, setSorting: setSortingCallback }
}

type Options<B> = Partial<{ isSortable: boolean; format: (b: NonNullable<B>) => string | null }>

export class Column<A, B> implements Sortable<A> {
  isSortable = false

  constructor(
    readonly label: string,
    readonly getValue: (a: A) => B,
    readonly options: Options<B> = { isSortable: false }
  ) {
    if (options.isSortable) {
      this.isSortable = options.isSortable
    }
  }

  sort(a: A, b: A) {
    return basicSort(this.getValue(a), this.getValue(b))
  }

  format(row: A): string | null {
    const value = this.getValue(row)
    if (value === null || value === undefined) {
      return null
    }

    return this.options.format ? this.options.format(value) : `${value}`
  }

  sortBy(xs: A[]) {
    return xs.slice().sort(this.sort.bind(this))
  }
}

class DateColumn<A> extends Column<A, Nullable<Date>> {
  sort(a: A, b: A) {
    try {
      return basicSort(this.getValue(a)?.getTime(), this.getValue(b)?.getTime())
    } catch {
      return 0
    }
  }
}

export function define<A>() {
  return {
    boolean: (label: string, getValue: (a: A) => Nullable<boolean>, options?: Options<Nullable<boolean>>) =>
      new Column(label, getValue, options),

    string: (label: string, getValue: (a: A) => Nullable<string>, options?: Options<Nullable<string>>) =>
      new Column(label, getValue, options),

    number: (label: string, getValue: (a: A) => Nullable<number>, options?: Options<Nullable<number>>) =>
      new Column(label, getValue, options),

    date: (label: string, getValue: (a: A) => Nullable<Date>, options?: Options<Nullable<Date>>) =>
      new DateColumn(label, getValue, options),
  }
}
