import classnames from 'classnames'

import styles from './Spinner.module.scss'

export const Spinner: React.FC<{ className?: string }> = ({ className }) => (
  <div className={classnames(styles.spinner, className)} />
)

export const LoadingSpinner: React.FC<{ isFullScreen?: boolean }> = ({ isFullScreen }) => (
  <div className={classnames(styles.spinner, { [styles.isFullScreen]: isFullScreen })}>
    <span className={styles.text}>Loading</span>
  </div>
)
