import { useState, useMemo, PropsWithChildren, useCallback } from 'react'

import { Help, HighlightOff } from '@mui/icons-material'
import classnames from 'classnames'
import { FormikErrors, FormikTouched } from 'formik'

import { isEmpty, minNumberOfRakes, OverviewBarge } from '../../../Domain/Barge'
import { isGoalId } from '../../../Domain/Goal'
import { shortDropLanes } from '../../../Domain/Lane'
import { TowNominationInput } from '../../../Domain/Nomination'
import { HullType, Goal, GoalId, HubLikeId, LaneId } from '../../../generated/graphql'
import { useSettingsContext } from '../../../providers/SettingsProvider'
import {
  DropdownButton,
  DropdownButtonLabel,
  DropdownMenu,
  DropdownMenuItem,
  DropdownWrapper,
} from '../../../ui/Dropdown/Dropdown'
import { ErrorNotification } from '../../../ui/Form/ErrorNotifcation'
import { WarningMessage } from '../../../ui/MessageBox/MessageBox'
import { NumberedInput, defaultNumberedTheme } from '../../../ui/NumberInput/NumberInput'
import { Popover } from '../../../ui/Popover/Popover'
import { Toggle } from '../../../ui/Toggle/Toggle'

import { EditNominationBoat } from './EditNominationBoat'
import styles from './NominationForm.module.scss'

const dropdownOptions = {
  theme: { button: styles.dropdownButton, menu: styles.dropdownMenu, item: styles.dropdownItem },
}

const MINIMAL_NR_OF_BARGES = 2
export const TBN_BOAT_ID = '2402'

export type TowNominationFormInput = Omit<TowNominationInput, 'goal'> & {
  numberOfLoaded: number
  goal: GoalId | null
  hasTurnboat?: boolean
  numberOfStrings: number | null
}

function getMaxNumberOfStrings(numberOfLoaded: number, numberOfEmpties: number): number {
  const calculatedMaxNumberOfStrings = Math.floor((numberOfLoaded + numberOfEmpties) / 2)
  return Math.min(calculatedMaxNumberOfStrings, 8)
}
export const initialConfig: TowNominationFormInput = {
  goal: null,
  numberOfLoaded: 0,
  numberOfEmpties: 0,
  numberOfStrings: null,
  preselectedBarges: [],
  boat: TBN_BOAT_ID,
  hasTurnboat: false,
}

const numberOfStringsTheme = {
  container: classnames(styles.inputContainer),
}

function collectTotals(towConfiguration: TowNominationFormInput, barges: OverviewBarge[]) {
  const totalNumberOfBarges = barges.length

  const { empties: totalNumberOfEmpties, rakes: totalNumberOfRakes } = barges.reduce(
    (acc: { empties: number; rakes: number }, barge) => {
      if (isEmpty(barge)) {
        acc.empties += 1
      }
      if (barge.barge.hullType === HullType.Rake) {
        acc.rakes += 1
      }
      return acc
    },
    { empties: 0, rakes: 0 }
  )

  const totalNumberOfLoaded = totalNumberOfBarges - totalNumberOfEmpties

  const total = Math.max(towConfiguration.numberOfLoaded, MINIMAL_NR_OF_BARGES) + towConfiguration.numberOfEmpties

  const remainingNumberOfLoaded = totalNumberOfLoaded - towConfiguration.numberOfLoaded
  const remainingNumberOfEmpties = totalNumberOfEmpties - towConfiguration.numberOfEmpties
  const remainingNumberOfRakes =
    totalNumberOfRakes - minNumberOfRakes(towConfiguration.numberOfLoaded + towConfiguration.numberOfEmpties)
  const remainingNumberOfBarges = totalNumberOfBarges - total

  const remaining =
    remainingNumberOfLoaded >= 0
      ? {
          numberOfLoaded: remainingNumberOfLoaded,
          numberOfEmpties: remainingNumberOfEmpties,
          numberOfRakes: remainingNumberOfRakes,
          numberOfBarges: remainingNumberOfBarges,
          numberOfPreselected: towConfiguration.preselectedBarges.length,
        }
      : {
          numberOfLoaded: totalNumberOfLoaded,
          numberOfEmpties: totalNumberOfEmpties,
          numberOfRakes: totalNumberOfRakes,
          numberOfBarges: totalNumberOfBarges,
          numberOfPreselected: towConfiguration.preselectedBarges.length,
        }

  return {
    totalNumberOfLoaded,
    totalNumberOfEmpties,
    remaining,
    totalNumberOfBarges,
  }
}

export const TowConfigSection = ({
  destination,
  towConfiguration,
  setTowConfiguration,
  touched,
  errors,
  barges,
  lane,
  hasTimeWindow = false,
  isDisabled = false,
}: PropsWithChildren<{
  hasTimeWindow?: boolean
  isDisabled?: boolean
  destination?: HubLikeId
  barges: OverviewBarge[]
  lane?: LaneId
  towConfiguration: TowNominationFormInput
  setTowConfiguration: (config: TowNominationFormInput) => void
  touched?: FormikTouched<TowNominationFormInput>
  errors?: string | FormikErrors<TowNominationFormInput>
}>) => {
  const { goals } = useSettingsContext()
  const [warning, setWarning] = useState<string>()

  const { numberOfLoaded, numberOfEmpties, goal, preselectedBarges, boat, hasTurnboat } = useMemo(
    () => towConfiguration || initialConfig,
    [towConfiguration]
  )

  const maxValue = getMaxNumberOfStrings(numberOfLoaded, numberOfEmpties)

  const getErrorMessage = useCallback(
    (field: keyof TowNominationFormInput): string | undefined => {
      if (typeof errors === 'string') {
        return errors
      }
      return errors?.[field] as string | undefined
    },
    [errors]
  )

  const warnings = useMemo(() => {
    const newWarnings: { numberOfLoaded?: string; goal?: string; boat?: string; numberOfStrings?: string } = {}
    if (touched?.numberOfLoaded && getErrorMessage('numberOfLoaded')) {
      newWarnings.numberOfLoaded = getErrorMessage('numberOfLoaded')
    }
    if (touched?.goal && getErrorMessage('goal')) {
      newWarnings.goal = getErrorMessage('goal')
    }
    if (touched?.goal && getErrorMessage('boat')) {
      newWarnings.boat = getErrorMessage('boat')
    }
    if (touched?.numberOfStrings && getErrorMessage('numberOfStrings')) {
      newWarnings.numberOfStrings = getErrorMessage('numberOfStrings')
    }
    return newWarnings
  }, [touched, getErrorMessage])

  const { totalNumberOfLoaded, totalNumberOfEmpties, remaining } = useMemo(
    () => collectTotals(towConfiguration, barges),
    [towConfiguration, barges]
  )

  function isGoalDisabled(g: GoalId, laneId?: LaneId, timeWindow?: boolean): boolean {
    const isLinehaulDisabled = g === GoalId.LinehaulTurnTime && timeWindow === false
    const isShortDropDisabled = g === GoalId.ShortDropTow && (laneId === undefined || !shortDropLanes.includes(laneId))
    return isLinehaulDisabled || isShortDropDisabled
  }

  return (
    <section className={styles.section}>
      {warning && (
        <WarningMessage title="Ooops, we're sorry" handleClose={() => setWarning(undefined)}>
          {warning}
        </WarningMessage>
      )}
      <section className={styles.section}>
        <span className={styles.label}>{preselectedBarges.length} Preselected barges</span>
      </section>
      <section className={styles.section}>
        <span className={styles.label}>Number of loaded</span>
        <NumberedInput
          min={0}
          max={totalNumberOfLoaded}
          size={totalNumberOfLoaded.toString().length}
          handleInput={nr =>
            setTowConfiguration({
              ...towConfiguration,
              numberOfLoaded: nr,
            })
          }
          theme={defaultNumberedTheme}
          input={{
            step: 1,
            value: numberOfLoaded,
          }}
        />
        {warnings.numberOfLoaded && <ErrorNotification message={warnings.numberOfLoaded} />}
        {remaining.numberOfRakes > 0 &&
          remaining.numberOfRakes < minNumberOfRakes(numberOfLoaded + numberOfEmpties) && (
            <ErrorNotification
              message={`Not enough rakes available for this number of barges (need at least ${minNumberOfRakes(
                numberOfLoaded + numberOfEmpties
              )}, only ${remaining.numberOfRakes} available )`}
            />
          )}
      </section>
      <section className={styles.section}>
        <span className={styles.label}>Number of empty barges</span>
        <NumberedInput
          min={0}
          max={totalNumberOfEmpties}
          size={2}
          handleInput={(nr: number) => {
            setTowConfiguration({
              ...towConfiguration,
              numberOfEmpties: nr,
            })
          }}
          theme={defaultNumberedTheme}
          input={{
            step: 1,
            value: numberOfEmpties,
          }}
        />
      </section>
      <section className={styles.section}>
        <span className={styles.label}>Number of strings</span>
        <div className={styles.numberOfStringsWrapper}>
          <NumberedInput
            min={0}
            max={maxValue}
            size={2}
            placeholder={maxValue.toString()}
            handleInput={nr => {
              setTowConfiguration({
                ...towConfiguration,
                numberOfStrings: nr,
              })
            }}
            theme={numberOfStringsTheme}
            input={{
              name: 'towConfiguration.numberOfStrings',
              step: 1,
              value: towConfiguration.numberOfStrings || '',
            }}
          />
          <HighlightOff
            className={classnames(styles.clear, { [styles.isDisabled]: isDisabled })}
            onClick={e => {
              e.stopPropagation()
              setTowConfiguration({
                ...towConfiguration,
                numberOfStrings: null,
              })
            }}
          />
        </div>
        {warnings.numberOfStrings && <ErrorNotification message={warnings.numberOfStrings} />}
      </section>
      <section className={styles.section}>
        <div className={classnames(styles.label, styles.goals)}>
          <span>Operational goal</span>
        </div>
        <DropdownWrapper>
          <DropdownButton isDisabled={isDisabled} as="div" className={dropdownOptions.theme.button}>
            <DropdownButtonLabel>
              {isGoalId(goal) && goal in goals ? (
                <>
                  {goals[goal].label} <GoalDescription goal={goals[goal]} />
                </>
              ) : (
                'Select a goal'
              )}
            </DropdownButtonLabel>
          </DropdownButton>
          <DropdownMenu className={dropdownOptions.theme.menu}>
            {Object.keys(goals)
              .filter(isGoalId)
              .map(g => (
                <DropdownMenuItem
                  key={g}
                  className={dropdownOptions.theme.item}
                  isDisabled={isGoalDisabled(g, lane, hasTimeWindow)}
                  handleClick={() => {
                    setTowConfiguration({
                      ...towConfiguration,
                      goal: g,
                    })
                  }}>
                  {goals[g].label}
                </DropdownMenuItem>
              ))}
          </DropdownMenu>
        </DropdownWrapper>
        {warnings.goal && <ErrorNotification message={warnings.goal} />}
      </section>
      <VesselSection
        isDisabled={isDisabled}
        destination={destination}
        warnings={warnings}
        value={boat}
        handleChange={boatId => {
          setTowConfiguration({
            ...towConfiguration,
            boat: boatId,
          })
        }}
      />
      <section className={styles.section}>
        <label className={classnames(styles.label, styles.hotBargeToggle)}>
          <Toggle
            enabled={!isDisabled && (hasTurnboat ?? false)}
            onChange={() => {
              setTowConfiguration({
                ...towConfiguration,
                hasTurnboat: !hasTurnboat,
              })
            }}
          />
          <h3 className={styles.formTitle}>Turnboat</h3>
        </label>
      </section>
    </section>
  )
}

export const GoalDescription = ({ goal }: { goal: Pick<Goal, 'label' | 'description'> }) => (
  <Popover
    button={<Help />}
    placement="bottom-end"
    theme={{ popover: styles.popover, panelWrapper: styles.panelWrapper, panel: styles.panel }}>
    {goal ? (
      <>
        <h3>{goal.label}</h3>
        <p>{goal.description}</p>
      </>
    ) : null}
  </Popover>
)

const VesselSection = (props: {
  isDisabled: boolean
  destination?: HubLikeId
  warnings: { boat?: string }
  value?: string
  handleChange: (value?: string) => void
}) => (
  <section className={styles.section}>
    <label className={styles.label}>Vessel</label>
    <EditNominationBoat {...props} />
    {props.warnings.boat && <ErrorNotification message={props.warnings.boat} />}
  </section>
)
