import { Switch } from '@headlessui/react'
import classNames from 'classnames'

import styles from './Toggle.module.scss'

type ToggleProps = {
  enabled: boolean
  onChange: (enabled: boolean) => void
}

export const Toggle = ({ enabled, onChange }: ToggleProps) => {
  const statusClass = enabled ? styles.enabled : styles.disabled
  return (
    <Switch checked={enabled} className={classNames(styles.toggle, statusClass)} onChange={onChange}>
      <span className={classNames(styles.toggleIcon)} />
    </Switch>
  )
}
