const getEnvironmentVariable = (name: string): string => {
  const value = process.env[name]

  if (value === undefined) {
    throw new Error(`Key ${name} is not defined in environment`)
  } else if (value === '') {
    throw new Error(`Key ${name} is defined in environment as an empty string`)
  }
  return value
}

export const DEPLOYMENT_ENVIRONMENT = getEnvironmentVariable('REACT_APP_DEPLOYMENT_ENVIRONMENT')
export const LOG_FRONTEND_ERRORS = getEnvironmentVariable('REACT_APP_LOG_FRONTEND_ERRORS')
export const API_URL = getEnvironmentVariable('REACT_APP_API_URL')
export const LOCAL_STORAGE_VERSION = getEnvironmentVariable('REACT_APP_LOCAL_STORAGE_VERSION')
export const SENTRY_DSN = getEnvironmentVariable('REACT_APP_SENTRY_DSN')

export const COGNITO_USER_POOL = getEnvironmentVariable('REACT_APP_COGNITO_USER_POOL')
export const COGNITO_CLIENT_ID = getEnvironmentVariable('REACT_APP_COGNITO_CLIENT_ID')
export const COGNITO_DOMAIN = getEnvironmentVariable('REACT_APP_COGNITO_POOL_DOMAIN')
export const COGNITO_REDIRECT_URL = getEnvironmentVariable('REACT_APP_COGNITO_REDIRECT_URL')

export const GRAPHQL_API_URL = process.env.REACT_APP_GRAPHQL_API_URL ?? API_URL
export const isTestEnv = DEPLOYMENT_ENVIRONMENT === 'test'
export const isAcceptanceEnv = DEPLOYMENT_ENVIRONMENT === 'acceptance'
export const isProductionEnv = DEPLOYMENT_ENVIRONMENT === 'production'
