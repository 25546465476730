import React from 'react'

import { Dialog } from '@headlessui/react'
import { Close } from '@mui/icons-material'

import { Modal } from './Modal'
import styles from './Modal.module.scss'

type InformationModalProps = {
  onClose: () => void
  title: string
  body: React.ReactNode
  isOpen: boolean
  withCloseButton?: boolean
}

export const InformationModal = ({ isOpen, onClose, title, body, withCloseButton }: InformationModalProps) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    {withCloseButton && (
      <button onClick={onClose} className={styles.closeButton}>
        <Close />
      </button>
    )}
    <div className={styles.content}>
      <Dialog.Title className={styles.title}>{title}</Dialog.Title>
      <Dialog.Description className={styles.text}>{body}</Dialog.Description>
    </div>
    <div className={styles.footer}>
      <button onClick={onClose} className={styles.button}>
        OK
      </button>
    </div>
  </Modal>
)
