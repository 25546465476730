import { type FC, useCallback, useEffect, useMemo, useState } from 'react'

import { CopyAll } from '@mui/icons-material'
import { Tooltip } from '@mui/material'

import styles from './CopyDataButton.module.scss'

type Props = {
  getData: () => string
  tooltipText: string
  successText: string
}

const CopyDataButton: FC<Props> = ({ getData, tooltipText, successText }) => {
  const [open, setOpen] = useState(false)
  const [copied, setCopied] = useState(false)

  const onOpen = useCallback(() => setOpen(true), [])
  const onClose = useCallback(() => {
    if (!copied) {
      setOpen(false)
    }
  }, [copied])

  const title = useMemo(() => (copied ? tooltipText : successText), [copied, tooltipText, successText])

  useEffect(() => {
    if (!copied) {
      return undefined
    }

    const timeout = setTimeout(() => setCopied(false), 1000)

    return () => clearTimeout(timeout)
  }, [copied])

  return (
    <Tooltip open={open} onOpen={onOpen} onClose={onClose} title={title} placement="top">
      <button
        type="button"
        className={styles.copyButton}
        onClick={() => {
          navigator.clipboard.writeText(getData())
          setCopied(true)
        }}>
        <CopyAll />
      </button>
    </Tooltip>
  )
}

export default CopyDataButton
