import {
  type Context,
  createContext,
  type FC,
  type PropsWithChildren,
  useCallback,
  useContext,
  useReducer,
} from 'react'

import * as R from 'ramda'

function toggleInCollections<T>(item: T, target: T[], mirror: T[]): [T[], T[]] {
  if (R.indexOf(item, target) >= 0) {
    return [R.reject(R.equals(item), target), mirror]
  }

  return [R.append(item, target), R.reject(R.equals(item), mirror)]
}

type BargeSelectionState = {
  preSelectedBarges: Array<string>
  preExcludedBarges: Array<string>
}

const initialBargeSelectionState: BargeSelectionState = {
  preSelectedBarges: [],
  preExcludedBarges: [],
}

interface BargeSelectionBridgeInterface extends BargeSelectionState {
  togglePreSelectionFor(bargeId: string): void
  togglePreExclusionFor(bargeId: string): void
  clearPreSelectionAndExclusion(): void
}

const stub = (): never => {
  throw new Error('You forgot to wrap your component in <BargeSelectionBridgeProvider>.')
}

const initialState: BargeSelectionBridgeInterface = {
  ...initialBargeSelectionState,
  togglePreSelectionFor: stub,
  togglePreExclusionFor: stub,
  clearPreSelectionAndExclusion: stub,
}

const BargeSelectionBridge: Context<BargeSelectionBridgeInterface> =
  createContext<BargeSelectionBridgeInterface>(initialState)

enum ActionTypes {
  TOGGLE_PRE_SELECTION = 'TOGGLE_PRE_SELECTION',
  TOGGLE_PRE_EXCLUSION = 'TOGGLE_PRE_EXCLUSION',
  CLEAR_ALL = 'CLEAR_ALL',
}

type ToggleActionPayload = { bargeId: string }
type ToggleAction =
  | { type: ActionTypes.TOGGLE_PRE_SELECTION; payload: ToggleActionPayload }
  | { type: ActionTypes.TOGGLE_PRE_EXCLUSION; payload: ToggleActionPayload }
  | { type: ActionTypes.CLEAR_ALL }

const reducePreSelection = (state: BargeSelectionState, payload: ToggleActionPayload) => {
  const [updatedSelected, maybeUpdatedExcluded] = toggleInCollections(
    payload.bargeId,
    state.preSelectedBarges,
    state.preExcludedBarges
  )
  return {
    preSelectedBarges: updatedSelected,
    preExcludedBarges: maybeUpdatedExcluded,
  }
}

const reducePreExclusion = (state: BargeSelectionState, payload: ToggleActionPayload) => {
  const [updatedExcluded, maybeUpdatedSelected] = toggleInCollections(
    payload.bargeId,
    state.preExcludedBarges,
    state.preSelectedBarges
  )
  return {
    preSelectedBarges: maybeUpdatedSelected,
    preExcludedBarges: updatedExcluded,
  }
}

const reducer = (state: BargeSelectionState, action: ToggleAction) => {
  switch (action.type) {
    case ActionTypes.TOGGLE_PRE_SELECTION:
      return reducePreSelection(state, action.payload)
    case ActionTypes.TOGGLE_PRE_EXCLUSION:
      return reducePreExclusion(state, action.payload)
    case ActionTypes.CLEAR_ALL:
      return initialBargeSelectionState
    default:
      return state
  }
}

const BargeSelectionBridgeProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialBargeSelectionState)

  const togglePreSelectionFor = useCallback(
    (bargeId: string) => {
      dispatch({ type: ActionTypes.TOGGLE_PRE_SELECTION, payload: { bargeId } })
    },
    [dispatch]
  )

  const togglePreExclusionFor = useCallback(
    (bargeId: string) => {
      dispatch({ type: ActionTypes.TOGGLE_PRE_EXCLUSION, payload: { bargeId } })
    },
    [dispatch]
  )

  const clearPreSelectionAndExclusion = useCallback(() => dispatch({ type: ActionTypes.CLEAR_ALL }), [dispatch])

  const providerOps: BargeSelectionBridgeInterface = {
    ...state,
    togglePreSelectionFor,
    togglePreExclusionFor,
    clearPreSelectionAndExclusion,
  }

  return <BargeSelectionBridge.Provider value={providerOps}>{children}</BargeSelectionBridge.Provider>
}

export default BargeSelectionBridgeProvider

export const useBargeSelectionBridge: () => BargeSelectionBridgeInterface = () => useContext(BargeSelectionBridge)
