import { createContext, PropsWithChildren, useContext } from 'react'

import {
  Goal,
  GoalId,
  HubLikeId,
  LaneId,
  HubLike,
  GraphqlTripStatus as TripStatus,
  NominatableBoat,
  useSettingsQuery,
  TboSubmissionStatusId,
} from '../generated/graphql'
import { LoadingSpinner } from '../ui/Spinner/Spinner'

export type State = {
  lanes: Record<LaneId, string>
  goals: Record<GoalId, Pick<Goal, 'label' | 'description'>>
  hubs: Record<HubLikeId, HubLike>
  tripStatuses: Record<TripStatus, string>
  boats: NominatableBoat[]
  tboSubmissionStatuses: Record<TboSubmissionStatusId, string>
}

export const SettingsContext = createContext({} as State)

export const emptyGoals: State['goals'] = {} as any

export const emptyLanes: State['lanes'] = {} as any

export const emptyHubs: State['hubs'] = {} as any

export const emptyTripStatuses: State['tripStatuses'] = {} as any

export const emptyBoats: State['boats'] = []

export const emptyTboSubmissionStatuses: State['tboSubmissionStatuses'] = {} as any

export const useSettingsContext = () => useContext(SettingsContext)

export function SettingsProvider({ children }: PropsWithChildren) {
  const [{ fetching: loading, data }] = useSettingsQuery({ requestPolicy: 'network-only' })

  if (loading || data === undefined) {
    return <LoadingSpinner isFullScreen />
  }

  const state: State = {
    lanes: data.lanes.reduce((acc: State['lanes'], lane) => ({ ...acc, [lane.id]: lane.label }), emptyLanes),
    goals: data.goals.reduce(
      (acc: State['goals'], goal) => ({ ...acc, [goal.id]: { label: goal.label, description: goal.description } }),
      emptyGoals
    ),
    tripStatuses: data.tripStatuses.reduce(
      (acc: State['tripStatuses'], tripStatus) => ({ ...acc, [tripStatus.id]: tripStatus.label }),
      emptyTripStatuses
    ),

    hubs: data.hubLikes.reduce((acc: State['hubs'], hub) => ({ ...acc, [hub.id]: hub }), emptyHubs),
    boats: data.boats,
    tboSubmissionStatuses: data.tboSubmissionStatuses.reduce(
      (acc: State['tboSubmissionStatuses'], s) => ({ ...acc, [s.id]: s.displayName }),
      emptyTboSubmissionStatuses
    ),
  }

  return <SettingsContext.Provider value={state}>{children}</SettingsContext.Provider>
}
