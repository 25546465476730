import React from 'react'

import { FormikErrors } from 'formik'

import { OverviewBarge } from '../../../../Domain/Barge'

import OperationalGoalForm from './OperationalGoalForm'
import PrioritizeHotBarges from './PrioritizeHotBarges'
import TowParametersForm from './TowParametersForm'

import type { GoalId } from '../../../../generated/graphql'
import type { TowParametersFormValues } from '../types'

type TowParametersSelectionFormProps = {
  data: TowParametersFormValues
  onChange: (config: TowParametersFormValues) => void
  errors?: FormikErrors<TowParametersFormValues>
  barges: OverviewBarge[]
  goals: Record<GoalId, { label: string; isDisabled: boolean }>
  prioritizeHotBarges: boolean
}

const TowParametersSelectionForm = ({ data, onChange, errors, barges, goals }: TowParametersSelectionFormProps) => {
  return (
    <div>
      <TowParametersForm data={data} setData={onChange} errors={errors} barges={barges} />
      <PrioritizeHotBarges
        barges={barges ?? []}
        prioritizeHotBarges={data.hotBarges}
        setPrioritizeHotBarges={val => onChange({ ...data, hotBarges: val })}
      />
      <OperationalGoalForm data={data} onChange={onChange} errors={errors} goals={goals} />
    </div>
  )
}

export default TowParametersSelectionForm
