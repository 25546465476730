import { Check } from '@mui/icons-material'
import { match } from 'ts-pattern'

import { ELLIPSIS } from '../../../constants/constants'
import { isGoalId } from '../../../Domain/Goal'
import { NominatedBarge } from '../../../Domain/Nomination'
import { showRiverLocation } from '../../../Domain/River'
import { GoalId } from '../../../generated/graphql'
import { define } from '../../../lib/Column'
import { map } from '../../../lib/Nullable'
import { useSettingsContext } from '../../../providers/SettingsProvider'
import { HeaderCell, TB, TBBody, TBHead, TBR } from '../../../ui/Table/Table'
import { TableCell } from '../../../ui/Table/TableCell'

import styles from './GoalsDiff.module.scss'

type Entry = { id: string; barge: NominatedBarge; goals: GoalId[] }

const col = define<Entry>()

const columns = {
  name: col.string('Name', _ => _.barge.name),
  [GoalId.BuildToDestination]: col.boolean(GoalId.BuildToDestination, _ => _.goals.includes(GoalId.BuildToDestination)),
  [GoalId.FleetEfficiency]: col.boolean(GoalId.FleetEfficiency, _ => _.goals.includes(GoalId.FleetEfficiency)),
  [GoalId.LinehaulTurnTime]: col.boolean(GoalId.LinehaulTurnTime, _ => _.goals.includes(GoalId.LinehaulTurnTime)),
  [GoalId.ShortDropTow]: col.boolean(GoalId.ShortDropTow, _ => _.goals.includes(GoalId.ShortDropTow)),
  cargo: col.string('Cargo', _ => _.barge.cargo),
  destination: col.string('Destination', ({ barge: { destination } }) => map(destination, showRiverLocation)),
  hullType: col.string('Hull Type', _ => _.barge.hullType),
  pickup: col.string('Pickup', ({ barge: { pickupFacility } }) => map(pickupFacility, showRiverLocation)),
  dropoff: col.string('Drop-off', ({ barge: { dropOffFacility } }) => map(dropOffFacility, showRiverLocation)),
  currentLocation: col.string('currentLocation', ({ barge }) => {
    if (barge.currentLocation) {
      return map(barge.currentLocation, showRiverLocation)
    }
    return 'N/A'
  }),
}

export type ColumnKey = keyof typeof columns
export const columnKeys: ColumnKey[] = [
  'name',
  GoalId.BuildToDestination,
  GoalId.FleetEfficiency,
  GoalId.LinehaulTurnTime,
  GoalId.ShortDropTow,
  'cargo',
  'destination',
  'hullType',
  'pickup',
  'dropoff',
]

export function CombinedBargesTable({
  rows,
  hasTimeWindow,
  goalBargeCount,
}: {
  rows: Entry[]
  hasTimeWindow: boolean
  goalBargeCount: Record<string, number>
}) {
  const { goals } = useSettingsContext()
  const keys = columnKeys.filter(key => hasTimeWindow || key !== GoalId.LinehaulTurnTime)

  return (
    <TB className={styles.table}>
      <TBHead>
        <TBR>
          {keys.map(key => {
            const c = columns[key]
            const className = match(key)
              .with('name', () => styles.nameCell)
              .with('hullType', () => styles.hullTypeCell)
              .with('destination', () => styles.cell)
              .with('pickup', () => styles.cell)
              .with('dropoff', () => styles.cell)
              .otherwise(() => undefined)

            return (
              <HeaderCell key={key} className={className}>
                {isGoalId(key) ? `${goals[key].label} (${goalBargeCount[key]})` : c.label}
              </HeaderCell>
            )
          })}
        </TBR>
      </TBHead>
      <TBBody>
        {rows.map((row, index) => (
          <TBR key={`${row.id}-${index}`} isOdd={index % 2 > 0}>
            {keys.map(key => {
              const c = columns[key]

              return (
                <TableCell key={key}>
                  {isGoalId(key) ? (
                    <>{c.getValue(row) ? <Check className={styles.check} /> : ELLIPSIS}</>
                  ) : (
                    c.format(row)
                  )}
                </TableCell>
              )
            })}
          </TBR>
        ))}
      </TBBody>
    </TB>
  )
}
