import { AdNote, AdWarning } from './Admonition/Admonintion'
import { P } from './Components/Components'

export const BargeGroupsDoc = () => (
  <>
    <h1>Barge groups</h1>
    <h2 data-section="overview">Overview</h2>
    <P>
      Barge groups are a sequence of barges in a string (i.e. they are connected by couplings) that have the same
      drop-off location and also follow a hydrodynamic pattern in their hull types and hull directions. Barge groups are
      valuable to detect and track in the barge nomination engine because they can are easier to efficiently handle in
      operations, and easier to plan for in logistics.
    </P>
    <h2 data-section="model">Model for detecting barge groups</h2>
    <P>
      <b>Data inputs</b>: The model uses a UTM data connection to find the tow configuration for barges in custody of
      each line-haul vessel. The relevant attributes of each barge that the model uses are:
      <ul>
        <li>Hull type (Rake vs Box)</li>
        <li>Raked hull direction (Upstream vs Downstream)</li>
        <li>Drop-off location</li>
        <li>Row and column slot in the configuration</li>
      </ul>
      The model looks down each string (column) of a vessel’s tow and detects groups of barges with the same drop-off
      that are arranged in a pattern like this:
    </P>
    <P>
      <pre><b>&lt;rake]:[box]*N:[rake&gt; </b></pre>
    </P>
    <P>
      Three things to note are:
      <ol>
        <li>
          The <pre><b>&lt;rake]</b></pre> barge has its rake hull in the opposite direction as the <pre><b>[rake&gt;</b></pre> barge
        </li>
        <li>
          There can be any number of <pre><b>[box]</b></pre> barges in between the two oppositely pointed rake barges (including
          zero)
        </li>
        <li>
          The model only looks down a <i>single</i> string (column) of the tow configuration. Groups that going to
          the same drop-off, but are in neighbouring strings (columns) are not labelled as being part of the same group.
        </li>
      </ol>
      <AdNote title="Future development">
        Right now we only look at barges currently in a tow; we will extend the model to detect groups of barges in
        fleet (+ be able to track groups from tow to fleet) in upcoming development cycles
      </AdNote>
    </P>
    <h2 data-section="web-app">Web-app interface</h2>
    <h3>Group colors</h3>
    <P>
      Barge groups are visually identified in the "Barge List" view of the web app using colored link icons on the left
      side of the relevant barges. Each barge group is visually linked with the same color icon. Notice
      in the screenshot that each barge group here has:
      <ul>
        <li>One down stream rake</li>
        <li>One up stream rake</li>
        <li>A varying number of box barges</li>
      </ul>
      This pattern will hold for <i>most</i> cases when viewing a pool (for exceptions, so see the warning below).
      Further information on what to expect and how to understand the patterns used to detect groups can be found in the
      Advanced Points section of this page.
    </P>
    <AdWarning title="Warning on filters">
      Depending on the toggle filters for the pool, not all barges in each group may be visible, so groups could be
      incomplete in the pool
    </AdWarning>
    <h3>Group IDs</h3>
    <P>
      Hovering over the icon for a given barge generates a pop-up that says the group ID of that color.
      The text of each group ID is fairly long and difficult to read. This is because the IDs need to be unique for
      every barge group that might ever be detected. However, the text of the ID itself says nothing important.
    </P>
    <h2 data-section="advanced">Advanced points</h2>
    <h3 data-section="hull-pattern">On the hull pattern</h3>
    <P>
      The pattern only identifies <i>fully slick</i> groups of barges in a single string. Slickness refers to the
      hydrodynamic properties of the group, i.e. how smoothly water can flow under and around the group. By <i>fully</i>{' '}
      slick, we mean that there is a smooth raked hull at both the head and the stern of the group, and only box hulls
      allowed in the interior of the group.
    </P>
    <h4>Reasoning</h4>
    <P>
      We want to track barge groups that are independently hydrodynamic so that each group could be swapped in and out
      of a list and still ensure good practice regarding even numbers of rakes, as well as be assembled one group to
      another without having a box hull connect to a raked hull
    </P>
    <h3>Examples</h3>
    <P>
      For example, the pattern <pre><b>&lt;rake]:[box]:[box]</b></pre>, which only has one rake, is not a fully slick string of
      barges because the front <pre><b>[box]</b></pre> barge is not hydrodynamic without a forward-facing <pre><b>[rake&gt;</b></pre> barge.
    </P>
    <P>
      Moreover, there can only be <i>two</i> rake barges in a barge group: one at the head and one at the stern. Therefore, the 
      pattern <pre><b>&lt;rake]:[box]:[rake&gt;:&lt;rake]:[rake&gt;</b></pre>, in which all five barges go to the same drop-off location, is
      detected as <i>two</i> groups.
      {/* This can be also be seen clearly in the tow diagram of Figure 3: the purple and
      orange barges in the left-most column of the tow are all going to the same destination. However, they are not
      detected as one barge group by our model because there would be raked hulls in the interior of the group. Instead,
      they are detected in two groups, one shown in purple and the other in orange. */}
    </P>
    <h3>Group identity</h3>
    <P>
      If we look at every barge in-tow at any one snapshot in time, each barge can only belong to a
      <i> maximum of one </i>
      group (or none).
    </P>
    <P>
      <b>Data tracking:</b> We generate a unique ID for each barge group & store it as a persistent data field. Barge
      group IDs are created once a new barge group is detected and then they are ended once there is any change in the
      configuration pattern (ex. a barge is removed or added). Once a change is detected the original barge group ID
      cannot be used again, and a new ID is generated, if a new qualifying pattern is detected after the change.
    </P>
    <P>
      Note that, at the moment, groups are <i>only</i> detected for barges that are in-tow, i.e. that are in custody of
      a vessel. Once these groups are dropped off the group ID is no longer active, and if the same group of barges
      happens to continue together onto another vessel to the same destination, they will be given a new group ID. In
      upcoming improvements, we will extend our ability to track and detect groups also when they are in custody of
      fleets.
    </P>
    <h4>Reasoning</h4>
    <P>
      By requiring that each barge can only belong to at most one group we can more efficiently use the group ID as a
      variable in algorithms, as well as use the group ID as a summary field in reporting.
    </P>
    <h4>Added value</h4>
    <P>
      Moreover, by storing the ID as a persistent data field, there are valuable opportunities to track performance over
      a network. A few examples include:
      <ul>
        <li>How many barge groups actually travel in-tow from the lists that a user sends for TBO's</li>
        <li>
          How many groups are broken apart at transshipment stops, which could have travelled together to a next
          drop-off point?
        </li>
        <li>
          What percentage of barge journeys flow through the network in groups over a given period of time? A higher
          percent suggests more efficiency in the network. This includes:{' '}
          <ul>
            <li>
              <i>Fleet efficiency</i> – because there would be fewer couplings to break/make and fewer fleet moves to
              make
            </li>
            <li>
              <i>Planning efficiency</i> – because groups from origin area of the network were efficiently planned to
              continue through to destination area of the network
            </li>
          </ul>
        </li>
      </ul>
    </P>
  </>
)
