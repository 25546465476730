import React, { useEffect, useMemo, useState } from 'react'

import { AddCircle, ChevronRight } from '@mui/icons-material'
import classnames from 'classnames'
import { getYear } from 'date-fns'
import useLocation from 'wouter/use-location'

import { isProductionEnv } from '../../../constants/env-vars'
import { useAuthorizationContext } from '../../../Pages/Account/AuthorizationContext'
import { useFeatureFlagProvider } from '../../../providers/FeatureFlagProvider'
import { Toggle } from '../../../ui/Toggle/Toggle'
import { BugReporter } from '../../BugReporter/BugReporter'
import { DrillDown, DrillDownLevel } from '../DrillDownLevel/DrillDownLevel'
import { NavigationButton, NavigationItem, NavigationLink } from '../NavigationItem/NavigationItem'

import styles from './NavigationDrawer.module.scss'

import type { NominationCard } from '../../../Pages/Nominations/List/viewModel'

export const NavigationDrawer: React.FC<{ isDrawerOpen: boolean; setIsDrawerOpen: (b: boolean) => void }> = ({
  isDrawerOpen,
  setIsDrawerOpen,
}) => {
  const nominations: NominationCard[] = []
  const [location] = useLocation()

  const { experimentalFeaturesEnabled, toggleExperimentalFeatures } = useFeatureFlagProvider()

  const { logout } = useAuthorizationContext()

  useMemo(() => getYear(new Date()), [])

  const [isBugReportActive, setIsBugReportActive] = useState(false)

  useEffect(() => {
    if (!isDrawerOpen && !!isBugReportActive) {
      setIsBugReportActive(false)
    }
  }, [isDrawerOpen, isBugReportActive])

  const handleLogout = () => logout()

  useEffect(() => setIsDrawerOpen(false), [location, setIsDrawerOpen])

  return (
    <>
      {isDrawerOpen && (
        <div
          className={styles.navigationDrawerOverlay}
          onClick={() => setIsDrawerOpen(false)}
          role="button"
          tabIndex={0}
          aria-hidden="true"
        />
      )}
      <nav className={classnames(styles.navigationDrawer, { [styles.navigationDrawerOpen]: isDrawerOpen })}>
        <DrillDownLevel active={!isBugReportActive} position={DrillDown.LEFT}>
          <ul className={classnames(styles.section, styles.sectionTop)}>
            <li className={classnames(styles.section, styles.sectionElement)}>
              <h2 className={styles.header}>Barge Overview</h2>
              <ul className={styles.section}>
                <li className={styles.linkItem}>
                  <NavigationLink href="/barges">
                    <span className={styles.navigationItem}>Barge Pool</span>
                    <ChevronRight />
                  </NavigationLink>
                </li>
              </ul>
            </li>
            <li className={classnames(styles.section, styles.sectionElement)}>
              <h2 className={styles.header}>Nomination Overview</h2>
              <ul className={styles.section}>
                <li className={styles.linkItem} key="list">
                  <NavigationLink href="/nominations">All Nominations</NavigationLink>
                </li>
                {nominations.map(n => (
                  <li className={styles.linkItem} key={n.id}>
                    <NavigationLink href="#">
                      <span className={styles.navigationItem}>
                        <span className={styles.itinerary}>{n.title}</span>
                      </span>
                      <ChevronRight />
                    </NavigationLink>
                  </li>
                ))}
                <li className={styles.createNomination}>
                  <NavigationLink href="/nominations/new">
                    <span className={styles.createNominationButton}>
                      <AddCircle />
                      <span>Create New Nomination</span>
                    </span>
                  </NavigationLink>
                </li>
              </ul>
            </li>
          </ul>
          <ul className={classnames(styles.section, styles.sectionBottom)}>
            <li className={styles.entry}>
              <NavigationButton onClick={() => setIsBugReportActive(true)} text="Help & Documentation" />
            </li>
            {isProductionEnv ? null : (
              <li className={styles.entry}>
                <NavigationItem text="Experimental Features">
                  <Toggle
                    enabled={experimentalFeaturesEnabled}
                    onChange={() => toggleExperimentalFeatures(!experimentalFeaturesEnabled)}
                  />
                </NavigationItem>
              </li>
            )}
            <li className={styles.logoutEntry}>
              <button className={styles.logout} onClick={handleLogout}>
                Log out
              </button>
            </li>
          </ul>
        </DrillDownLevel>
        <DrillDownLevel active={isBugReportActive} position={DrillDown.RIGHT}>
          <ul className={styles.section}>
            <li className={styles.entry}>
              <BugReporter onBack={() => setIsBugReportActive(false)} isActive={isBugReportActive} />
            </li>
            <li className={styles.entry}>
              <NavigationLink href="/docs">
                Documentation
                <ChevronRight />
              </NavigationLink>
            </li>
          </ul>
        </DrillDownLevel>
      </nav>
    </>
  )
}
