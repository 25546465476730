import React, { type ReactNode } from 'react'

import FormBlockWithActions from './FormBlockWithActions'
import SummaryBlock from './SummaryBlock'

import type { StageActions } from './types'

type FormOrSummaryProps = {
  title: string
  isSelected: boolean
  isValid: boolean
  form: ReactNode
  summary: ReactNode
  actions: StageActions
}

const FormOrSummary = ({ title, isSelected, isValid, form, summary, actions }: FormOrSummaryProps) => {
  const { nextStage, previousStage, self } = actions
  return isSelected ? (
    <FormBlockWithActions title={title} nextStage={nextStage} previousStage={previousStage} isValid={isValid}>
      {form}
    </FormBlockWithActions>
  ) : (
    <SummaryBlock title={title} self={self} isValid={isValid}>
      {summary}
    </SummaryBlock>
  )
}

export default FormOrSummary
