import { useMemo } from 'react'

import classnames from 'classnames'

import { OverviewBarge } from '../../../Domain/Barge'
import { Toggle } from '../../../ui/Toggle/Toggle'

import styles from './NominationForm.module.scss'

export function HotBargeSection({
  barges,
  prioritizeHotBarges,
  setPrioritizeHotBarges,
  isDisabled,
}: {
  barges: OverviewBarge[]
  prioritizeHotBarges: boolean
  setPrioritizeHotBarges: (val: boolean) => void
  isDisabled: boolean
}) {
  const count = useMemo(() => barges.filter(b => b.isHot).length, [barges])

  return (
    <section className={styles.section}>
      <label className={classnames(styles.label, styles.hotBargeToggle)}>
        <Toggle enabled={!isDisabled && prioritizeHotBarges} onChange={setPrioritizeHotBarges} />
        <span className={styles.toggleLabel}>Prioritize hot barges ({count})</span>
      </label>
    </section>
  )
}
