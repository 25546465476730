import React from 'react'

import { Popover } from '@headlessui/react'
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import * as R from 'ramda'

import styles from './Selector.module.scss'

type SelectorProps<K extends string | number, V extends string> = {
  label: string
  selected: K
  options: Record<K, V>
  onChange: (key: K) => void
  tooltipText?: string
}

const KVSelector = <K extends string | number, V extends string>({
  label,
  selected,
  options,
  onChange,
  tooltipText,
}: SelectorProps<K, V>) => {
  const optionPairs = R.toPairs(options)
  const dropdownOptions = R.map(([key, optionLabel]) => {
    const selectorId = `selector-${key}`
    return (
      <div key={key}>
        <input
          type="radio"
          id={selectorId}
          checked={key === selected}
          onChange={() => onChange(key)}
          className={styles.radio}
          name="selector"
        />
        <label htmlFor={selectorId} className={styles.selectorLabel}>
          {optionLabel}
        </label>
      </div>
    )
  }, optionPairs)

  const fullLabel = `${label}: ${options[selected]}`

  return (
    <div className={styles.selectorContainer}>
      <span>{fullLabel}</span>
      <Popover>
        {({ open }) => (
          <>
            <Popover.Button className={styles.button}>{open ? <ArrowDropUp /> : <ArrowDropDown />}</Popover.Button>
            <Popover.Panel className={styles.popoverPanel}>
              <Tooltip title={tooltipText} placement="top">
                <div className={styles.selectorOptions}>{dropdownOptions}</div>
              </Tooltip>
            </Popover.Panel>
          </>
        )}
      </Popover>
    </div>
  )
}

export default KVSelector
