import { useCallback, useEffect, useRef, useState } from 'react'

import classnames from 'classnames'
import { Field, Formik, FormikHelpers } from 'formik'
import { Link, useLocation } from 'wouter'
import { object as YupObject, string as YupString } from 'yup'

import { SuccessMessage, WarningMessage } from '../../ui/MessageBox/MessageBox'

import styles from './Account.module.scss'
import { AuthorizationModes, useAuthorizationContext } from './AuthorizationContext'
import { ErrorNotification, Form } from './Form'
import { Layout } from './Layout'

type Credentials = {
  email: string
  password: string
}

const logInValidationSchema = YupObject().shape(
  {
    email: YupString().required('Required').email(),
    password: YupString().required('Required'),
  },
  []
)

const PasswordResetSuccess: React.FC<{ handleClose: () => void }> = ({ handleClose }) => (
  <SuccessMessage title="Success" handleClose={handleClose}>
    The password was successfully reset. Now you can log in using your new password.
  </SuccessMessage>
)

export function LoginPage() {
  const ref = useRef<number>()
  const [, navigate] = useLocation()
  const { login, loginWithToken, getAccessToken, initFederatedLogin, isAuthenticated, session, mode } =
    useAuthorizationContext()
  const [isFetchingToken, setIsFetchingToken] = useState(false)
  const [passwordResetSuccess, setPasswordResetSuccess] = useState(
    new URLSearchParams(window.location.search).get('passwordReset') !== null
  )
  const [parsingTokens, setParsingTokens] = useState(false)

  const [{ authenticationError, formValues }, setState] = useState<{
    formValues: Credentials
    authenticationError: Error | null
  }>({
    formValues: { email: '', password: '' },
    authenticationError: null,
  })

  useEffect(() => {
    // validation can only happen once
    // Strict mode in development will try to run it twice
    if (ref.current) {
      clearTimeout(ref.current)
    }

    ref.current = setTimeout(() => {
      if (session && 'accessToken' in session) {
        setIsFetchingToken(true)
        getAccessToken().catch(err => {
          setState(state => ({ ...state, authenticationError: err }))
          setIsFetchingToken(false)
        })
      }
    })

    return () => {
      if (ref.current) {
        clearTimeout(ref.current)
      }
    }
  }, [getAccessToken, navigate, session, setIsFetchingToken])

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/', { replace: true })
    }
  }, [isAuthenticated, navigate])

  useEffect(() => {
    if (mode === AuthorizationModes.FORCED_PASSWORD_RESET) {
      navigate('/forced-password-reset')
    }
  }, [mode, navigate])

  useEffect(() => {
    function setPasswordReset() {
      setPasswordResetSuccess(new URLSearchParams(window.location.search).get('passwordReset') !== null)
    }

    window.addEventListener('popstate', setPasswordReset)
    window.addEventListener('pushState', setPasswordReset)
    window.addEventListener('replaceState', setPasswordReset)

    return () => {
      window.removeEventListener('popstate', setPasswordReset)
      window.removeEventListener('pushState', setPasswordReset)
      window.removeEventListener('replaceState', setPasswordReset)
    }
  })

  useEffect(() => {
    if (!parsingTokens) {
      const params = new URLSearchParams(window.location.hash.substring(1))
      const idToken = params.get('id_token')
      const accessToken = params.get('access_token')
      const refreshToken = params.get('refresh_token')

      if (idToken && accessToken) {
        setParsingTokens(true)
        setIsFetchingToken(true)
        loginWithToken(idToken, accessToken, refreshToken)
          .then(() => {
            window.history.replaceState({}, document.title, window.location.pathname)
            setIsFetchingToken(false)
            setParsingTokens(false)
          })
          .catch(err => {
            setState(state => ({ ...state, authenticationError: err }))
            window.history.replaceState({}, document.title, window.location.pathname)
            setIsFetchingToken(false)
            setParsingTokens(false)
          })
      }
    }
  }, [navigate, setIsFetchingToken, loginWithToken, parsingTokens, setParsingTokens])

  const handleOnSubmit = useCallback(
    ({ email, password }: Credentials, { setSubmitting }: FormikHelpers<Credentials>) => {
      login(email, password).catch(err => {
        setState(state => ({
          ...state,
          authenticationError: err,
        }))
        setSubmitting(false)
      })
    },
    [login]
  )

  const federatedLoginButton = (isSubmitting: boolean) =>
    isFetchingToken ? (
      <button disabled className={classnames([styles.loading, styles.button])}>
        <span className={styles.loader} />
        Fetching token...
      </button>
    ) : (
      <button
        disabled={isSubmitting}
        className={classnames([styles.ssoLogin, styles.button])}
        type="button"
        onClick={initFederatedLogin}>
        Login using SSO
      </button>
    )

  return (
    <Layout>
      <Formik
        initialValues={formValues}
        initialErrors={authenticationError ? { password: authenticationError.message } : {}}
        initialTouched={authenticationError ? { password: true } : {}}
        validationSchema={logInValidationSchema}
        onSubmit={handleOnSubmit}>
        {({ isSubmitting, touched, errors }) => (
          <Form>
            <h2 title="Sign in" className={styles.title}>
              Sign in
            </h2>
            {authenticationError && (
              <WarningMessage
                title="Something went wrong"
                handleClose={() => setState(state => ({ ...state, authenticationError: null }))}>
                {authenticationError.message ? authenticationError.message : ''}
              </WarningMessage>
            )}
            {passwordResetSuccess && <PasswordResetSuccess handleClose={() => navigate('/login', { replace: true })} />}
            <Field
              id="email"
              name="email"
              className={classnames(styles.input, { [styles.hasError]: touched.email && errors.email })}
              disabled={isFetchingToken}
              placeholder="Username"
            />
            {touched.email && errors.email && <ErrorNotification message={errors.email} />}
            <Field
              id="password"
              name="password"
              className={classnames(styles.input, { [styles.hasError]: touched.password && errors.password })}
              type="password"
              disabled={isFetchingToken}
              placeholder="Password"
            />
            {touched.password && errors.password && <ErrorNotification message={errors.password} />}
            {isFetchingToken ? (
              <button disabled className={classnames([styles.loading, styles.button])}>
                <span className={styles.loader} />
                Fetching token...
              </button>
            ) : (
              <button disabled={isSubmitting} className={classnames([styles.submit, styles.button])} type="submit">
                Sign in
              </button>
            )}
            {federatedLoginButton(isSubmitting)}
            <Link className={styles.forgotPassword} to="/reset-password">
              Forgot password
            </Link>
          </Form>
        )}
      </Formik>
    </Layout>
  )
}
