import styles from './Form.module.scss'

type ErrorNotificationProps = { message: string }

export const ErrorNotification = ({ message }: ErrorNotificationProps) => (
  <div className={styles.errorNotification}>
    <span className={styles.note}>*</span>
    <span>{message}</span>
  </div>
)
