import { NominationStage, type StageActions, type StageTransition } from './types'

const stageTransitions: Record<NominationStage, StageTransition> = {
  [NominationStage.LaneSelection]: { previous: null, next: NominationStage.VesselSelection },
  [NominationStage.VesselSelection]: {
    previous: NominationStage.LaneSelection,
    next: NominationStage.DepartureTimeSelection,
  },
  [NominationStage.DepartureTimeSelection]: {
    previous: NominationStage.VesselSelection,
    next: NominationStage.PoolFiltersSelection,
  },
  [NominationStage.PoolFiltersSelection]: {
    previous: NominationStage.DepartureTimeSelection,
    next: NominationStage.TowParametersSelection,
  },
  [NominationStage.TowParametersSelection]: { previous: NominationStage.PoolFiltersSelection, next: null },
}

const maybeChangeStageHandler = (target: NominationStage | null, setStage: (s: NominationStage) => void) => {
  return target !== null ? () => setStage(target) : null
}

export function createStageNavigation(
  currentStage: NominationStage,
  setStage: (stage: NominationStage) => void
): StageActions {
  return {
    nextStage: maybeChangeStageHandler(stageTransitions[currentStage].next, setStage),
    self: () => setStage(currentStage),
    previousStage: maybeChangeStageHandler(stageTransitions[currentStage].previous, setStage),
  }
}
