import { createContext, FC, useContext, PropsWithChildren, Reducer, useReducer, useEffect, useCallback } from 'react'

import { CognitoUser, CognitoUserSession, ICognitoUserPoolData } from 'amazon-cognito-identity-js'

import { COGNITO_CLIENT_ID, COGNITO_DOMAIN, COGNITO_REDIRECT_URL, COGNITO_USER_POOL } from '../../constants/env-vars'

import { AuthorizationClient, AwsCognitoClient, NEW_PASSWORD_REQUIRED_ERROR } from './AuthorizationClient'

const FEDERATED_LOGIN_RESPONSE_TYPE = 'token'
const FEDERATED_LOGIN_SCOPE = 'aws.cognito.signin.user.admin+email+openid'

export enum AuthorizationModes {
  AUTHENTICATION_REQUIRED,
  FORCED_PASSWORD_RESET,
  AUTHENTICATED,
  INITIALIZING,
}

interface AuthorizationContextState {
  client: AuthorizationClient
  user?: CognitoUser
  session?: CognitoUserSession
  error?: Error | undefined
  isAuthenticated: boolean
  isLoading: boolean
  mode: AuthorizationModes
}

export type UserDetails = {
  sub: string
  email: string
  cognitoUsername: string
}

interface AuthorizationContextInterface extends AuthorizationContextState {
  // ID token can be used to assume the user is authenticated
  // or get user profile data
  // It cannot be used as a token to access APIs
  getIdToken(): Promise<any>
  // Access token is used to call APIs
  // it cannot be used to get information about the user
  getAccessToken(): Promise<any>

  login(email: string, password: string): Promise<any>
  loginWithToken(idToken: string, accessToken: string, refreshToken: string | null): Promise<any>
  logout(): Promise<any>
  forceResetPassword(password: string): Promise<any>
  forgotPassword(email: string): Promise<any>
  resetPassword(code: string, password: string): Promise<any>

  getUserInfo(): UserDetails | undefined

  initFederatedLogin(): void
}

const poolConfiguration: ICognitoUserPoolData = {
  UserPoolId: COGNITO_USER_POOL,
  ClientId: COGNITO_CLIENT_ID,
}

const stub = (): never => {
  throw new Error('You forgot to wrap your component in <AuthorizationContextProvider>.')
}

const initialAuthorizationContextState = {
  client: new AwsCognitoClient(poolConfiguration),
  isAuthenticated: false,
  isLoading: true,
  mode: AuthorizationModes.INITIALIZING,
}

export const initialState: AuthorizationContextInterface = {
  ...initialAuthorizationContextState,
  getIdToken: stub,
  getAccessToken: stub,
  login: stub,
  loginWithToken: stub,
  logout: stub,
  forceResetPassword: stub,
  forgotPassword: stub,
  resetPassword: stub,
  getUserInfo: stub,
  initFederatedLogin: stub,
}

export const AuthorizationContext = createContext<AuthorizationContextInterface>(initialState)

enum Actions {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',

  SET_FORCE_RESET_PASSWORD = 'SET_FORCE_RESET_PASSWORD',
  SET_RESET_PASSWORD = 'SET_RESET_PASSWORD',
  SET_SET_PASSWORD = 'SET_SET_PASSWORD',

  ERROR = 'ERROR',
}

type LoginPayload = { user: CognitoUser; session: CognitoUserSession }
type ForceResetPasswordPayload = { user: CognitoUser; attributes: any }
type ResetPasswordPayload = { user: CognitoUser }
type ErrorPayload = { user: CognitoUser; error: any }

type Action =
  | { type: Actions.LOGIN; payload: LoginPayload }
  | { type: Actions.LOGOUT }
  | { type: Actions.SET_FORCE_RESET_PASSWORD; payload: ForceResetPasswordPayload }
  | { type: Actions.SET_RESET_PASSWORD }
  | { type: Actions.SET_SET_PASSWORD; payload: ResetPasswordPayload }
  | { type: Actions.ERROR; payload: ErrorPayload }

const authReducer: Reducer<AuthorizationContextState, Action> = (state: AuthorizationContextState, action: Action) => {
  switch (action.type) {
    case Actions.LOGIN:
      return {
        ...state,
        user: action.payload.user,
        session: action.payload.session,
        isAuthenticated: true,
        isLoading: false,
        mode: AuthorizationModes.AUTHENTICATED,
      }
    case Actions.LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        isLoading: false,
        session: undefined,
        user: undefined,
        mode: AuthorizationModes.AUTHENTICATION_REQUIRED,
      }
    case Actions.SET_FORCE_RESET_PASSWORD:
      return {
        ...state,
        user: action.payload.user,
        session: action.payload.attributes,
        isLoading: false,
        isAuthenticated: false,
        mode: AuthorizationModes.FORCED_PASSWORD_RESET,
      }
    case Actions.SET_RESET_PASSWORD:
      return {
        ...state,
        user: undefined,
        session: undefined,
        isLoading: false,
        isAuthenticated: false,
        mode: AuthorizationModes.AUTHENTICATION_REQUIRED,
      }
    case Actions.SET_SET_PASSWORD:
      return {
        ...state,
        user: action.payload.user,
        session: undefined,
        isLoading: false,
        isAuthenticated: false,
        mode: AuthorizationModes.AUTHENTICATION_REQUIRED,
      }
    case Actions.ERROR:
      return {
        ...state,
        isLoading: false,
        user: action.payload.user,
        error: action.payload.error,
      }
    default:
      return state
  }
}

export const AuthorizationContextProvider: FC<PropsWithChildren> = ({ children }: PropsWithChildren) => {
  const [state, dispatch] = useReducer(authReducer, initialAuthorizationContextState)

  const refreshSession = useCallback(async () => {
    try {
      const { user, session } = await state.client.currentUser()
      dispatch({ type: Actions.LOGIN, payload: { user, session } })
    } catch (e) {
      dispatch({ type: Actions.LOGOUT })
    }
  }, [state])

  const getValidSession = async (): Promise<CognitoUserSession> => {
    if (state.session && state.session.isValid()) {
      return state.session
    }

    return refreshSession()
      .then(() => {
        return state.session
      })
      .catch(() => {
        return logout()
      })
  }

  const getIdToken = async () => {
    return getValidSession().then(maybeSession => maybeSession?.getIdToken().getJwtToken())
  }

  const getAccessToken = async () => {
    return getValidSession().then(maybeSession => maybeSession?.getAccessToken().getJwtToken())
  }

  const login = async (email: string, password: string): Promise<any> => {
    return state.client
      .authenticate(email, password)
      .then(({ user, session }) => {
        dispatch({ type: Actions.LOGIN, payload: { user, session } })
        return { user, session }
      })
      .catch(({ user, error }) => {
        if (error.type === NEW_PASSWORD_REQUIRED_ERROR) {
          dispatch({ type: Actions.SET_FORCE_RESET_PASSWORD, payload: { user, attributes: error.userAttributes } })
        } else {
          dispatch({ type: Actions.ERROR, payload: { error, user } })
        }
        throw error
      })
  }

  const loginWithToken = async (idToken: string, accessToken: string, refreshToken: string | null): Promise<any> => {
    return state.client
      .authenticateWithToken(idToken, accessToken, refreshToken)
      .then(({ user, session }) => {
        dispatch({ type: Actions.LOGIN, payload: { user, session } })
        return { user, session }
      })
      .catch(({ user, error }) => {
        dispatch({ type: Actions.ERROR, payload: { error, user } })
        throw error
      })
  }

  const logout = async (): Promise<any> => {
    return state.client.logout(state.user!).then(() => {
      dispatch({ type: Actions.LOGOUT })
    })
  }

  const forceResetPassword = async (password: string): Promise<any> => {
    return state.client
      .forceResetPassword(state.user!, state.session, password)
      .then(({ user, session }) => {
        dispatch({ type: Actions.LOGIN, payload: { user, session } })
        return { user, session }
      })
      .catch(({ user, error }) => {
        dispatch({ type: Actions.ERROR, payload: { error, user } })
        throw error
      })
  }

  const forgotPassword = async (email: string): Promise<any> => {
    return state.client.forgotPassword(email).catch(({ user, error }) => {
      dispatch({ type: Actions.ERROR, payload: { error, user } })
      throw error
    })
  }

  const resetPassword = async (code: string, password: string) => {
    return state.client.resetPassword(state.user!, code, password).catch(({ user, error }) => {
      dispatch({ type: Actions.ERROR, payload: { error, user } })
      throw error
    })
  }

  const getUserInfo = (): UserDetails | undefined => {
    if (state.session) {
      const { sub, email, 'cognito:username': cognitoUsername } = state.session.getIdToken().decodePayload()
      return { sub, email, cognitoUsername }
    }

    return undefined
  }

  const initFederatedLogin = (): void => {
    const url = `https://${COGNITO_DOMAIN}/login?response_type=${FEDERATED_LOGIN_RESPONSE_TYPE}&client_id=${COGNITO_CLIENT_ID}&scope=${FEDERATED_LOGIN_SCOPE}&redirect_uri=${COGNITO_REDIRECT_URL}`
    window.location.href = url
  }

  useEffect(() => {
    if (!state.session && state.mode === AuthorizationModes.INITIALIZING) {
      refreshSession()
    }
  }, [state, refreshSession])

  return (
    <AuthorizationContext.Provider
      value={{
        ...state,
        getIdToken,
        getAccessToken,
        login,
        loginWithToken,
        forceResetPassword,
        forgotPassword,
        resetPassword,
        logout,
        getUserInfo,
        initFederatedLogin,
      }}>
      {children}
    </AuthorizationContext.Provider>
  )
}

export const useAuthorizationContext: () => AuthorizationContextInterface = () => useContext(AuthorizationContext)
