import { SetStateAction, useCallback, useState } from 'react'

import classnames from 'classnames'
import * as R from 'ramda'

import { Filter } from '../../../Domain/BargeFilters'
import { CloseButton } from '../../../ui/CloseButton/CloseButton'

import { ExclusionFilters } from './ExclusionFilters'
import IncludeTBO from './IncludeTBO'
import styles from './LaneFilters.module.scss'
import { Column, ColumnEntry, ColumnTitle } from './Layout'
import { LocationFilter } from './LocationFilter'
import { MaxDraftFilter } from './MaxDraftFilter'
import { TimeWindowFilter } from './TimeWindowFilter'

const filterToSearchParams = (filter: Filter): URLSearchParams => {
  const params = R.pipe(
    R.toPairs,
    R.filter(([, value]) => value != null),
    R.map(([key, value]) => [key, String(value)])
  )(filter)

  return new URLSearchParams(params)
}

type Props = {
  filters: Filter
  setIsVisible: (b: boolean) => void
  readOnly: boolean
  isEnabled: boolean
  setFilters: (f: SetStateAction<Filter>) => void
}

export function LaneFilters({ filters, setIsVisible, readOnly, isEnabled, setFilters }: Props) {
  const close = useCallback(() => setIsVisible(false), [setIsVisible])

  const handleApply = useCallback(() => {
    const params = filterToSearchParams(filters)

    window.history.pushState({}, '', `${window.location.pathname}?${params}`)
    close()
  }, [filters, close])

  return (
    <div className={styles.panel}>
      <div className={styles.content}>
        <CloseButton handleClose={close} theme={{ button: styles.closeButton }} />
        <h2 className={styles.title}>Barge Pool Filters</h2>

        <div className={styles.columns}>
          <Filters filters={filters} setFilters={setFilters} readOnly={readOnly} isEnabled={isEnabled} />
        </div>

        <footer>
          <div className={styles.columns}>
            <button onClick={handleApply}>Apply</button>
          </div>
        </footer>
      </div>
    </div>
  )
}

type FiltersProps = {
  filters: Filter
  setFilters: (f: SetStateAction<Filter>) => void
  readOnly: boolean
  isEnabled: boolean
}

export function Filters({ filters, setFilters, readOnly, isEnabled }: FiltersProps) {
  const setMaxDraft = useCallback(
    (maxDraft?: number) => {
      setFilters(current => ({ ...current, maxDraft }))
    },
    [setFilters]
  )

  const setTimeWindow = useCallback(
    (time?: number) => {
      setFilters(current => ({ ...current, time }))
    },
    [setFilters]
  )

  const [tboNumbers, setTboNumbers] = useState<Set<number>>(new Set(filters.includeTBOs || []))

  const handleAddTbo = (order: number) => {
    if (!tboNumbers.has(order)) {
      setTboNumbers(prevTboNumbers => {
        prevTboNumbers.add(order)
        setFilters(current => ({
          ...current,
          includeTBOs: Array.from(prevTboNumbers),
        }))
        return new Set(prevTboNumbers)
      })
    }
  }

  const handleRemoveTbo = (number: number) => {
    setTboNumbers(prevTboNumbers => {
      prevTboNumbers.delete(number)
      setFilters(current => ({
        ...current,
        includeTBOs: Array.from(prevTboNumbers),
      }))
      return new Set(prevTboNumbers)
    })
  }

  if (!filters.origin && tboNumbers.size > 0) {
    setTboNumbers(new Set())
    setFilters(current => ({ ...current, includeTBOs: [] }))
  }

  return (
    <>
      <Column>
        <ColumnTitle title="Location" />
        <LocationFilter filters={filters} setFilters={setFilters} readOnly={readOnly} />
      </Column>
      <hr />
      <Column>
        <ColumnTitle title="Expected Departure Time" />
        <TimeWindowFilter
          isDisabled={filters.origin === undefined}
          time={filters.time}
          setTimeWindow={setTimeWindow}
          readOnly={readOnly}
        />
      </Column>
      <hr />
      <Column>
        <ColumnTitle title="Filter" />
        <ColumnEntry>
          <span className={classnames({ [styles.disabledLabel]: readOnly })}>Draft</span>
          <MaxDraftFilter maxDraft={filters.maxDraft} setMaxDraft={setMaxDraft} readOnly={readOnly} />
        </ColumnEntry>
        <ColumnEntry columnClassnames={[classnames({ [styles.disabledLabel]: readOnly })]}>
          Exclude
          <ExclusionFilters filters={filters} setFilters={setFilters} isEnabled={isEnabled} readOnly={readOnly} />
        </ColumnEntry>
        <ColumnEntry>
          <span className={classnames({ [styles.disabledLabel]: readOnly })}>Include TBO</span>
          <IncludeTBO
            tboNumbers={Array.from(tboNumbers)}
            onAddTbo={handleAddTbo}
            onRemoveTbo={handleRemoveTbo}
            readOnly={readOnly}
            isDisabled={!filters.origin}
          />
        </ColumnEntry>
      </Column>
    </>
  )
}
