import React from 'react'

import { Formik, Form, Field, ErrorMessage, FormikHelpers } from 'formik'
import * as Yup from 'yup'

import styles from './styles.module.scss'
import TboItem from './TboItem' 

type IncludeTBOProps = {
  tboNumbers: number[]
  onAddTbo: (number: number) => void
  onRemoveTbo: (number: number) => void
  readOnly: boolean
  validationSchema: Yup.ObjectSchema<any>
}

const initialValuesForm = {
  tboInput: '',
}

const IncludeTBO: React.FC<IncludeTBOProps> = ({ tboNumbers, onAddTbo, onRemoveTbo, readOnly, validationSchema }) => {
  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    setFieldTouched: (field: string, touched: boolean) => void
  ) => {
    if (e.key === 'Enter') {
      setFieldTouched('tboInput', true)
    }
  }

  const handleAddTbo = (
    values: { tboInput: string },
    { setFieldValue, setTouched }: FormikHelpers<{ tboInput: string }>
  ) => {
    const number = parseFloat(values.tboInput)
    if (!Number.isNaN(number)) {
      onAddTbo(number)
      setFieldValue('tboInput', '', false)
      setTouched({ tboInput: false })
    }
  }

  return (
    <Formik initialValues={initialValuesForm} validationSchema={validationSchema} onSubmit={handleAddTbo}>
      {({ setFieldTouched }) => (
        <Form className={styles.includeTboContainer}>
          <label className={styles.label}>Include TBO</label>
          <div className={styles.inputContainer}>
            <Field
              type="text"
              name="tboInput"
              placeholder="Enter TBO number"
              className={`${styles.tboInput} ${readOnly ? styles.disabledInput : ''}`}
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => handleKeyDown(e, setFieldTouched)}
            />
            <button type="submit" className={styles.addButton}>
              Add
            </button>
          </div>
          <ErrorMessage name="tboInput" component="div" className={styles.errorMessage} />
          <div className={styles.tboList}>
            {tboNumbers.length > 0 ? (
              tboNumbers.map((tbo, index) => <TboItem key={index} tbo={tbo} onRemove={onRemoveTbo} />)
            ) : (
              <p className={styles.noTbo}>No TBO numbers added yet.</p>
            )}
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default IncludeTBO
