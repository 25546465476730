import { DefinitionNode, Kind, OperationDefinitionNode } from 'graphql'
import { Client, fetchExchange, mapExchange, Exchange, Operation, OperationResult } from 'urql'
import { pipe, filter, merge, map, tap } from 'wonka'

import { GRAPHQL_API_URL } from '../constants/env-vars'
import { getRequestId } from '../utils/requestid'

type ResultCache = Map<number, OperationResult>

const isOperationDefinition = (d: DefinitionNode): d is OperationDefinitionNode => d.kind === Kind.OPERATION_DEFINITION

const getOperationName = (op: Operation) => op.query.definitions.find(isOperationDefinition)?.name?.value

const getOperationFromCache = (cache: ResultCache, name: string, filterFn: (op: Operation) => boolean = () => true) =>
  [...cache.values()].find(v => getOperationName(v.operation) === name && filterFn(v.operation))?.operation

function refetchQuery(op: Operation, client: Client, cache: ResultCache) {
  switch (getOperationName(op)) {
    case 'saveBargeNomination': {
      const cachedOp = getOperationFromCache(cache, 'savedBargeNominations')

      if (cachedOp) {
        client.reexecuteOperation({
          ...cachedOp,
          context: { ...cachedOp.context, requestPolicy: 'network-only' },
        })
      }
      break
    }

    case 'updateBargeNomination': {
      ;[
        getOperationFromCache(
          cache,
          'savedBargeNomination',
          cachedOp => cachedOp?.variables?.nominationId === op?.variables?.uuid
        ),
        getOperationFromCache(cache, 'savedBargeNominations'),
      ].forEach(cachedOp => {
        if (cachedOp) {
          client.reexecuteOperation({
            ...cachedOp,
            context: { ...cachedOp.context, requestPolicy: 'network-only' },
          })
        }
      })
      break
    }

    case 'deleteBargeNomination': {
      const cachedOp = getOperationFromCache(cache, 'savedBargeNominations')

      if (cachedOp) {
        client.reexecuteOperation({
          ...cachedOp,
          context: { ...cachedOp.context, requestPolicy: 'network-only' },
        })
      }
      break
    }

    default: {
      break
    }
  }
}

const isQueryOperationCached = (operation: Operation, cache: ResultCache) =>
  operation.kind === 'query' &&
  operation.context.requestPolicy !== 'network-only' &&
  (operation.context.requestPolicy === 'cache-only' || cache.has(operation.key))

// https://github.com/urql-graphql/urql/blob/main/packages/core/src/exchanges/cache.ts
const simpleCacheExchange: (r: (op: Operation, client: Client, cache: ResultCache) => void) => Exchange =
  refetch =>
  ({ forward, client }) => {
    const resultCache: ResultCache = new Map()

    return ops =>
      merge([
        pipe(
          ops,
          filter(op => isQueryOperationCached(op, resultCache)),
          map(op => resultCache.get(op.key)!)
        ),
        pipe(
          ops,
          filter(op => !isQueryOperationCached(op, resultCache)),
          forward,
          tap(res => {
            const { operation, data } = res

            if (operation.kind === 'mutation' && data) {
              refetch(operation, client, resultCache)
            }

            if (operation.kind === 'query' && data) {
              resultCache.set(operation.key, res)
            }
          })
        ),
      ])
  }

export const createUrqlClient = (getToken: () => Promise<string>) =>
  new Client({
    url: `${GRAPHQL_API_URL}/graphql`,
    exchanges: [
      simpleCacheExchange(refetchQuery),
      mapExchange({
        onOperation: op =>
          getToken().then(token => ({
            ...op,
            context: {
              ...op.context,
              fetchOptions: {
                headers: {
                  authorization: `Bearer ${token}`,
                  'x-request-id': getRequestId(),
                },
              },
            },
          })),
      }),
      fetchExchange,
    ],
  })
