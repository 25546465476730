import { BargeTotals, collectBargeTotals, OverviewBarge } from '../../Domain/Barge'
import { isNB } from '../../Domain/Hub'
import { showRiverLocation } from '../../Domain/River'
import { LaneId } from '../../generated/graphql'
import { toString } from '../../utils/date'

const DATE_FORMAT: Intl.DateTimeFormatOptions = {
  year: '2-digit',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
}

function getBoatInfo({ destination, eta }: NonNullable<OverviewBarge['custodyInfo']['boat']>) {
  if (destination === undefined && eta === undefined) {
    return undefined
  }
  return [
    destination ? `Dest: ${showRiverLocation(destination)} ${destination.name ?? ''} ` : null,
    eta ? toString(eta, DATE_FORMAT) : null,
  ]
    .filter(v => v !== null)
    .join(' | ')
}

enum LaneGroups {
  LMR = 'LowerMississippiRiver',
  GIWW = 'GulfIWWest',
  GIWE = 'GulfIWEast',
  ARK = 'Arkansas',
  ORB = 'OhioRiverBassin',
  MRB = 'MississippiRiverBassin',
  Other = 'other',
}

const currentLocationKey = ({ custodyInfo }: OverviewBarge) => custodyInfo.custody

const groupSortingRules: {
  group: string
  key: (b: OverviewBarge) => string
  predicate: (b: OverviewBarge) => boolean
}[] = [
  {
    group: LaneGroups.LMR,
    key: currentLocationKey,
    predicate: ({
      currentLocation: {
        riverLocation: { code },
      },
    }) => code === 'LMR',
  },
  {
    group: LaneGroups.GIWW,
    key: currentLocationKey,
    predicate: ({ currentLocation: { locationCode } }) =>
      locationCode !== null && ['45000', '43000', '41000'].includes(locationCode),
  },
  {
    group: LaneGroups.GIWE,
    key: currentLocationKey,
    predicate: ({ currentLocation: { locationCode } }) =>
      locationCode !== null && ['44000', '42000'].includes(locationCode),
  },
  {
    group: LaneGroups.ARK,
    key: currentLocationKey,
    predicate: ({ currentLocation: { locationCode } }) => locationCode !== null && locationCode === '32100',
  },
  {
    group: LaneGroups.ORB,
    key: currentLocationKey,
    predicate: ({ currentLocation: { locationCode } }) => locationCode !== null && locationCode.startsWith('1'),
  },
  {
    group: LaneGroups.MRB,
    key: currentLocationKey,
    predicate: ({ currentLocation: { locationCode } }) => locationCode !== null && locationCode.startsWith('2'),
  },
  {
    group: LaneGroups.Other,
    key: currentLocationKey,
    predicate: () => true,
  },
]

const groupBySortingRules = (barges: OverviewBarge[]) =>
  barges.reduce((acc: Record<string, Record<string, BargeTotals & { barges: OverviewBarge[]; mp: number }>>, b) => {
    groupSortingRules.every(({ group, predicate, key }) => {
      if (predicate(b)) {
        const k = key(b)

        acc[group] = acc[group] || {}
        acc[group][k] = acc[group][k] || {
          barges: [],
          rakes: 0,
          boxes: 0,
          tank: 0,
          LD: 0,
          MT: 0,
          mp: b.currentLocation.riverLocation.mileagePoint,
        }
        acc[group][k] = collectBargeTotals(acc[group][k], b)
        acc[group][k].barges.push(b)

        return false
      }
      return true
    })
    return acc
  }, {})

export type OverviewBargeGroup = BargeTotals & {
  key: string
  kind: 'group'
  type: 'boat' | 'fleet'
  boatInfo?: string
  bargeIds: string[]
}

export const groupOverviewBarges = (barges: OverviewBarge[], lane?: LaneId) =>
  doGroupOverviewBarges<OverviewBarge | OverviewBargeGroup>(
    barges,
    (key, { rakes, boxes, tank, LD, MT, barges: groupBarges }) => {
      return [
        {
          kind: 'group',
          type: groupBarges.length && groupBarges[0].custodyInfo.boat ? 'boat' : 'fleet',
          boatInfo:
            groupBarges.length && groupBarges[0].custodyInfo.boat
              ? getBoatInfo(groupBarges[0].custodyInfo.boat)
              : undefined,
          key,
          rakes,
          boxes,
          tank,
          LD,
          MT,
          bargeIds: groupBarges.map(({ barge: { id } }) => id),
        },
        ...groupBarges, 
      ]
    },
    lane
  )


export function doGroupOverviewBarges<R>(
  barges: OverviewBarge[],
  doGroup: (groupKey: string, group: BargeTotals & { barges: OverviewBarge[] }) => R[],
  lane?: LaneId
) {
  const groups = groupBySortingRules(barges)

  return groupSortingRules
    .filter(({ group }) => group in groups)
    .map(({ group: gr }) => {
      const sort =
        gr === LaneGroups.LMR
          ? (a: string, b: string) =>
              (groups[gr][a].mp > groups[gr][b].mp ? 1 : -1) * (lane === undefined || isNB(lane) ? 1 : -1)
          : undefined

      return Object.keys(groups[gr])
        .sort(sort)
        .map(k => doGroup(k, groups[gr][k]))
        .flat()
    })
    .flat()
}
