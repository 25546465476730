import { PropsWithChildren } from 'react'

import classnames from 'classnames'

import { CloseButton } from '../CloseButton/CloseButton'

import styles from './MessageBox.module.scss'

type MessageBoxProps = PropsWithChildren<{ title: string; className?: string; handleClose: () => void }>

export const MessageBox: React.FC<MessageBoxProps> = ({ title, children, handleClose, className }) => (
  <div className={classnames(styles.messageBox, className)}>
    <div className={styles.messageBoxContent}>
      <span className={styles.messageTitle}>{title}</span>
      <p className={styles.description}>{children}</p>
    </div>
    <CloseButton handleClose={handleClose} theme={{ button: styles.closeButton, icon: styles.closeIcon }} />
  </div>
)

export const SuccessMessage: React.FC<MessageBoxProps> = props => (
  <MessageBox {...props} className={classnames(styles.success, props.className)} />
)

export const WarningMessage: React.FC<MessageBoxProps> = props => (
  <MessageBox {...props} className={classnames(styles.warning, props.className)} />
)
