import * as R from 'ramda'

import type { FacilityLocation } from './Facility'
import type { RiverLocationLite } from '../generated/graphql'

// TODO: maybe move to lib/formatters
export const showRiverLocation = ({ code, mileagePoint }: Pick<RiverLocationLite, 'code' | 'mileagePoint'>): string =>
  `${code} ${mileagePoint.toFixed(1)}`

export const equals = (a: RiverLocationLite | FacilityLocation, b: RiverLocationLite | FacilityLocation) =>
  a.code === b.code && a.mileagePoint === b.mileagePoint

export enum Basin {
  OH = 'OH',
  LMR = 'LMR',
}
export const BASINS: Record<Basin, string[]> = {
  [Basin.OH]: ['OH', 'TEN', 'CUM', 'ALL', 'MON', 'BS', 'KAN', 'GR', 'HWR', 'BARC', 'CLI', 'EMO'],
  [Basin.LMR]: ['UMR', 'IR', 'LMI', 'SAN', 'CAL', 'MINN', 'STC', 'KAS', 'MO'],
}

const invertBasinMapping = ([basin, rivers]: [Basin, string[]]): Record<string, Basin> =>
  R.fromPairs(R.xprod(rivers, [basin]))

const basinPairs = Object.entries(BASINS) as [Basin, string[]][]
const invertedMappings = basinPairs.map(invertBasinMapping)

export const RIVERS_BASIN: Record<string, Basin> = R.mergeAll(invertedMappings)
