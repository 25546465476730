import { AddCircle, ArrowLeft, ArrowRight, RemoveCircle } from '@mui/icons-material'
import classnames from 'classnames'

import { ELLIPSIS } from '../../../constants/constants'
import { NominatedTowStop, showTransitTime } from '../../../Domain/Nomination'
import { equals } from '../../../Domain/River'
import { RiverLocationLite } from '../../../generated/graphql'

import styles from './Journey.module.scss'

type DotProps = RiverLocationLite & {
  isActive?: boolean
  dropOffs: number
  pickUps: number
  isLastStop: boolean
  travelMinutesToNextStop: number | null
  handleClick: () => void
}

const Dot = ({
  code,
  mileagePoint,
  isActive,
  isLastStop,
  dropOffs,
  pickUps,
  travelMinutesToNextStop,
  handleClick,
}: DotProps) => (
  <>
    <div className={classnames(styles.node)}>
      {dropOffs > 0 ? (
        <span className={styles.nodeMinus}>
          ({dropOffs}) <RemoveCircle />
        </span>
      ) : null}
      <span className={styles.nodeLabel}>
        {code} {mileagePoint}
      </span>
      {pickUps > 0 ? (
        <span className={styles.nodePlus}>
          <AddCircle /> ({pickUps})
        </span>
      ) : null}
      <div className={classnames(styles.button, { [styles.isActive]: isActive })} onClick={handleClick} />
    </div>
    {isLastStop ? null : (
      <span className={styles.transitTime}>
        <ArrowLeft />
        {travelMinutesToNextStop ? showTransitTime(travelMinutesToNextStop) : ELLIPSIS}
        <ArrowRight />
      </span>
    )}
  </>
)

type Props = {
  stops: NominatedTowStop[]
  handleSelectStop: (loc: RiverLocationLite | null) => void
  selectedStop?: RiverLocationLite
  boat?: string
}

export function Journey({ stops, selectedStop, handleSelectStop, boat }: Props) {
  const totalTransitTimeMinutes = stops.reduce((total, stop) => total + (stop.travelMinutesToNextStop ?? 0), 0)

  const lastStopIndex = stops.length - 1

  return (
    <div className={styles.journey}>
      <div className={styles.bar}>
        <div className={styles.stops} />
        {stops.map((stop, i) => {
          const isActive = selectedStop && equals(selectedStop, stop)

          return (
            <Dot
              handleClick={() =>
                handleSelectStop(isActive ? null : { code: stop.code, mileagePoint: stop.mileagePoint })
              }
              key={i}
              code={stop.code}
              mileagePoint={stop.mileagePoint}
              isActive={isActive}
              pickUps={stop.pickUps.length}
              dropOffs={stop.dropOffs.length}
              travelMinutesToNextStop={stop.travelMinutesToNextStop}
              isLastStop={i === lastStopIndex}
            />
          )
        })}
      </div>
      <div className={styles.infoJourney}>
        <div className={styles.towSpecs}>
          total transit time: <strong>{showTransitTime(totalTransitTimeMinutes)}</strong>
        </div>
        <div className={styles.towSpecs}>
          Vessel: <strong>{boat ?? ''}</strong>
        </div>
      </div>
    </div>
  )
}
