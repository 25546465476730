import React, { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react'

import { CheckCircle, Save, WarningAmber } from '@mui/icons-material'
import classnames from 'classnames'
import { match } from 'ts-pattern'
import { CombinedError } from 'urql'
import { Link, useLocation } from 'wouter'

import {
  LastUpdateIndicator,
  PrimaryMenu,
  PrimaryMenuRight,
  ToggleFilterScreenButton,
} from '../../../components/Header/PrimaryMenu/PrimaryMenu'
import { AsyncActionModal } from '../../../components/Modal/ActionModal'
import { UpdateUnavailableBargesModal } from '../../../components/Modal/UpdateUnavailableBarges'
import { NominateBarges } from '../../../components/Nomination/BargeNomination/NominateBarges'
import {
  LatestNominationPage,
  SavedNominationPage as SavedNominationPageComp,
} from '../../../components/Nomination/NominationPage'
import { ELLIPSIS } from '../../../constants/constants'
import { fromBargesQuery, isEmpty, OverviewBarge } from '../../../Domain/Barge'
import { getExcludedBargeTypes } from '../../../Domain/BargeType'
import {
  fromGqlUserBargeNomination,
  fromRequestFilters,
  NominatedBargeWithReview,
  UserBargeNomination,
} from '../../../Domain/Nomination'
import { excludePlacedToLoadStatuses } from '../../../Domain/Trip'
import {
  SavedBargeNominationQuery,
  UpdateBargeNominationMutation,
  useSavedBargeNominationQuery,
  useDeleteBargeNominationMutation,
  useUpdateBargeNominationMutation,
  useLaneBargesQuery,
  useReplaceNominationBargesMutation,
  TboSubmissionStatusId,
  useSubmitBargeNominationMutation,
} from '../../../generated/graphql'
import { useSavedNominationsContext } from '../../../providers/SavedNominationsProvider'
import { Layout } from '../../../ui/Layout/Layout'
import { LoadingSpinner } from '../../../ui/Spinner/Spinner'
import { errorToast, successToast } from '../../../ui/Toast/Toast'
import { exportNominationData } from '../../../utils/collectFeedbackData'
import { isoZonedDateTime, toString } from '../../../utils/date'
import { useAuthorizationContext, UserDetails } from '../../Account/AuthorizationContext'

import styles from './SavedNomination.module.scss'
import { useLatestNomination } from './useLatestNomination'

const INTERVAL_DURATION = 3000

const dateFormat: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
}

export function SavedNominationPage({ uuid, version }: { uuid: string; version: string }) {
  const { getUserInfo } = useAuthorizationContext()
  const { groupedNominations } = useSavedNominationsContext()

  const nominationsGroup = Object.values(groupedNominations).find(gr => gr.some(n => n.uuid === uuid))

  const [{ data, fetching }] = useSavedBargeNominationQuery({
    variables: { uuid },
  })
  const [, setLocation] = useLocation()
  const [previousMode, setPreviousMode] = useState<string>('latest')

  const modes = {
    saved: 'saved',
    latest: 'latest',
    edit: 'edit',
  }

  const handleNavigationTabClick = (
    e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>,
    destination: string,
    clickedUuid: string
  ) => {
    e.preventDefault()
    setPreviousMode(destination)
    setLocation(`/nominations/${clickedUuid}/${destination}`)
  }

  const handleEditButtonClick = () => {
    setLocation(modes.edit)
  }

  const handleClose = () => {
    setLocation(`/nominations/${uuid}/${previousMode}`)
  }

  if (fetching || data === undefined) {
    return <LoadingSpinner isFullScreen />
  }

  const savedBargeNomination = data.savedBargeNomination
    ? {
        ...data.savedBargeNomination,
        nomination: fromGqlUserBargeNomination(data.savedBargeNomination.nomination),
      }
    : null

  const userDetails = getUserInfo()

  return (
    <Layout
      primaryMenu={({ isDrawerOpen, setIsDrawerOpen }) => (
        <PrimaryMenu isDrawerOpen={isDrawerOpen} setDrawerOpen={setIsDrawerOpen}>
          <nav className={styles.nav}>
            <Link
              to={`/nominations/${uuid}/latest`}
              onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
                handleNavigationTabClick(e, modes.latest, uuid)
              }
              className={classnames(styles.tab, { [styles.isSelected]: version === 'latest' })}
              title="latest">
              Latest
            </Link>
            {nominationsGroup ? (
              nominationsGroup.map(n => (
                <Link
                  to={`/nominations/${n.uuid}/saved`}
                  key={n.uuid}
                  onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
                    handleNavigationTabClick(e, modes.saved, n.uuid)
                  }
                  className={classnames(styles.tab, {
                    [styles.isSelected]: version === 'saved' && uuid === n.uuid,
                  })}
                  title="saved">
                  <Save />
                  <div className={styles.savedBarge}>
                    <span className={styles.label}>{toString(n.recordTime, dateFormat)}</span>
                    <span className={styles.label}>
                      <span className={styles.label}>{n.userRequest?.towConfiguration?.goal}</span>
                    </span>
                  </div>
                </Link>
              ))
            ) : (
              <Link
                to={`/nominations/${uuid}/saved`}
                className={classnames(styles.tab, { [styles.isSelected]: version === 'saved' })}
                title="saved"
                onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
                  handleNavigationTabClick(e, modes.saved, uuid)
                }>
                <div className={classnames(styles.tab, { [styles.isSelected]: version === 'saved' })} title="saved">
                  <Save />
                  {savedBargeNomination ? toString(savedBargeNomination.nomination.recordTime, dateFormat) : ELLIPSIS}
                </div>
              </Link>
            )}
          </nav>
          <PrimaryMenuRight>
            <LastUpdateIndicator />
            <ToggleFilterScreenButton handleClick={handleEditButtonClick} />
          </PrimaryMenuRight>
        </PrimaryMenu>
      )}>
      {savedBargeNomination ? (
        match(version)
          .with('saved', () => (
            <WithAvailableBargePool {...savedBargeNomination.nomination.userRequest}>
              <SavedNomination
                userDetails={userDetails}
                nominationsGroup={nominationsGroup}
                savedNomination={savedBargeNomination}
              />
            </WithAvailableBargePool>
          ))
          .with('latest', () => (
            <WithAvailableBargePool {...savedBargeNomination.nomination.userRequest}>
              <LatestNomination
                {...savedBargeNomination.nomination.userRequest}
                uuid={savedBargeNomination.nomination.uuid}
                userDetails={userDetails}
              />
            </WithAvailableBargePool>
          ))
          .with('edit', () => (
            <NominateBarges
              nomination={savedBargeNomination.nomination}
              {...savedBargeNomination.nomination.userRequest}
              handleClose={handleClose}
              readOnly
            />
          ))
          .otherwise(() => null)
      ) : (
        <h1>Nomination not found</h1>
      )}
    </Layout>
  )
}

function WithAvailableBargePool({
  children,
  bargeFilters,
  towConfiguration,
}: PropsWithChildren<NonNullable<SavedBargeNominationQuery['savedBargeNomination']>['nomination']['userRequest']>) {
  const filters = fromRequestFilters(bargeFilters)

  const [{ fetching, data }] = useLaneBargesQuery({
    variables: {
      laneId: filters.lane ?? null,
      originId: filters.origin ?? null,
      destinationId: filters.destination ?? null,
      excludeBargeTypes: getExcludedBargeTypes(filters.excludeTanks, filters.excludeHoppers),
      excludeTripStatuses: filters.excludePlacedToLoad ? excludePlacedToLoadStatuses : null,
      excludeTboInfoBarges: filters.excludeTboInfo,
      excludeShuttleMoves: filters.excludeShuttleMoves,
      maxDraft: filters.maxDraft ?? null,
      time: filters.time ? isoZonedDateTime(filters.time) : null,
      includeTBOs: filters.includeTBOs,
      includeBargeIds: [],
    },
  })

  const barges = useMemo(() => (data === undefined ? [] : fromBargesQuery(data.lanes[0].barges)), [data])

  const requirements = useMemo(() => {
    if (fetching) {
      return []
    }
    const { numberOfBarges, numberOfEmptyBarges } = towConfiguration

    const totalRequiredLoaded = numberOfBarges - numberOfEmptyBarges

    const empties = barges.filter(isEmpty).length

    return [
      totalRequiredLoaded <= barges.length - empties,
      numberOfEmptyBarges <= empties,
      numberOfBarges <= barges.length,
    ]
  }, [fetching, barges, towConfiguration])

  if (fetching || data === undefined) {
    return <LoadingSpinner isFullScreen />
  }

  if (!requirements.every(r => r)) {
    return (
      <div className={styles.errorMessageBox}>
        <div className={styles.box}>
          <h3 className={styles.errorTitle}>There currently are not enough barges available!</h3>
          <p>Please try again at another time or create a new nomination</p>
          <ul className={styles.errorMessage}>
            <li className={classnames({ [styles.failed]: !requirements[0] })}>
              {requirements[0] ? (
                <>
                  <CheckCircle />
                  Enough loaded barges available
                </>
              ) : (
                <>
                  <WarningAmber />
                  Not enough loaded barges available
                </>
              )}
            </li>
            <li className={classnames({ [styles.failed]: !requirements[1] })}>
              {requirements[1] ? (
                <>
                  <CheckCircle />
                  Enough empty barges available
                </>
              ) : (
                <>
                  <WarningAmber />
                  Not enough empty barges available
                </>
              )}
            </li>
            <li className={classnames({ [styles.failed]: !requirements[2] })}>
              {requirements[2] ? (
                <>
                  <CheckCircle />
                  Enough barges available
                </>
              ) : (
                <>
                  <WarningAmber />
                  Not enough barges available
                </>
              )}
            </li>
          </ul>
        </div>
      </div>
    )
  }

  return React.cloneElement(children as React.ReactElement<any>, { bargesList: barges })
}

function LatestNomination(
  props: NonNullable<SavedBargeNominationQuery['savedBargeNomination']>['nomination']['userRequest'] & {
    uuid: string
    userDetails?: UserDetails
    bargesList?: OverviewBarge[]
  }
) {
  const [isUpdating, setIsUpdating] = useState(false)
  const [showUpdateStateModal, setShowUpdateStateModal] = useState(false)
  const { userDetails, uuid, bargesList } = props
  const { fetching, data, error } = useLatestNomination(props)

  const [, updateSavedNomination] = useUpdateBargeNominationMutation()
  const [, setLocation] = useLocation()

  const nomination = useMemo(() => {
    if (data === undefined || data.createBargeNomination.__typename === 'NominationCreateFailure') {
      return undefined
    }

    const n = data.createBargeNomination.nomination
    const userRequest = {
      ...n.userRequest,
      towConfiguration: n.userRequest.towConfiguration!,
    }
    const updatedNomination = {
      ...n,
      userRequest,
    }
    return fromGqlUserBargeNomination(updatedNomination)
  }, [data])

  const latestId = nomination?.uuid

  const handleUpdate = useCallback((res: { data?: UpdateBargeNominationMutation; error?: CombinedError }) => {
    setIsUpdating(false)
    if (res.error) {
      errorToast(res.error.graphQLErrors.map(e => e.originalError?.message).join('\n'))
    }
    if (res.data) {
      successToast('Latest saved nomination has been updated!')
    }
  }, [])

  const updateSavedNominationCallback = useCallback(
    () =>
      new Promise<void>(resolve => {
        if (latestId) {
          setIsUpdating(true)
          return updateSavedNomination({
            savedNominationId: uuid,
            autoSavedNominationId: latestId,
          }).then(res => {
            handleUpdate(res)
            resolve()
          })
        }

        return resolve()
      }),
    [uuid, latestId, handleUpdate, updateSavedNomination]
  )

  const exportFeedback = useCallback(
    (nominationData: UserBargeNomination) => {
      if (userDetails) {
        const barges = bargesList ?? []
        exportNominationData(userDetails, nominationData, barges)
      }
    },
    [userDetails, bargesList]
  )

  useEffect(() => {
    if (error) {
      errorToast(error.graphQLErrors.map(e => e.message).join('\n'))
      setLocation(`/nominations/${uuid}/saved`)
    }
  }, [error, uuid, setLocation])

  useEffect(() => {
    if (data && data.createBargeNomination.__typename === 'NominationCreateFailure') {
      const { message, errors } = data.createBargeNomination

      errorToast(`${message}\n\n${errors.join('\n')}`)
      setLocation(`/nominations/${uuid}/saved`)
    }
  }, [data, uuid, setLocation])

  if (fetching || nomination === undefined) {
    return <LoadingSpinner isFullScreen />
  }

  return (
    <>
      <LatestNominationPage
        isUpdating={isUpdating}
        handleUpdateNomination={() => setShowUpdateStateModal(true)}
        handleFeedbackExport={() => exportFeedback(nomination)}
        nomination={nomination}
      />
      <AsyncActionModal
        title="Update saved nomination"
        isOpen={showUpdateStateModal}
        onAction={updateSavedNominationCallback}
        onClose={() => setShowUpdateStateModal(false)}
        body={<p>Are you sure you want to replace this saved nomination, with the new nomination?</p>}
      />
    </>
  )
}

function useUnavailableBargesUpdate(
  isEnabled: boolean,
  { nomination }: NonNullable<SavedBargeNominationQuery['savedBargeNomination']>
) {
  const [, updateSavedNomination] = useUpdateBargeNominationMutation()

  const [{ data, error, fetching }, replaceBarges] = useReplaceNominationBargesMutation()

  const { uuid } = nomination
  const latestId =
    data?.replaceNominationBarges.__typename === 'NominationReplaceBargesSuccess'
      ? data.replaceNominationBarges.nomination.uuid
      : undefined

  const updateSavedNominationCallback = useCallback(
    () =>
      new Promise<void>(resolve => {
        if (latestId) {
          return updateSavedNomination({
            savedNominationId: uuid,
            autoSavedNominationId: latestId,
          }).then(() => {
            resolve()
          })
        }

        return resolve()
      }),
    [uuid, latestId, updateSavedNomination]
  )

  useEffect(() => {
    if (isEnabled) {
      replaceBarges({ nominationId: uuid })
    }
  }, [isEnabled, replaceBarges, uuid])

  return { fetching, data, error, updateSavedNominationCallback }
}

function SavedNomination({
  userDetails,
  nominationsGroup,
  savedNomination,
  bargesList,
}: {
  userDetails?: UserDetails
  nominationsGroup?: UserBargeNomination[]
  savedNomination: NonNullable<SavedBargeNominationQuery['savedBargeNomination']>
  bargesList?: OverviewBarge[]
}) {
  const effectiveBargesList = useMemo(() => bargesList ?? [], [bargesList])
  const [, setLocation] = useLocation()
  const [isDeleting, setIsDeleting] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showSubmitModal, setShowSubmitModal] = useState(false)
  const [showUpdateBargesModal, setShowUpdateBargesModal] = useState(false)

  const bargesUpdate = useUnavailableBargesUpdate(showUpdateBargesModal, savedNomination)
  const { updateSavedNominationCallback } = bargesUpdate

  const remainingBargeIds = useMemo(() => {
    const {
      nomination,
      review: { leftTheBargePool },
    } = savedNomination
    return nomination.tows
      .map(({ barges }) => barges.filter(({ id }) => !leftTheBargePool.includes(id)).map(({ id }) => id))
      .flat()
  }, [savedNomination])

  const nominationWithReviews = useMemo(() => {
    const { nomination, review } = savedNomination

    return {
      ...nomination,
      tows: nomination.tows.map(t => ({
        ...t,
        barges: t.barges.map(
          (b): NominatedBargeWithReview => ({
            ...b,
            review: {
              receivedTBO: review.receivedTBO.find(_ => _.bargeId === b.id)?.tbo,
              leftTheBargePool: review.leftTheBargePool.includes(b.id),
              riskLevel: review.changedRiskLevel.find(_ => _.bargeId === b.id)?.newRiskLevel,
            },
          })
        ),
      })),
    }
  }, [savedNomination])

  const handleUpdateCallback = useCallback(async () => {
    setIsUpdating(true)

    try {
      await updateSavedNominationCallback()
      setShowUpdateBargesModal(false)
    } catch (e) {
      if (e instanceof CombinedError) {
        errorToast(e.message)
      }
    }

    setIsUpdating(false)
  }, [updateSavedNominationCallback])

  const exportFeedback = useCallback(
    (n: UserBargeNomination) => {
      if (userDetails) {
        exportNominationData(userDetails, n, effectiveBargesList)
      }
    },
    [userDetails, effectiveBargesList]
  )

  const [, deleteNomination] = useDeleteBargeNominationMutation()

  const { uuid } = savedNomination.nomination

  const onDeleteActionCallback = useCallback(
    () =>
      new Promise<void>(resolve => {
        setIsDeleting(true)
        deleteNomination({ uuid })
          .then(() => {
            setIsDeleting(false)
            successToast('Saved nomination has been deleted!')
            if (nominationsGroup && nominationsGroup.length > 1) {
              const { uuid: nextId } = nominationsGroup.filter(n => n.uuid !== uuid)[0]

              setLocation(`/nominations/${nextId}/latest`)
            } else {
              setLocation('/nominations/create')
            }
            resolve()
          })
          .catch((e: Error) => {
            setIsDeleting(false)
            errorToast(e.message)
          })
      }),
    [setLocation, uuid, deleteNomination, nominationsGroup]
  )

  const [, submitNomination] = useSubmitBargeNominationMutation()
  const [{ data }, reexecuteQuery] = useSavedBargeNominationQuery({
    variables: { uuid },
    requestPolicy: 'network-only',
  })
  const [tboSubmissionStatusId, setTboSubmissionStatusId] = useState(savedNomination.tboSubmissionStatus)
  const onSubmitActionCallback = useCallback(async () => {
    setIsSubmitting(true)

    try {
      const result = await submitNomination({ uuid })

      if (result.error) {
        throw new Error(result.error.message)
      }

      setIsSubmitting(false)

      const updatedStatus =
        result.data?.submitBargeNomination?.tboSubmissionStatus || TboSubmissionStatusId.NotSubmitted
      setTboSubmissionStatusId(updatedStatus)
    } catch (error) {
      setIsSubmitting(false)
      const errorMessage = (error as Error).message
      errorToast(errorMessage ?? 'An unknown error occurred.')
    }
  }, [uuid, submitNomination])

  const updateTboStatus = useCallback(() => {
    const updatedStatus = data?.savedBargeNomination?.tboSubmissionStatus || TboSubmissionStatusId.NotSubmitted
    setTboSubmissionStatusId(updatedStatus)
  }, [data])

  useEffect(() => {
    updateTboStatus()
  }, [updateTboStatus])

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null

    if (tboSubmissionStatusId === TboSubmissionStatusId.Pending) {
      interval = setInterval(() => {
        reexecuteQuery()
      }, INTERVAL_DURATION)
    }

    return () => {
      if (interval !== null) {
        clearInterval(interval)
      }
    }
  }, [tboSubmissionStatusId, reexecuteQuery])

  return (
    <>
      <SavedNominationPageComp
        isDeleting={isDeleting}
        isSubmitting={isSubmitting}
        handleDeleteNomination={() => setShowDeleteModal(true)}
        handleSubmitNomination={() => setShowSubmitModal(true)}
        handleFeedbackExport={() => exportFeedback(nominationWithReviews)}
        handleUpdatingNominationBarges={() => setShowUpdateBargesModal(true)}
        nomination={nominationWithReviews}
        tboSubmissionStatus={tboSubmissionStatusId}
      />
      <AsyncActionModal
        title="Delete confirmation"
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onAction={onDeleteActionCallback}
        body="Are you sure you want to delete this nomination?"
      />
      <AsyncActionModal
        title="Submission confirmation"
        isOpen={showSubmitModal}
        onClose={() => setShowSubmitModal(false)}
        onAction={onSubmitActionCallback}
        body="Are you sure you want to submit this nomination to TBO?"
      />
      <UpdateUnavailableBargesModal
        handleExport={exportFeedback}
        handleUpdate={handleUpdateCallback}
        isUpdating={isUpdating}
        isOpen={showUpdateBargesModal}
        onClose={() => setShowUpdateBargesModal(false)}
        bargesUpdate={bargesUpdate}
        remainingBargeIds={remainingBargeIds}
      />
    </>
  )
}
