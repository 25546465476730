import { useCallback, useEffect, useMemo } from 'react'

import { Dialog } from '@headlessui/react'
import { Close, Download } from '@mui/icons-material'
import classnames from 'classnames'
import { CombinedError } from 'urql'

import { UserBargeNomination } from '../../Domain/Nomination'
import { ReplaceNominationBargesMutation } from '../../generated/graphql'
import { LoadingSpinner } from '../../ui/Spinner/Spinner'
import { errorToast } from '../../ui/Toast/Toast'
import { UpdatedNominationBargesTable } from '../Table/NominatedBargesTable'

import { Modal } from './Modal'
import styles from './Modal.module.scss'

type Props = {
  onClose: () => void
  isOpen: boolean
  isUpdating: boolean
  remainingBargeIds: string[]
  handleUpdate: () => void
  handleExport: (nomination: UserBargeNomination) => void
  bargesUpdate: {
    data?: ReplaceNominationBargesMutation
    error?: CombinedError
    fetching: boolean
  }
}

export function UpdateUnavailableBargesModal({
  isOpen,
  onClose,
  isUpdating,
  handleUpdate,
  handleExport,
  remainingBargeIds,
  bargesUpdate,
}: Props) {
  const { data } = bargesUpdate
  const hasNomination = useMemo(
    () => data?.replaceNominationBarges.__typename === 'NominationReplaceBargesSuccess',
    [data]
  )

  const handleExportCallback = useCallback(() => {
    if (data?.replaceNominationBarges.__typename === 'NominationReplaceBargesSuccess') {
      const { nomination } = data.replaceNominationBarges

      const towConfiguration = nomination.userRequest.towConfiguration!

      const updatedUserRequest = {
        ...nomination.userRequest,
        towConfiguration,
      }

      const updatedNomination = {
        ...nomination,
        userRequest: updatedUserRequest,
      }
      handleExport(updatedNomination)
    }

    return undefined
  }, [handleExport, data])

  return (
    <Modal className={styles.updateBargesModal} isOpen={isOpen} onClose={onClose}>
      <button onClick={onClose} className={styles.closeButton}>
        <Close />
      </button>
      <div className={styles.content}>
        <Dialog.Title className={styles.title}>
          <span>Replace unavailable barges</span>
          <button disabled={!hasNomination} onClick={handleExportCallback} className={styles.export}>
            Export feedback data <Download />
          </button>
        </Dialog.Title>
        <Dialog.Description as="div" className={styles.text}>
          <UpdateUnavailableBarges remainingBargeIds={remainingBargeIds} bargesUpdate={bargesUpdate} />
        </Dialog.Description>
      </div>
      <div className={styles.footer}>
        <button onClick={onClose} className={styles.button}>
          Cancel
        </button>
        <button
          onClick={handleUpdate}
          disabled={!hasNomination}
          className={classnames(styles.button, styles.primary, { [styles.isLoading]: hasNomination && isUpdating })}>
          Replace
        </button>
      </div>
    </Modal>
  )
}

function UpdateUnavailableBarges({
  remainingBargeIds,
  bargesUpdate: { data, error, fetching },
}: {
  remainingBargeIds: string[]
  bargesUpdate: {
    data?: ReplaceNominationBargesMutation
    error?: CombinedError
    fetching: boolean
  }
}) {
  useEffect(() => {
    if (error) {
      errorToast(error.message)
    }
  }, [error])

  if (fetching || data === undefined) {
    return (
      <div className={styles.result}>
        <LoadingSpinner isFullScreen />
      </div>
    )
  }

  return (
    <div
      className={classnames(styles.result, {
        [styles.hasResult]: data.replaceNominationBarges.__typename === 'NominationReplaceBargesSuccess',
        [styles.hasFailure]: data.replaceNominationBarges.__typename === 'NominationReplaceBargesFailure',
      })}>
      {data.replaceNominationBarges.__typename === 'NominationReplaceBargesSuccess' ? (
        <>
          {data.replaceNominationBarges.nomination.tows.map((tow, index) => (
            <UpdatedNominationBargesTable key={index} barges={tow.barges} remainingBargeIds={remainingBargeIds} />
          ))}
        </>
      ) : (
        <div className={styles.failure}>
          <h4 className={styles.message}>{data.replaceNominationBarges.message}</h4>
          <p>{data.replaceNominationBarges.errors.join('\n')}</p>
        </div>
      )}
    </div>
  )
}
