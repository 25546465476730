import React, { FC } from 'react'

import FormOrSummary from '../FormOrSummary'
import { StageData } from '../types'

import TowParametersSelectionForm from './TowParametersSelectionForm'

const TowParametersSelectionSummary: FC<{ description: string[] }> = ({ description }) => {
  return (
    <div>
      {description.map((desc, index) => (
        <div key={index}>{desc}</div>
      ))}
    </div>
  )
}

const TowParametersSelectionBlock: FC<StageData<any, any, any>> = ({
  isSelected,
  isValid,
  actions,
  summary,
  form,
  initParameters,
}) => {
  const { data, onChange } = form

  const { description } = summary

  const { barges, goals } = initParameters

  return (
    <FormOrSummary
      title="Tow Parameters"
      isSelected={isSelected}
      isValid={isValid}
      actions={actions}
      form={
        <TowParametersSelectionForm
          data={data}
          onChange={onChange}
          barges={barges}
          goals={goals}
          prioritizeHotBarges={data.hotBarges}
        />
      }
      summary={<TowParametersSelectionSummary description={description} />}
    />
  )
}

export default TowParametersSelectionBlock
