import { PropsWithChildren } from 'react'

import { Close } from '@mui/icons-material'
import classnames from 'classnames'

import styles from './CloseButton.module.scss'

type CloseButtonProps = PropsWithChildren<{ handleClose: () => void; theme: { button?: string; icon?: string } }>
export const CloseButton: React.FC<CloseButtonProps> = ({ children, handleClose, theme: { button, icon } }) => (
  <button type="button" onClick={handleClose} className={classnames(styles.button, button)}>
    {children}
    <Close className={icon} />
  </button>
)
