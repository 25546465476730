import { Link } from 'wouter'

import { docsPagePath } from '../../paths'

import { AdNote, AdWarning } from './Admonition/Admonintion'
import { P } from './Components/Components'

export const LanesDoc = () => (
  <>
    <h1>Lanes</h1>
    <h2 data-section="overview">Overview</h2>
    <P>
      A barge can qualify for many <b>lanes</b> over the course of a barge trip. The lanes capture major traffic
      patterns that Logistics users use to filter a barge pool when starting to plan a new barge nomination.
    </P>
    <h3 data-section="qualification">Qualification for a lane</h3>
    <P>
      The required information for each barge that is needed for determining lanes are: a final destination for a barge
      trip and a current location for the barge. From this, the qualifying lanes for each barge are calculated from the{' '}
      <i>remaining</i> portion of a barge trip, i.e. the route from the barge's <i>current</i> location to its final
      destination. This remaining portion can qualify under multiple lanes, and lanes can have overlapping
      specifications.
    </P>
    <P>
      One more criterion for lane qualification is that the remaining route must involve crossing certain milestone
      points over the lane's coverage. The reasoning for this is to require there to be some reasonably significant
      travel still remaining in a barge trip.
    </P>
    <P>
      A <Link href={`${docsPagePath('lanes')}/s/lane-specification`}>full specification</Link> for each Lane visible in
      the web-app, including the milestones of the lane, can be found in the Advanced Points section below.
    </P>
    <AdNote title="Future development">
      Lanes for the Tennessee (TEN), Cumberland (CUM), Illinois (IR), and Gulf Canals (GIWW and GIWE) will be specified
      and included as part of the web-app in future development cycles.
    </AdNote>
    <h2 data-section="web-app">Web-app interface</h2>
    <P>
      The lanes of a trip are one of the most important pieces of information in the BNE: they are the first filter that
      a user must choose when using the web-app, and choosing a Lane is required to open up other dropdown filters such
      as Origin and Destination.
    </P>
    <P>
      If the user chooses <i>any</i> of the lanes in the Lane dropdown which the barge qualifies for, then the barge
      should appear in the pool, assuming that there are no further options or filter toggles yet chosen.
    </P>
    <AdWarning title="Lane pools">
      Choosing a lane and leaving the Origin and Destination blank will show you the complete pool of barges for that
      lane. This is similar to the kind of view users might be familiar with e.g. the "Northbound from the Gulf" and
      other similar reports.
    </AdWarning>
    <h2 data-section="advanced">Advanced points</h2>
    <h3 data-section="lane-specification">Lane specification</h3>
    <P>
      For each lane specification, there is a:
      <ul>
        <li>
          <i>Start</i> point: the furthest bound that starts the lane's coverage
        </li>
        <li>
          <i>End</i> point: the furthest bound that ends the lane's coverage
        </li>
        <li>
          <i>Direction</i>: either upstream or downstream
        </li>
        <li>
          <i>Milestones</i>: a sequential list of important points that help determine the significance of the remaining
          trip
        </li>
        <li>
          <i>Qualification</i>: the direction and manner that the barge needs to cross the milestones (either any of the
          milestones, or all of the milestones)
        </li>
      </ul>
    </P>
    <h4>SB Unlocked</h4>
    <P>
      <ul>
        <li>
          <i>Start</i>: St. Louis (LMR 185.5, end of Chain of Rocks Lock No. 27)
        </li>
        <li>
          <i>End</i>: The Hole (LMR 0)
        </li>
        <li>
          <i>Direction</i>: Downstream
        </li>
        <li>
          <i>Milestones</i>: LMR 953, LMR 905, LMR 600, LMR 305, LMR 169, LMR 115, LMR 82
        </li>
        <li>
          <i>Qualification</i>: Pass downstream through <i>any</i> of the milestone points
        </li>
      </ul>
    </P>
    <h4>SB Express to Gulf</h4>
    <P>
      <ul>
        <li>
          <i>Start</i>: GRMS–Columbus (LMR 953)
        </li>
        <li>
          <i>End</i>: Greater Baton Rouge (LMR 168.5)
        </li>
        <li>
          <i>Direction</i>: Downstream
        </li>
        <li>
          <i>Milestones</i>: LMR 905, LMR 600, LMR 305
        </li>
        <li>
          <i>Qualification</i>: Pass downstream through <i>all</i> of the milestone points
        </li>
      </ul>
    </P>
    <h4>NB Unlocked</h4>
    <P>
      <ul>
        <li>
          <i>Start</i>: The Hole (LMR 0)
        </li>
        <li>
          <i>End</i>: St. Louis (LMR 185.5, end of Chain of Rocks Lock No. 27)
        </li>
        <li>
          <i>Direction</i>: Upstream
        </li>
        <li>
          <i>Milestones</i>: LMR 82, LMR 115, LMR 169, LMR 305, LMR 600, LMR 905, LMR 953
        </li>
        <li>
          <i>Qualification</i>: Pass upstream through <i>any</i> of the milestone points
        </li>
      </ul>
    </P>
    <h4>NB Express from Gulf</h4>
    <P>
      <ul>
        <li>
          <i>Start</i>: Greater Baton Rouge (LMR 168.5)
        </li>
        <li>
          <i>End</i>: GRMS–Columbus (LMR 953)
        </li>
        <li>
          <i>Direction</i>: Upstream
        </li>
        <li>
          <i>Milestones</i>: LMR 305, LMR 600, LMR 905
        </li>
        <li>
          <i>Qualification</i>: Pass upstream through <i>all</i> of the milestone points
        </li>
      </ul>
    </P>
    <h4>EB Ohio</h4>
    <P>
      <ul>
        <li>
          <i>Start</i>: GRMS–Columbus (LMR 905)
        </li>
        <li>
          <i>End</i>: Pittsburgh (OH 0)
        </li>
        <li>
          <i>Direction</i>: Upstream
        </li>
        <li>
          <i>Milestones</i>: OH 980, OH 918, OH 720, OH 436
        </li>
        <li>
          <i>Qualification</i>: Pass upstream through <i>any</i> of the milestone points
        </li>
      </ul>
    </P>
    <h4>WB Ohio</h4>
    <P>
      <ul>
        <li>
          <i>Start</i>: Pittsburgh (OH 0)
        </li>
        <li>
          <i>End</i>: GRMS–Columbus (LMR 905)
        </li>
        <li>
          <i>Direction</i>: Downstream
        </li>
        <li>
          <i>Milestones</i>: OH 436, OH 720, OH 918, OH 980
        </li>
        <li>
          <i>Qualification</i>: Pass downstream through <i>any</i> of the milestone points
        </li>
      </ul>
    </P>
    <h4>NB UMR</h4>
    <P>
      <ul>
        <li>
          <i>Start</i>: GRMS–Columbus (LMR 905)
        </li>
        <li>
          <i>End</i>: Minneapolis–St. Paul (UMR 877)
        </li>
        <li>
          <i>Direction</i>: Upstream
        </li>
        <li>
          <i>Milestones</i>: UMR 0, UMR 186, UMR 365, UMR 680, UMR 797
        </li>
        <li>
          <i>Qualification</i>: Pass upstream through <i>any</i> of the milestone points
        </li>
      </ul>
    </P>
    <h4>SB UMR</h4>
    <P>
      <ul>
        <li>
          <i>Start</i>: Minneapolis–St. Paul (UMR 877)
        </li>
        <li>
          <i>End</i>: GRMS–Columbus (LMR 905)
        </li>
        <li>
          <i>Direction</i>: Downstream
        </li>
        <li>
          <i>Milestones</i>: UMR 797, UMR 680, UMR 365, UMR 186, UMR 0
        </li>
        <li>
          <i>Qualification</i>: Pass downstream through <i>any</i> of the milestone points
        </li>
      </ul>
    </P>
    <h3>Example</h3>
    <P>
      Barge XYZ is loaded at “LMR 172 – Rene Industries” with a final destination of "UMR 474.7 – Continental Cement,
      Linwood, IA". Over the different phases of the barge trip from origin to destination, the set of Lanes the barge
      qualifies for will change. Below is an explanation of which lanes qualify at three different junctures in the
      trip.
    </P>
    <h4>At origin</h4>
    <P>
      When the barge is still at origin, the entire trip is still remaining, and at this point the barge qualifies for
      three lanes:
      <ul>
        <li>NB Unlocked</li>
        <li>NB Express from Gulf</li>
        <li>NB UMR</li>
      </ul>
      If a user chooses any of these lanes, then, assuming no further filters have been chosen, the barge should appear
      in the barge pool.
    </P>
    <h4>At GRMS-Columbus</h4>
    <P>
      Now, assume that the barge is the middle its trip and is currently located in a fleet at GRMS–Columbus (LMR 937).
      At this point, the barge now qualifies for two lanes:
      <ul>
        <li>NB Unlocked</li>
        <li>NB UMR</li>
      </ul>
      It <i>no longer</i> qualifies for NB from Gulf (Direct) because it is now past Baton Rouge in its journey, which
      is (roughly) the cutoff point for no longer qualifying on the Direct lane.
    </P>
    <P>
      It <i>still</i> qualifies for NB Unlocked because there is still a significant stretch of unlocked river to go in
      the journey (from Columbus to St. Louis).
    </P>
    <h4>At St. Louis</h4>
    <P>
      Finally, let's look at the barge as it continues its journey and is sitting in-fleet at St. Louis (UMR 172.5). At
      this point, the barge now only qualifies for one lane:
      <ul>
        <li>NB UMR</li>
      </ul>
      So the barge will only appear in the pool for NB UMR as a candidate to be transported from St. Louis to its final
      destination at "UMR 474.7 – Continental Cement, Linwood, IA".
    </P>
  </>
)