import { OverviewBarge } from '../../Domain/Barge'
import { showRiverLocation } from '../../Domain/River'
import { define } from '../../lib/Column'
import { map } from '../../lib/Nullable'

const col = define<OverviewBarge>()

export const columns = {
  name: col.string('Name', _ => _.barge.name),
  cargo: col.string('Cargo', _ => _.currentTrip.cargo),
  destination: col.string('Destination', _ => map(_.currentTrip.destination, showRiverLocation)),
  hullType: col.string('Hull Type', _ => _.barge.hullType),
  tboInfo: col.string('Latest TBO', _ => _.latestTBO?.info),
  type: col.string('Type', _ => _.barge.type),
}

export type ColumnKey = keyof typeof columns
export const columnKeys: ColumnKey[] = ['name', 'cargo', 'destination', 'hullType', 'tboInfo']
