import { PropsWithChildren } from 'react'

import { Combobox, Transition } from '@headlessui/react'
import classnames from 'classnames'

import styles from './Combobox.module.scss'

export const ComboboxWrapper = ({ children, className }: PropsWithChildren<{ className?: string }>) => (
  <div className={classnames(styles.menu, className)}>{children}</div>
)

export const ComboboxButton = () => <Combobox.Button className={styles.button} />

export const ComboboxOptions = ({
  children,
  className,
  afterLeave,
  readOnly,
}: PropsWithChildren<{ className?: string; afterLeave: () => void; readOnly?: boolean }>) => {
  const optionsClassName = classnames(styles.options, className, { [styles.disabled]: readOnly })
  return (
    <Transition className={styles.optionsWrapper} afterLeave={afterLeave}>
      <Combobox.Options className={optionsClassName}>{children}</Combobox.Options>
    </Transition>
  )
}

export const ComboboxInput = ({
  className,
  children,
  readOnly,
}: PropsWithChildren<{ className?: string; readOnly?: boolean }>) => {
  const inputClassName = classnames(styles.input, className, { [styles.disabled]: readOnly })

  return <div className={inputClassName}>{children}</div>
}

export const ComboboxOption = ({
  className,
  value,
  children,
  readOnly,
}: PropsWithChildren<{ className?: string; value: string; readOnly?: boolean }>) => {
  const optionClassName = classnames(styles.option, className, { [styles.disabled]: readOnly })

  return (
    <Combobox.Option value={value} className={optionClassName}>
      {children}
    </Combobox.Option>
  )
}
