import { OwnedBy, useNominationsQuery } from '../generated/graphql'

import { convertNominationSummary } from './converters'

import type { LatestNominationSummary } from './models'

export type NominationsModel = {
  fetching: boolean
  nominations: LatestNominationSummary[]
  refreshNominations: (ctx: any) => void
}

const useNominationsModel = (ownershipFilter: OwnedBy): NominationsModel => {
  const [{ fetching, data }, reexecuteQuery] = useNominationsQuery({
    variables: { ownedBy: ownershipFilter },
  })

  const nominations: LatestNominationSummary[] = data?.nominations.map(convertNominationSummary) || []

  return {
    fetching,
    nominations,
    refreshNominations: ctx => reexecuteQuery(ctx),
  }
}

export default useNominationsModel
