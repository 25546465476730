import { useCallback } from 'react'

import {
  type GraphqlNewNominationRequest,
  type GraphqlVersionRequest,
  useArchiveNominationMutation,
  useArchiveNominationVersionMutation,
  useCreateNominationVersionMutation,
  useCreateVersionedNominationMutation,
  useSubmitNominationVersionMutation,
} from '../generated/graphql'

type NominationActionsModel = {
  createNomination: (payload: GraphqlNewNominationRequest) => Promise<CreatedIdentifiers>
  createVersion: (payload: GraphqlVersionRequest) => Promise<CreatedIdentifiers>
  archiveNomination: (nominationId: string) => Promise<boolean>
  archiveNominationVersion: (nominationId: string, versionId: string) => Promise<boolean>
  submitNominationVersion: (nominationId: string, versionId: string) => Promise<boolean>
}

export type CreatedIdentifiers = {
  nominationId: string
  versionId: string
}

const CreationFailure = 'CreationFailure'
const CreationSuccess = 'CreationSuccess'

const ArchivingSuccess = 'ArchivingSuccess'

const SubmitSuccess = 'SubmitSuccess'

const useNominationActionsModel = (): NominationActionsModel => {
  const [, createNewNominationMutation] = useCreateVersionedNominationMutation()
  const [, createVersionMutation] = useCreateNominationVersionMutation()

  const [, archiveNominationMutation] = useArchiveNominationMutation()
  const [, archiveNominationVersionMutation] = useArchiveNominationVersionMutation()

  const [, submitNominationVersionMutation] = useSubmitNominationVersionMutation()

  const createNomination = useCallback(
    async (payload: GraphqlNewNominationRequest) => {
      const result = await createNewNominationMutation({ request: payload })
      const response = result.data?.createVersionedNomination
      if (response && response.__typename === CreationFailure) {
        throw new Error(response.message)
      }
      if (response && response.__typename === CreationSuccess) {
        return { nominationId: response.nominationId, versionId: response.nominationVersionId }
      }

      throw new Error('Could not receive nomination response')
    },
    [createNewNominationMutation]
  )

  const createVersion = useCallback(
    async (payload: GraphqlVersionRequest) => {
      const result = await createVersionMutation({ request: payload })
      const response = result.data?.createVersion
      if (response && response.__typename === CreationFailure) throw new Error(response.message)
      if (response && response.__typename === CreationSuccess)
        return { nominationId: response.nominationId, versionId: response.nominationVersionId }

      throw new Error('Could not receive nomination response')
    },
    [createVersionMutation]
  )

  const archiveNomination = useCallback(
    async (nominationId: string) => {
      const result = await archiveNominationMutation({ nominationId })
      const response = result.data?.archiveNomination
      if (response && response.__typename === ArchivingSuccess) return true

      return false
    },
    [archiveNominationMutation]
  )

  const archiveNominationVersion = useCallback(
    async (nominationId: string, versionId: string) => {
      const result = await archiveNominationVersionMutation({ nominationId, versionId })
      const response = result.data?.archiveNominationVersion
      if (response && response.__typename === ArchivingSuccess) return true

      return false
    },
    [archiveNominationVersionMutation]
  )

  const submitNominationVersion = useCallback(
    async (nominationId: string, versionId: string) => {
      const result = await submitNominationVersionMutation({ nominationId, versionId })
      const response = result.data?.submitVersionToTbo
      if (response && response.__typename === SubmitSuccess) return true

      return false
    },
    [submitNominationVersionMutation]
  )

  return {
    createNomination,
    createVersion,
    archiveNomination,
    archiveNominationVersion,
    submitNominationVersion,
  }
}

export default useNominationActionsModel
