import { PropsWithChildren } from 'react'

import classNames from 'classnames'

import styles from './Table.module.scss'

export const THButton: React.FC<PropsWithChildren<{ className?: string; handleClick: () => void }>> = ({
  className,
  handleClick,
  children,
}) => (
  <button className={classNames(styles.tableHeaderButton, className)} onClick={handleClick}>
    {children}
  </button>
)

export const HeaderCell: React.FC<PropsWithChildren<{ size?: number; className?: string }>> = ({
  children,
  size,
  className,
}) => (
  <th
    className={classNames(styles.cell, styles.headerCell, className)}
    style={
      size
        ? {
            width: size,
          }
        : undefined
    }>
    {children}
  </th>
)

export const TB: React.FC<PropsWithChildren<{ className?: string }>> = ({ className, children }) => (
  <table className={classNames(styles.table, className)}>{children}</table>
)

export const TBR: React.FC<PropsWithChildren<{ isOdd?: boolean; className?: string }>> = ({
  children,
  className,
  isOdd,
}) => <tr className={classNames(className, { [styles.odd]: isOdd })}>{children}</tr>

export const TBHead: React.FC<PropsWithChildren> = ({ children }) => (
  <thead className={styles.tableHeader}>{children}</thead>
)

export const TBBody: React.FC<PropsWithChildren> = ({ children }) => (
  <tbody className={styles.tableBody}>{children}</tbody>
)
