import { format } from 'date-fns'
import { parse, Options } from 'json2csv'

function defaultTemplate(date: Date) {
  const formatted = format(date, 'yyyy-MM-dd-hh-mm-ss-SS')

  return `ingram-barge-export-${formatted}.csv`
}

export const downloadFile = (mimeType: string, payload: string, filename: string) => {
  const element = document.createElement('a')
  element.setAttribute('href', `data:${mimeType};charset=utf-8,${payload}`)

  element.setAttribute('download', `${filename}`)
  element.style.display = 'none'
  document.body.appendChild(element)

  element.click()
  document.body.removeChild(element)
}

export const handleCSVDownload = <Data>(
  { data, fields }: Pick<Options<Data>, 'fields'> & { data: Readonly<Data> | ReadonlyArray<Data> },
  template: (date: Date) => string = defaultTemplate
) => downloadFile('text/csv', encodeURIComponent(parse(data, { fields })), template(new Date()))
