import { useCallback, useEffect, useState } from 'react'

import classnames from 'classnames'
import { Formik, FormikHelpers, Field } from 'formik'
import { useLocation } from 'wouter'
import { object as YupObject, string as YupString } from 'yup'

import { WarningMessage } from '../../ui/MessageBox/MessageBox'

import styles from './Account.module.scss'
import { AuthorizationModes, useAuthorizationContext } from './AuthorizationContext'
import { Form, ErrorNotification } from './Form'
import { Layout } from './Layout'

const passwordResetValidationSchema = YupObject().shape(
  {
    email: YupString().required('Required').email(),
  },
  []
)

export function ResetPasswordPage() {
  const [, navigate] = useLocation()
  const { forgotPassword, mode } = useAuthorizationContext()

  const [{ authenticationError, formValues }, setState] = useState<{
    formValues: { email: string }
    authenticationError: Error | null
  }>({
    formValues: { email: '' },
    authenticationError: null,
  })

  useEffect(() => {
    if (mode !== AuthorizationModes.AUTHENTICATION_REQUIRED) {
      navigate('/')
    }
  }, [mode, navigate])

  const handleOnSubmit = useCallback(
    (form: { email: string }, { setSubmitting }: FormikHelpers<{ email: string }>) => {
      forgotPassword(form.email)
        .then(() => navigate('/set-password'))
        .catch(err => {
          setState(state => ({
            ...state,
            authenticationError: err,
          }))
          setSubmitting(false)
        })
    },
    [forgotPassword, navigate]
  )

  return (
    <Layout>
      <Formik initialValues={formValues} validationSchema={passwordResetValidationSchema} onSubmit={handleOnSubmit}>
        {({ dirty, isValid, isSubmitting, touched, errors }) => (
          <Form>
            <h2>Reset your password</h2>
            {authenticationError && (
              <WarningMessage
                title="Something went wrong"
                handleClose={() => setState(state => ({ ...state, authenticationError: null }))}>
                {authenticationError.message ? authenticationError.message : ''}
              </WarningMessage>
            )}
            <Field
              id="email"
              name="email"
              className={classnames(styles.input, { [styles.hasError]: touched.email && errors.email })}
              placeholder="email"
            />
            {touched.email && errors.email && <ErrorNotification message={errors.email} />}
            <button disabled={!dirty || !isValid || isSubmitting} className={styles.submit} type="submit">
              Submit
            </button>
          </Form>
        )}
      </Formik>
    </Layout>
  )
}
