import React from 'react'

import { CloseIcon } from '@graphiql/react'

import styles from './styles.module.scss'

type TboItemProps = {
    tbo: number
    onRemove: (tbo: number) => void
}

const TboItem: React.FC<TboItemProps> = ({ tbo, onRemove }) => {
    return (
        <div className={styles.tboItem}>
            {tbo}
            <button onClick={() => onRemove(tbo)} className={styles.removeButton}>
                <CloseIcon />
            </button>
        </div>
    )
}

export default TboItem
