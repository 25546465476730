import { useCallback, useEffect, useState } from 'react'

import classnames from 'classnames'
import { Field, Formik, FormikHelpers } from 'formik'
import { useLocation } from 'wouter'
import { object as YupObject, string as YupString, ref as YupRef } from 'yup'

import { WarningMessage } from '../../ui/MessageBox/MessageBox'

import styles from './Account.module.scss'
import { AuthorizationModes, useAuthorizationContext } from './AuthorizationContext'
import { ErrorNotification, Form } from './Form'
import { Layout } from './Layout'

type SetPasswordFormData = {
  code: string
  password: string
  passwordConfirmation: string
}

const initialState: SetPasswordFormData = { code: '', password: '', passwordConfirmation: '' }

const passwordResetValidationSchema = YupObject().shape(
  {
    code: YupString().required('Required'),
    password: YupString().required('Required'),
    passwordConfirmation: YupString()
      .required('Required')
      .oneOf([YupRef('password'), null], 'Password and confirmation must match'),
  },
  []
)

export function SetPasswordPage() {
  const [, navigate] = useLocation()
  const { resetPassword, mode } = useAuthorizationContext()

  const [{ authenticationError, formValues }, setState] = useState<{
    formValues: SetPasswordFormData
    authenticationError: Error | null
  }>({
    formValues: initialState,
    authenticationError: null,
  })

  useEffect(() => {
    if (mode !== AuthorizationModes.AUTHENTICATION_REQUIRED) {
      navigate('/')
    }
  }, [mode, navigate])

  const handleOnSubmit = useCallback(
    (form: SetPasswordFormData, { setSubmitting }: FormikHelpers<SetPasswordFormData>) => {
      resetPassword(form.code, form.password)
        .then(() => navigate('/login?passwordReset=true'))
        .catch(err => {
          setState(state => ({
            ...state,
            authenticationError: err,
          }))
          setSubmitting(false)
        })
    },
    [resetPassword, navigate]
  )

  return (
    <Layout>
      <Formik initialValues={formValues} validationSchema={passwordResetValidationSchema} onSubmit={handleOnSubmit}>
        {({ dirty, isValid, isSubmitting, touched, errors }) => (
          <Form>
            <h2>Set new password</h2>
            {authenticationError && (
              <WarningMessage
                title="Something went wrong"
                handleClose={() => setState(state => ({ ...state, authenticationError: null }))}>
                {authenticationError.message ? authenticationError.message : ''}
              </WarningMessage>
            )}
            <Field
              id="code"
              name="code"
              className={classnames(styles.input, { [styles.hasError]: touched.code && errors.code })}
              placeholder="Code"
            />
            {touched.code && errors.code && <ErrorNotification message={errors.code} />}
            <Field
              id="password"
              name="password"
              type="password"
              className={classnames(styles.input, { [styles.hasError]: touched.password && errors.password })}
              placeholder="Password"
            />
            {touched.password && errors.password && <ErrorNotification message={errors.password} />}
            <Field
              id="passwordConfirmation"
              name="passwordConfirmation"
              type="password"
              className={classnames(styles.input, {
                [styles.hasError]: touched.passwordConfirmation && errors.passwordConfirmation,
              })}
              placeholder="Confirm password"
            />
            {touched.passwordConfirmation && errors.passwordConfirmation && (
              <ErrorNotification message={errors.passwordConfirmation} />
            )}
            <span className={styles.clarificationText}>
              Password must contain lowercase and uppercase letters, numbers, and symbols.
            </span>
            <button disabled={!dirty || !isValid || isSubmitting} className={styles.submit} type="submit">
              Submit
            </button>
          </Form>
        )}
      </Formik>
    </Layout>
  )
}
