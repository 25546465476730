import { StrictMode } from 'react'

import * as Sentry from '@sentry/react'
import { createRoot } from 'react-dom/client'

import './styles/base.module.scss'
import './styles/theme.module.scss'

import { App } from './App'
import { ErrorBoundary } from './components/ErrorBoundary'
import { DEPLOYMENT_ENVIRONMENT, isAcceptanceEnv, SENTRY_DSN } from './constants/env-vars'

Sentry.init({
  environment: DEPLOYMENT_ENVIRONMENT,
  dsn: SENTRY_DSN,
  integrations: [
    new Sentry.Replay({
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
  denyUrls: ['http://localhost:3000'],

  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

const rootEl = document.getElementById('root')!
const root = createRoot(rootEl)

if (!isAcceptanceEnv) {
  rootEl.style.background = 'none'
}

root.render(
  <StrictMode>
    <Sentry.ErrorBoundary fallback={<ErrorBoundary />}>
      <App />
    </Sentry.ErrorBoundary>
  </StrictMode>
)
