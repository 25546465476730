import { format } from 'date-fns'
import { utils, writeFileXLSX } from 'xlsx'

import { columns, columnKeys } from '../../../components/Table/BargesTable'
import { ELLIPSIS } from '../../../constants/constants'
import { browserInfo } from '../../../lib/browser-info'

import type { DepartureTimeFormValues, LaneSelectionFormValues, PoolFiltersSelectionFormValues, TowParametersFormValues, VesselSelectionFormValues } from './types'
import type { OverviewBarge } from '../../../Domain/Barge'
import type { UserDetails } from '../../Account/AuthorizationContext'


const NOT_AVAILABLE = 'N/A'
 
export function collectFormFeedbackData(
 userInfo: UserDetails,
 lane: LaneSelectionFormValues,
 boat: VesselSelectionFormValues,
 departureTime: DepartureTimeFormValues,
 poolFilters: PoolFiltersSelectionFormValues,
 towParameters: TowParametersFormValues,
 barges: OverviewBarge[],
 recordTime?: Date
) {
 const { email, cognitoUsername } = userInfo
 const info = browserInfo()
 const workbook = utils.book_new()

 const feedbackSheetData = [
  ['info'],
  ['browser', info.browser],
  ['browser version', info.browserVersion],
  ['os', info.os],
  ['device', info.device],
  [],
  ['user'],
  ['email', email],
  ['username', cognitoUsername],
  [],
  ['lane & vessel info'],
  ['lane', lane.laneId ?? NOT_AVAILABLE],
  ['origin', lane.origin ?? NOT_AVAILABLE],
  ['destination', lane.destination ?? NOT_AVAILABLE],
  ['vessel', boat.boatId ?? NOT_AVAILABLE],
  [],
  ['departure time'],
  ['date', departureTime.selectedDate ? format(departureTime.selectedDate, 'yyyy-MM-dd') : NOT_AVAILABLE],
  ['time', departureTime.time ? `${departureTime.time.hours}:${departureTime.time.minutes}` : NOT_AVAILABLE],
  [],
  ['pool filters'],
  ['max draft (feet)', poolFilters.feet ?? NOT_AVAILABLE],
  ['max draft (inches)', poolFilters.inch ?? NOT_AVAILABLE],
  ['exclude tanks', poolFilters.tanks],
  ['exclude open hopper', poolFilters.openHopper],
  ['exclude TBO info barges', poolFilters.havingTboInfo],
  ['exclude placed-to-load barges', poolFilters.placedToLoad],
  ['exclude shuttle moves', poolFilters.shuttleMoves],
  ['include TBOs', poolFilters.includeTBOs.length > 0 ? poolFilters.includeTBOs.join(', ') : NOT_AVAILABLE],
  [],
  ['tow parameters'],
  ['goal', towParameters.goal || NOT_AVAILABLE],
  ['number of loaded barges', towParameters.numberOfLoaded],
  ['number of empty barges', towParameters.numberOfEmpties],
  ['number of strings', towParameters.numberOfStrings ?? NOT_AVAILABLE],
  ['prioritize hot barges', towParameters.hotBarges],
  [],
  ['export time', format(recordTime || new Date(), 'yyyy-MM-dd HH:mm:ss')],
 ]

 utils.book_append_sheet(workbook, utils.aoa_to_sheet(feedbackSheetData), 'Feedback')

 
 const bargesSheetData = [
   columnKeys.map(key => columns[key].label),
   ...barges.map(
     barge => columnKeys.map(key => columns[key].format(barge) ?? ELLIPSIS)
   ),
 ]
 utils.book_append_sheet(workbook, utils.aoa_to_sheet(bargesSheetData), 'Barges')


 const fileName = `form-feedback-${format(new Date(), 'yyyy-MM-dd')}.xlsx`
 writeFileXLSX(workbook, fileName)
}
