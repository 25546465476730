import { forwardRef } from 'react'

import { CalendarMonth } from '@mui/icons-material'
import classnames from 'classnames'
import { format } from 'date-fns'
import ReactDatePicker from 'react-datepicker'

import { TimeInputElement } from '../../../ui/TimeInput/TimeInput'

import styles from './LaneFilters.module.scss'
import { ColumnEntry } from './Layout'

export type TimeWindow = {
  day: Date
  time: string
}

const dateFmt: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
}

const fmtDate = Intl.DateTimeFormat('en-US', dateFmt).format

export function fromFilterTime(timeStamp: number): TimeWindow {
  const day = new Date(timeStamp)
  const time = format(day, 'HH:mm')
  return { day: new Date(day.setHours(0, 0, 0, 0)), time: time === '00:00' ? '' : time }
}

const CustomInput = forwardRef<HTMLInputElement, any>((props, ref) => (
  <span
    className={classnames(styles.input, { [styles.isDisabled]: props.disabled || props.readOnly })}
    onClick={props.onClick}>
    <CalendarMonth />
    <input
      disabled={props.disabled || props.readOnly}
      ref={ref}
      placeholder="Select a date"
      value={props.selected ? fmtDate(props.selected) : ''}
      onChange={() => {}}
    />
  </span>
))

export function getTimeWindowValue({ time, day }: TimeWindow) {
  const { hour, minute } =
    time.length === 4
      ? {
          hour: parseInt(time[0] + time[1], 10),
          minute: parseInt(time[2] + time[3], 10),
        }
      : { hour: 0, minute: 0 }

  return new Date(day.getFullYear(), day.getMonth(), day.getDate(), hour, minute).getTime()
}

type Props = {
  isDisabled: boolean
  time?: number
  setTimeWindow: (timeWindow?: number) => void
  readOnly?: boolean
}

export function TimeWindowFilter({ isDisabled, time: filterTime, setTimeWindow, readOnly }: Props) {
  const timeWindow = filterTime ? fromFilterTime(filterTime) : null
  return (
    <>
      <ColumnEntry columnClassnames={[classnames({ [styles.disabledLabel]: readOnly })]}>
        <span>Day</span>
        <ReactDatePicker
          minDate={new Date()}
          disabled={isDisabled}
          selected={timeWindow?.day}
          readOnly={readOnly}
          startDate={timeWindow?.day}
          popperPlacement="top"
          customInput={
            <CustomInput selected={timeWindow?.day} readOnly={readOnly} className={readOnly ? styles.readOnly : ''} />
          }
          onChange={day => {
            if (day) {
              setTimeWindow(getTimeWindowValue(timeWindow ? { ...timeWindow, day } : { day, time: '' }))
            }
          }}
        />
      </ColumnEntry>
      <ColumnEntry columnClassnames={[classnames({ [styles.disabledLabel]: readOnly })]}>
        <span>Time</span>
        <div className={classnames({ [styles.disabled]: timeWindow === null || readOnly })}>
          <TimeInputElement
            value={timeWindow?.time}
            isDisabled={timeWindow === null || readOnly}
            handleChange={time => {
              if (timeWindow !== null) {
                setTimeWindow(getTimeWindowValue({ ...timeWindow, time }))
              }
            }}
          />
        </div>
      </ColumnEntry>
      <ColumnEntry>
        <button
          disabled={timeWindow === null}
          onClick={() => setTimeWindow(undefined)}
          className={classnames(styles.clearButton, { [styles.readOnly]: readOnly })}>
          Clear
        </button>
      </ColumnEntry>
    </>
  )
}
