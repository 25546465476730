import { ArrowForward } from '@mui/icons-material'

import styles from './styles.module.scss'

export const ErrorBoundary = () => (
  <div className={styles.errorBoundaryPage}>
    <div className={styles.sky} />
    <div className={styles.water}>
      <h1>Ooops something went wrong!</h1>
      <p>The error has been logged and sent to the development team</p>
      <p>
        <a href="/">
          Take me back to the home page <ArrowForward />
        </a>
      </p>
      <svg
        className={styles.ship}
        viewBox="0 0 306 284"
        preserveAspectRatio="xMidYMid meet"
        xlinkHref="http://www.w3.org/1999/xlink"
        xmlns="http://www.w3.org/2000/svg">
        <defs>
          <path id="a" d="M155.678 77H.593V.224h155.085z" />
          <path id="c" d="M0 1.389v-1h306.51v1H0z" />
        </defs>
        <g fill="none" fillRule="evenodd">
          <path
            fill="#001F4B"
            d="M53.738 80.836l-23.905-7.991 7.947-23.782 23.905 7.989zM44.081 109.725l-23.803-7.953 7.95-23.78 23.801 7.959zM80.867 89.903l-23.899-7.989 7.947-23.782 23.9 7.986zM71.211 118.794l-23.902-7.989 7.947-23.778 23.902 7.99zM108.19 99.037L84.29 91.048l7.948-23.784 23.904 7.99zM98.534 127.926l-23.902-7.986 7.947-23.778 23.903 7.987zM135.32 108.102l-23.9-7.99 7.95-23.78 23.897 7.985zM125.666 136.995l-23.905-7.989 7.948-23.78 23.902 7.99zM162.45 117.17l-23.902-7.988 7.95-23.782 23.901 7.986zM152.793 146.06l-23.899-7.984 7.945-23.78 23.899 7.99zM189.58 126.237l-23.9-7.986 7.95-23.785 23.896 7.987zM179.926 155.129l-23.902-7.987 7.947-23.78 23.899 7.99zM63.454 51.766l-23.905-7.99 7.947-23.784 23.905 7.989zM90.581 60.835l-23.899-7.989 7.95-23.785 23.9 7.989zM117.907 69.967l-23.902-7.99 7.95-23.784 23.899 7.989zM145.036 79.033l-23.899-7.989 7.947-23.782 23.9 7.986zM172.166 88.1l-23.899-7.99 7.947-23.78 23.902 7.986zM199.296 97.169l-23.9-7.988 7.95-23.785 23.897 7.989zM216.717 135.307l-23.907-7.991 7.95-23.78 23.904 7.989zM207.058 164.198l-23.904-7.989 7.947-23.78 23.907 7.992zM245.032 145.437l-23.897-7.984 7.947-23.785 23.902 7.99zM234.378 173.33l-23.899-7.989 7.947-23.775 23.899 7.984zM284.997 188.108l-14.281-20.765 20.663-14.211 14.279 20.763zM261.372 182.35l-23.767-7.94 7.951-23.78 23.761 7.943zM226.43 106.236l-23.906-7.989 7.955-23.785 23.902 7.992zM258.748 118.368l-23.896-7.986 7.947-23.785 23.902 7.99zM274.497 154.96l-21.02-13.906 13.836-20.915 21.02 13.905zM84.342 0l-7.947 23.78 23.9 7.988 7.95-23.779zM127.62 40.9l-23.9-7.988 7.948-23.78 23.903 7.99zM154.753 49.968l-23.905-7.99 7.95-23.777 23.902 7.987zM181.88 59.034l-23.9-7.986 7.948-23.783 23.902 7.99zM209.01 68.104l-23.9-7.99 7.95-23.78 23.9 7.985zM236.142 77.173l-23.902-7.99 7.948-23.781 23.904 7.992zM266.177 90.258l-22.555-11.235 11.18-22.443 22.554 11.236z"
          />
          <path fill="#9B9B9B" d="M230.72 43.385L129.282 9.477l1.979-5.923L232.7 37.457z" />
          <path fill="#413B34" d="M254.714 225.643l-.092-.028c-.052.104-.117.21-.184.326.182-.194.276-.298.276-.298" />
          <path
            fill="#02709C"
            d="M8.514 156.809c-4.154-4.175-6.215-7.715-7.196-10.493 2.753 16.207 14.11 64.322 55.812 103.209 0 0 7.037 18.945 38.464 29.449l24.134-72.21C29.755 177.813 8.514 156.809 8.514 156.809"
          />
          <path fill="#FFF" d="M.52 140.685s.012.146.043.423a8.68 8.68 0 0 1 .05-.392l-.094-.031z" />
          <g transform="translate(95 206.54)">
            <mask id="b" fill="#fff">
              <use xlinkHref="#a" />
            </mask>
            <path
              fill="#155D7A"
              d="M143.634 27.18S114.03 31.195 24.728.225L.593 72.434c31.43 10.504 48.447-.402 48.447-.402 56.683-5.998 94.685-37.597 106.638-48.9-2.453 1.628-6.23 3.214-12.044 4.049"
              mask="url(#b)"
            />
          </g>
          <path fill="#504941" d="M250.678 229.672c2.035-1.347 3.154-2.727 3.76-3.731a94.518 94.518 0 0 1-3.76 3.73" />
          <path fill="#FFF" d="M1.317 146.316c-.449-2.641-.67-4.441-.754-5.208-.12 1.163-.049 2.927.754 5.208" />
          <path
            fill="#0D83B3"
            d="M255.108 224.433l13.853-41.441c-12.784-7.595-37.093-24.09-54.798-48.538l-63.207-21.125-23.34 69.835-7.889 23.6c89.303 30.97 118.906 26.957 118.906 26.957 5.816-.835 9.591-2.421 12.044-4.05a93.79 93.79 0 0 0 3.76-3.73c.067-.115.133-.222.185-.326.388-.723.486-1.182.486-1.182"
          />
          <path
            fill="#079BD7"
            d="M87.755 92.204c-28.846 8.887-58.186 7.454-72.967 5.837L.936 139.48s-.2.428-.323 1.237a6.591 6.591 0 0 0-.05.391c.084.767.305 2.568.754 5.208.981 2.78 3.042 6.32 7.196 10.493 0 0 21.241 21.004 111.214 49.956l7.888-23.6 23.341-69.836-63.2-21.124z"
          />
          <g transform="translate(-11 179.54)">
            <mask id="d" fill="#fff">
              <use xlinkHref="#c" />
            </mask>
            <path fill="#025394" d="M349.861.389H-64.913" mask="url(#d)" />
          </g>
        </g>
      </svg>
    </div>
  </div>
)
