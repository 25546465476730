import React from 'react'

import classnames from 'classnames'

import Button from '../../../components/Button'
import { TboLinkStatus } from '../../../generated/graphql'

import LabelValuePair from './LabelValuePair'
import styles from './styles.module.scss'

import type { NominationSummary, Navigation } from './viewModel'

type Props = {
  summary: NominationSummary
  navigation: Navigation
  initNominationArchiving: () => void
  handleExport : () => void
}

export const TboLinkStatusLabels: Record<TboLinkStatus, string> = {
  [TboLinkStatus.Broken]: 'Broken',
  [TboLinkStatus.Established]: 'Established',
  [TboLinkStatus.NotEstablished]: 'Not Established',
}

const NominationSummaryBlock = ({ summary, navigation, initNominationArchiving, handleExport }: Props) => {
  const { tboLinkStatus, orderNumber } = summary

  return (
    <div className={styles.container}>
      <div className={classnames([styles.section, styles.nominationSummary])}>
        <div className={styles.info}>
          <h2>{summary.title}</h2>
          <LabelValuePair label="Creation Time" value={summary.recordTime} />
          <LabelValuePair label="TBO Link Status" value={TboLinkStatusLabels[tboLinkStatus]} />
          {orderNumber && <LabelValuePair label="Order Number" value={orderNumber} />}
        </div>
        <div className={styles.actions}>
          <Button className={styles.button} onClick={navigation.newNominationVersion}>
            New Version
          </Button>
          <Button className={styles.button} onClick={handleExport}>
            Export Feedback
          </Button>
          {summary.canBeArchived && (
            <Button className={classnames(styles.button, styles.danger)} onClick={initNominationArchiving}>
              Archive Nomination
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

export default NominationSummaryBlock
