import { useCallback, useEffect, useState } from 'react'

import { AddCircle } from '@mui/icons-material'
import { useLocation } from 'wouter'

import {
  LastUpdateIndicator,
  PrimaryMenu,
  PrimaryMenuRight,
  ToggleFilterScreenButton,
} from '../../../components/Header/PrimaryMenu/PrimaryMenu'
import { NominateBarges } from '../../../components/Nomination/BargeNomination/NominateBarges'
import { NewNominationPage as NewNomination } from '../../../components/Nomination/NominationPage'
import { OverviewBarge } from '../../../Domain/Barge'
import { fromRequestFilters, UserBargeNomination } from '../../../Domain/Nomination'
import { useSaveBargeNominationMutation } from '../../../generated/graphql'
import { Layout } from '../../../ui/Layout/Layout'
import { errorToast, successToast } from '../../../ui/Toast/Toast'
import { collectFeedbackData } from '../../../utils/collectFeedbackData'
import { useAuthorizationContext } from '../../Account/AuthorizationContext'

import styles from './NewNominationPage.module.scss'

function useActions(barges: OverviewBarge[]) {
  const { getUserInfo } = useAuthorizationContext()
  const [{ fetching }, saveNominationMutation] = useSaveBargeNominationMutation()

  const saveNomination = useCallback(
    async (uuid: string) => {
      try {
        const { data } = await saveNominationMutation({ uuid })
        if (data) {
          successToast('Nomination has been saved!')
        }
      } catch (e) {
        if (e instanceof Error) errorToast(e.message)
      }
    },
    [saveNominationMutation]
  )

  const exportFeedback = useCallback(
    (nomination: UserBargeNomination) => {
      const {
        tows,
        userRequest: { bargeFilters, towConfiguration, prioritizeHotBarges },
      } = nomination

      const filters = fromRequestFilters(bargeFilters)
      const userInfo = getUserInfo()
      if (userInfo) {
        collectFeedbackData(
          userInfo,
          bargeFilters.lane,
          filters,
          { prioritizeHotBarges, towConfiguration },
          tows,
          barges
        )
      }
    },
    [getUserInfo, barges]
  )
  return {
    exportFeedback,
    saveNomination,
    isSaving: fetching,
  }
}

export function NewNominationPage({ mode }: { mode: 'view' | 'create' | 'edit' }) {
  const [, setLocation] = useLocation()
  const [{ nomination, barges }, setNominationData] = useState<{
    nomination?: UserBargeNomination
    barges: OverviewBarge[]
  }>({ barges: [] })

  const { exportFeedback, saveNomination, isSaving } = useActions(barges)

  const nominationId = nomination?.uuid

  useEffect(() => {
    if (nominationId) {
      setLocation('/nominations/view')
    }
  }, [nominationId, setLocation])

  return (
    <Layout
      primaryMenu={({ isDrawerOpen, setIsDrawerOpen }) => (
        <PrimaryMenu isDrawerOpen={isDrawerOpen} setDrawerOpen={setIsDrawerOpen}>
          <PrimaryMenuRight>
            <LastUpdateIndicator />
            <ToggleFilterScreenButton
              handleClick={() => setLocation(`/nominations/${nominationId ? 'edit' : 'create'}`)}
            />
          </PrimaryMenuRight>
        </PrimaryMenu>
      )}>
      {mode !== 'view' ? (
        <NominateBarges
          nomination={mode === 'edit' ? nomination : undefined}
          setNominationData={setNominationData}
          handleClose={() => setLocation('/nominations/view')}
          readOnly={false}
        />
      ) : null}
      {nomination ? (
        <NewNomination
          handleFeedbackExport={() => exportFeedback(nomination)}
          handleSaveNomination={() => saveNomination(nomination.uuid)}
          isSaving={isSaving}
          nomination={nomination}
        />
      ) : (
        <div className={styles.createNomination}>
          <button
            title="Create New Nomination"
            onClick={() => setLocation('/nominations/create')}
            className={styles.createNominationButton}>
            <AddCircle />
            <span>Create New Nomination</span>
          </button>
        </div>
      )}
    </Layout>
  )
}
