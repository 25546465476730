export type Nullable<A> = A | null | undefined

export function map<A, B>(a: Nullable<A>, fn: (a: A) => B): Nullable<B> {
  if (a === null) return null
  if (a === undefined) return undefined

  return fn(a)
}

export function map2<A, B, C>(a: Nullable<A>, b: Nullable<B>, fn: (a: A, b: B) => C): Nullable<C> {
  if (a === null || b === null) return null
  if (a === undefined || b === undefined) return undefined

  return fn(a, b)
}
