import { PropsWithChildren } from 'react'

import { Tune } from '@mui/icons-material'

import { HamburgerIcon } from '../../../ui/HamburgerIcon/HamburgerIcon'
import { LastBargePoolUpdate } from '../LastBargePoolUpdate/LastBargePoolUpdate'

import styles from './PrimaryMenu.module.scss'

export const ToggleFilterScreenButton = ({
  handleClick,
  isDisabled = false,
}: {
  isDisabled?: boolean
  handleClick?: () => void
}) => (
  <button onClick={handleClick} disabled={isDisabled} className={styles.menuItem}>
    <Tune />
  </button>
)

export const LastUpdateIndicator = () => (
  <div className={styles.menuItem}>
    <LastBargePoolUpdate />
  </div>
)

export function PrimaryMenu({
  isDrawerOpen,
  setDrawerOpen,
  children,
}: PropsWithChildren<{ isDrawerOpen: boolean; setDrawerOpen: (b: boolean) => void }>) {
  return (
    <header className={styles.primaryMenu}>
      <button type="button" className={styles.menuItem} onClick={() => setDrawerOpen(!isDrawerOpen)}>
        <HamburgerIcon isOpen={isDrawerOpen} />
      </button>
      {children ?? null}
    </header>
  )
}

export const PrimaryMenuRight: React.FC<PropsWithChildren> = ({ children }) => (
  <div className={styles.right}>{children}</div>
)
