import { PropsWithChildren } from 'react'

import { Formik, FormikHelpers, useFormikContext } from 'formik'
import { object as YupObject, string as YupString, number as YupNumber, bool as YupBool } from 'yup'

import { TBN_BOAT_ID, TowNominationFormInput } from './TowConfigurationSection'

const MINIMAL_NR_OF_BARGES = 2

export type Values = {
  prioritizeHotBarges: boolean
  towConfiguration: TowNominationFormInput
}

export type ParentType = {
  numberOfLoaded?: number
  numberOfEmpties?: number
}

export type PreselectedBarge = { id: string; name: string | null }

export const useNominationFormContext = useFormikContext<Values>

export const getMaxNumberOfStrings = (parent: ParentType): number => {
  const totalNumberOfBarges = (parent.numberOfLoaded ?? 0) + (parent.numberOfEmpties ?? 0)
  return Math.floor(totalNumberOfBarges / 2)
}

const validationSchema = YupObject().shape(
  {
    prioritizeHotBarges: YupBool().default(false),
    towConfiguration: YupObject().shape({
      goal: YupString().required('Required'),
      numberOfLoaded: YupNumber().test(
        'minimum amount of barges',
        `A nomination should contain at least ${MINIMAL_NR_OF_BARGES} barges`,
        (numberOfLoaded, { parent }) => (numberOfLoaded ?? 0) + (parent.numberOfEmpties ?? 0) >= MINIMAL_NR_OF_BARGES
      ),
      numberOfEmpties: YupNumber(),
      numberOfStrings: YupNumber()
        .nullable()
        .positive('Number of strings must be greater than 0')
        .integer('Number of strings must be an integer')
        .test(
          'valid number of strings',
          'Number of strings must be less than or equal to half the total number of barges',
          (numberOfStrings, context) => {
            const maxNumberOfStrings = getMaxNumberOfStrings(context.parent)
            return numberOfStrings === null || (numberOfStrings !== undefined && numberOfStrings <= maxNumberOfStrings)
          }
        ),
      boat: YupString().required('Required').default(TBN_BOAT_ID),
      hasTurnboat: YupBool().default(false),
    }),
  },
  []
)

type Props = PropsWithChildren<
  Values & {
    preselectedBarges: PreselectedBarge[]
    handleSubmit: (values: Values, helpers: FormikHelpers<Values>) => void
  }
>

export const NominationForm = ({ children, towConfiguration, prioritizeHotBarges, handleSubmit }: Props) => (
  <Formik<Values>
    enableReinitialize
    validationSchema={validationSchema}
    initialValues={{
      prioritizeHotBarges,
      towConfiguration,
    }}
    onSubmit={handleSubmit}>
    {children}
  </Formik>
)
