import { Link } from 'wouter'

import { docsPagePath } from '../../paths'

import { AdNote } from './Admonition/Admonintion'
import { P } from './Components/Components'

export const OperationalGoalsDoc = () => (
  <>
    <h1>Operational Goals</h1>
    <h2 data-section="overview">Overview</h2>
    <P>
      The purpose of the operational goals is to give default instructions for the engine that will give a similar
      nomination as the user would judge as the best option.
    </P>
    <P>
      The task of barge nomination for tows involves a wide range of operational tradeoffs to consider in every planning
      session, and the best way to balance these tradeoffs will change depending on planning and operating contexts that
      are not easily determined from data sources. Because of this, the engine does not have one "universal" standard
      for finding an optimal nomination, but instead uses a set of distinct <i>operational goals</i> given to it by the
      user for running the optimization. Each of these goals is based on certain primary goals that the user typically
      brings to mind during planning.
    </P>
    <P>
      Each operational goal has its own distinct way of balancing tradeoffs. The tradeoffs are represented in the engine
      through various <Link href={docsPagePath('nomination-policies')}>nomination policies</Link>.
    </P>
    <h3 data-section="list-of-goals">List of operational goals</h3>
    <P>
      <ul>
        <li>
          <Link href={docsPagePath('build-to-destination')}>Build to Destination</Link>
        </li>
        <li>
          <Link href={docsPagePath('fleet-efficiency')}>Fleet Efficiency</Link>
        </li>
        <li>Linehaul Turn Time</li>
      </ul>
    </P>
    <AdNote title="Future development">
      The operational goal Barge Destination is still under development and will be included in a future iteration of
      the engine.
    </AdNote>
    <h2 data-section="web-app">Web-app interface</h2>
    <P>
      Choosing an operational goal is a required step for sending a nomination request to the engine. They can be found
      in a dropdown list within the Nomination Form.
    </P>
    <h2 data-section="advanced">Advanced points</h2>
    <P>
      Because the metrics used within the nomination policies are calculated from a variety of units (e.g. miles, having
      an even number of rakes, hours in fleet, etc) at three different levels, they cannot be easily converted from one
      to another. This also means there is <i>no one universally "best" way</i> to choose the weights that make the
      tradeoffs between the policies for the operational goals.
    </P>
    <h3>Types of policies</h3>
    <P>
      Each operational goal can have the following:
      <ol>
        <li>
          One or more <b>primary policies</b>: these are the policies that are a distinctively targeted by the
          operational goal as compared to the other operational goals. Nomination policies <i>cannot</i> be a primary
          policy for <i>more</i> than one operational goal, so that means the primary policies are unique to each goal.
        </li>
        <li>
          Zero or more <b>secondary policies</b>: these are policies that the goal gives a higher than usual importance
          compared to other goals, but these are not unique and distinctive to the goal
        </li>
        <li>
          Zero or more <b>minor policies</b>: these are policies that relate to the goal's particular purpose, but with
          lesser importance than the primary and secondary policies
        </li>
        <li>
          Zero or more <b>general policies</b>: these are policies which need to be included for generating good
          nominations, but they are not necessarily directly related to the goal's purpose and the importance attached
          to general policies is more or less the same across each operational goal
        </li>
      </ol>
    </P>
  </>
)
