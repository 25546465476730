import { useState, useCallback } from 'react'

import { DownloadOutlined, SvgIconComponent } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import classnames from 'classnames'

import { NominatedTow } from '../../Domain/Nomination'
import {
  groupByKeys,
  GroupByType,
  sortByKeysN,
  SortByTypeN,
  sortOrderKeys,
  SortOrderType,
} from '../../ui/Table/TableColumnConfig'
import { handleCSVDownload } from '../../utils/downloadFile'
import CopyDataButton from '../Buttons/CopyDataButton'
import { getDataByColumns, NominatedBargesTable, Theme } from '../Table/NominatedBargesTable'

import GroupBySelector from './GroupBySelector'
import styles from './Nomination.module.scss'

const theme: Theme = {
  columns: {
    [SortByTypeN.IsAvailable]: styles.cell,
    [SortByTypeN.TboInfo]: styles.tboCell,
    [SortByTypeN.Name]: styles.nameCell,
    [SortByTypeN.IsAtRisk]: styles.isAtRiskCell,
    [SortByTypeN.Cargo]: undefined,
    [SortByTypeN.Destination]: styles.cell,
    [SortByTypeN.CurrentLocation]: styles.cell,
    [SortByTypeN.HullType]: styles.hullTypeCell,
    [SortByTypeN.TripStatus]: styles.cell,
    [SortByTypeN.Pickup]: styles.locationCell,
    [SortByTypeN.DropOff]: styles.locationCell,
    [SortByTypeN.PickupType]: styles.cell,
    [SortByTypeN.Type]: styles.cell,
    [SortByTypeN.Fleet]: styles.cell,
    [SortByTypeN.None]: styles.cell,
  },
}

type Props = {
  barges: NominatedTow['barges']
  hasReviews: boolean
  bargesInTow?: string[]
}

function Header({
  barges,
  onGroupByChange,
  onSortByChange,
  onSortOrderChange,
  groupBy,
  sortBy,
  sortOrder,
  sortByKeys,
}: {
  barges: NominatedTow['barges']
  onGroupByChange: (value: GroupByType) => void
  onSortByChange: (value: SortByTypeN) => void
  onSortOrderChange: (value: SortOrderType) => void
  groupBy: GroupByType
  sortBy: SortByTypeN
  sortOrder: SortOrderType
  sortOrderKeys: SortOrderType[]
  sortByKeys: SortByTypeN[]
}) {
  const bargeNamesCallback = useCallback(() => barges.map(({ name }) => name).join(', '), [barges])

  return (
    <header className={styles.header}>
      <GroupBySelector
        groupBy={groupBy}
        groupByKeys={groupByKeys}
        handleGroupByChange={onGroupByChange}
        sortBy={sortBy}
        sortByKeys={sortByKeys}
        handleSortByChange={onSortByChange}
        sortOrder={sortOrder}
        sortOrderKeys={sortOrderKeys}
        handleSortOrderChange={onSortOrderChange}
      />
      <TowActionButton
        label="Export Table to CSV"
        onClick={() => handleCSVDownload({ data: getDataByColumns(barges) })}
        Icon={DownloadOutlined}
      />
      <CopyDataButton getData={bargeNamesCallback} successText="Copied barge names ✔" tooltipText="Copy Barge Names" />
    </header>
  )
}

export const Nomination = ({ barges, hasReviews }: Props) => {
  const [groupBy, setGroupBy] = useState<GroupByType>(GroupByType.None)
  const [sortBy, setSortBy] = useState<SortByTypeN>(SortByTypeN.None)
  const [sortOrder, setSortOrder] = useState<SortOrderType>(SortOrderType.Ascending)

  return (
    <div className={classnames(styles.nomination, { [styles.hasReviews]: hasReviews })}>
      <Header
        barges={barges}
        onGroupByChange={setGroupBy}
        onSortByChange={setSortBy}
        onSortOrderChange={setSortOrder}
        groupBy={groupBy}
        sortBy={sortBy}
        sortOrder={sortOrder}
        sortOrderKeys={sortOrderKeys}
        sortByKeys={sortByKeysN}
      />
      <NominatedBargesTable
        hasReviews={hasReviews}
        barges={barges}
        theme={theme}
        groupBy={groupBy}
        sortBy={sortBy}
        sortOrder={sortOrder}
      />
    </div>
  )
}

type TowActionButtonProps = {
  label: string
  className?: string | undefined
  Icon: SvgIconComponent
  onClick: () => void
}
const TowActionButton = ({ label, onClick, Icon, className }: TowActionButtonProps) => (
  <Tooltip title={label} placement="top">
    <button className={classnames(styles.towActionButton, className)} type="button" onClick={onClick}>
      <span className="sr-only">{label}</span>
      <Icon />
    </button>
  </Tooltip>
)
