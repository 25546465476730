import React from 'react'

import { GroupByType, SortByTypeN, SortOrderType } from '../../ui/Table/TableColumnConfig'
import Selector from '../Header/Selector/Selector'

import styles from './Nomination.module.scss'

type GroupBySelectorProps = {
  groupBy: GroupByType
  groupByKeys: GroupByType[]
  handleGroupByChange: (key: GroupByType) => void
  sortBy: SortByTypeN
  sortByKeys: SortByTypeN[]
  handleSortByChange: (key: SortByTypeN) => void
  sortOrder: SortOrderType
  sortOrderKeys: SortOrderType[]
  handleSortOrderChange: (order: SortOrderType) => void
}

const GroupBySelector: React.FC<GroupBySelectorProps> = ({
  groupBy,
  groupByKeys,
  handleGroupByChange,
  sortBy,
  sortByKeys,
  handleSortByChange,
  sortOrder,
  sortOrderKeys,
  handleSortOrderChange,
}) => {
  
  return (
    <div className={styles.selectors}>
        <Selector
          label="Group by"
          currentSelection={groupBy}
          options={groupByKeys}
          onChange={handleGroupByChange}
          tooltipText="Grouping table columns"
        />
        <Selector
          label="Sort by"
          currentSelection={sortBy}
          options={sortByKeys}
          onChange={handleSortByChange}
          tooltipText="Select sorting criteria"
        />
        <Selector
          label="Sort order"
          currentSelection={sortOrder}
          options={sortOrderKeys}
          onChange={handleSortOrderChange}
          tooltipText="Select sorting order"
        />
    </div>
  )
}

export default GroupBySelector
