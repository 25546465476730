import React from 'react'

import { HighlightOff } from '@mui/icons-material'
import classnames from 'classnames'

import styles from './styles.module.scss'

type MaxDraftFormProps = {
  feet: number | null
  inch: number | null
  handleFeetReset: () => void
  handleInchReset: () => void
  onChange: (feet: number | null, inch: number | null) => void
  readOnly?: boolean
}

const MaxDraftForm: React.FC<MaxDraftFormProps> = ({
  feet,
  inch,
  onChange,
  handleFeetReset,
  handleInchReset,
  readOnly = false,
}) => {
  const handleFeetChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.valueAsNumber
    onChange(Number.isNaN(value) ? null : value, inch)
  }

  const handleInchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.valueAsNumber
    onChange(feet, Number.isNaN(value) ? null : value)
  }

  const handleFeetBlur = () => {
    if (feet === 0) {
      onChange(null, inch)
    }
  }

  const handleInchBlur = () => {
    if (inch === 0) {
      onChange(feet, null)
    }
  }

  return (
    <div className={styles.formRow}>
      <label className={styles.label}>Draft</label>
      <div className={styles.draught}>
        <div className={styles.draughtEntry}>
          <span className={styles.label}>Feet</span>
          <input
            type="number"
            value={feet ?? ''}
            min={0}
            onChange={handleFeetChange}
            onBlur={handleFeetBlur}
            placeholder="0"
            className={classnames(styles.nr, { [styles.readOnly]: readOnly })}
            readOnly={readOnly}
          />
          {!readOnly && feet !== null && (
            <HighlightOff
              className={classnames(styles.clear, { [styles.isDisabled]: feet === 0 })}
              onClick={e => {
                e.stopPropagation()
                if (feet !== 0) handleFeetReset()
              }}
            />
          )}
        </div>
        <div className={styles.draughtEntry}>
          <span className={styles.label}>Inch</span>
          <input
            type="number"
            value={inch ?? ''}
            min={0}
            max={11}
            onChange={handleInchChange}
            onBlur={handleInchBlur}
            placeholder="0"
            className={classnames(styles.nr, { [styles.readOnly]: readOnly })}
            readOnly={readOnly}
          />
          {!readOnly && inch !== null && (
            <HighlightOff
              className={classnames(styles.clear, { [styles.isDisabled]: inch === 0 })}
              onClick={e => {
                e.stopPropagation()
                if (inch !== 0) handleInchReset()
              }}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default MaxDraftForm
