import React from 'react'

import { CloseIcon } from '@graphiql/react'
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from 'formik'
import * as Yup from 'yup'

import styles from './LaneFilters.module.scss'

type IncludeTBOProps = {
    tboNumbers: number[]
    onAddTbo: (number: number) => void
    onRemoveTbo: (number: number) => void
    readOnly: boolean
    isDisabled: boolean
}

const initialValuesForm = {
    tboInput: '',
}

const validationSchema = Yup.object().shape({
    tboInput: Yup.number()
        .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value))
        .nullable()
        .positive('TBO number must be positive')
        .integer('TBO number must be an integer')
        .notOneOf([], 'TBO number already exists'),
})


const IncludeTBO: React.FC<IncludeTBOProps> = ({ tboNumbers, onAddTbo, onRemoveTbo, readOnly, isDisabled }) => {

    const handleKeyDown = (
        e: React.KeyboardEvent<HTMLInputElement>,
        setFieldTouched: (field: string, touched: boolean) => void
    ) => {
        if (e.key === 'Enter') {
            setFieldTouched('tboInput', true)
        }
    }

    const handleAddTbo = (
        values: { tboInput: string },
        { setFieldValue, setTouched }: FormikHelpers<{ tboInput: string }>
    ) => {
        const number = parseFloat(values.tboInput)
        if (!Number.isNaN(number)) {
            onAddTbo(number)
            setFieldValue('tboInput', '', false)
            setTouched({ tboInput: false })
        }
    }

    const handleRemoveTbo = (number: number) => {
        onRemoveTbo(number)
    }

    return (
        <Formik initialValues={initialValuesForm} validationSchema={validationSchema} onSubmit={handleAddTbo}>
            {({ setFieldTouched }) => (
                <Form className={styles.includeTboContainer}>
                    <div className={styles.inputContainer}>
                        <Field
                            type="text"
                            name="tboInput"
                            placeholder="Enter TBO number"
                            className={`${styles.tboInput} ${readOnly || isDisabled ? styles.disabledInput : ''}`}
                            disabled={readOnly || isDisabled}
                            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => handleKeyDown(e, setFieldTouched)}
                        />
                        <button
                            type="submit"
                            className={`${styles.addButton} ${readOnly || isDisabled ? styles.disabledButton : ''}`}
                            disabled={readOnly || isDisabled}>
                            Add
                        </button>
                    </div>
                    <ErrorMessage name="tboInput" component="div" className={styles.errorMessage} />
                    <div className={styles.tboList}>
                        {tboNumbers.length > 0 ? (
                            tboNumbers.map((number, index) => (
                                <div key={index} className={`${styles.tboItem} ${readOnly ? styles.disabledInput : ''}`}>
                                    {number}
                                    <button
                                        onClick={() => handleRemoveTbo(number)}
                                        className={styles.removeButton}
                                        disabled={readOnly || isDisabled}>
                                        <CloseIcon/>
                                    </button>
                                </div>
                            ))
                        ) : (
                            <p className={styles.noTbo}>No TBO numbers added yet.</p>
                        )}
                    </div>
                </Form>
            )}
        </Formik>
    )
}

export default IncludeTBO
