import { PropsWithChildren } from 'react'

import { Popover as P } from '@headlessui/react'
import classnames from 'classnames'

import { DropoutMenuDropper, Placement } from '../DropoutMenu/DropoutMenuDropper'

import styles from './Popover.module.scss'

type Theme = Partial<{
  popover?: string
  panelWrapper: string
  panel?: string
}>

type Props = PropsWithChildren<{
  button: JSX.Element
  theme?: Theme
  placement: Placement
}>

export const Popover = ({ children, button, theme, placement }: Props) => (
  <P className={classnames(styles.popover, theme?.popover)}>
    <P.Button>{button}</P.Button>
    <DropoutMenuDropper
      placement={placement}
      triangleOffsetRight={placement.endsWith('-end') ? '1rem' : undefined}
      className={theme?.panelWrapper}>
      <div className={classnames(styles.panel, theme?.panel)}>{children}</div>
    </DropoutMenuDropper>
  </P>
)
