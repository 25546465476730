import { CSSProperties, PropsWithChildren } from 'react'

import { Link, SportsScore } from '@mui/icons-material'

import styles from './TableLegend.module.scss'

export const TableLegendItem = ({
  children,
  text,
  style,
}: PropsWithChildren<{ text: string; style?: CSSProperties }>) => (
  <div className={styles.legendItem} style={style}>
    {children}
    <div className={styles.legendItemText}>{text}</div>
  </div>
)

export const HotBargeLegendItem = () => (
  <TableLegendItem text="Hot Barge" style={{ '--r': 230, '--g': 0, '--b': 126 } as CSSProperties}>
    <div className={styles.legendItemIcon} />
  </TableLegendItem>
)

export const DerivedLegendItem = () => (
  <TableLegendItem text="Derived Info" style={{ '--r': 191, '--g': 197, '--b': 200 } as CSSProperties}>
    <div className={styles.legendItemIcon} />
  </TableLegendItem>
)

export const TableLegend = ({ hasDerivedInfo = false }: { hasDerivedInfo?: boolean }) => (
  <div className={styles.legend}>
    <HotBargeLegendItem />
    <TableLegendItem text="Grouped Barge">
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <Link />
    </TableLegendItem>
    <TableLegendItem text="Next Stop Drop-off">
      <SportsScore />
    </TableLegendItem>
    {hasDerivedInfo ? <DerivedLegendItem /> : null}
  </div>
)
