import { useEffect, useState, useRef, PropsWithChildren } from 'react'

import classnames from 'classnames'

import { isNumeric } from '../../utils/number'

import styles from './NumberInput.module.scss'

export type Theme = {
  container?: string
}

export type Props = {
  input: Omit<JSX.IntrinsicElements['input'], 'type' | 'onInput'>
  theme?: Theme
  className?: string
  handleInput: (value: number) => void
}

const Container: React.FC<PropsWithChildren<{ className?: string }>> = ({ className, children }) => (
  <div className={classnames(styles.container, className)}>{children}</div>
)

export const defaultNumberedTheme: Theme = {
  container: classnames(styles.defaultContainer, styles.defaultNumberedContainer),
}

type NumberedInputProps = Omit<Props, 'input'> & {
  input: Omit<JSX.IntrinsicElements['input'], 'type' | 'onInput' | 'min' | 'max'>
  min: number
  max: number
  placeholder?: string
  size?: number
}

const DELAY = 300

export function NumberedInput({ handleInput, theme, input, min, max, placeholder, size }: NumberedInputProps) {
  const { value } = input
  const delayRef = useRef<NodeJS.Timeout>()
  const [inputValue, setInputValue] = useState(value)

  useEffect(() => {
    setInputValue(value)
  }, [value])

  return (
    <Container className={theme?.container}>
      <div className={classnames(styles.numberedRange, { [styles.isDisabled]: input.disabled })}>
        <input
          {...input}
          size={size}
          min={min}
          max={max}
          placeholder={placeholder}
          type="number"
          className={styles.numberedInput}
          onChange={({ target: { value: currentValue } }) => {
            setInputValue(currentValue)

            if (delayRef.current) {
              clearTimeout(delayRef.current)
            }

            if (isNumeric(currentValue)) {
              const val = Number(currentValue)

              if (val >= min && val <= max) {
                delayRef.current = setTimeout(() => handleInput(val), DELAY)
              }
            }
          }}
          value={inputValue}
          onBlur={() => setInputValue(val => (typeof val === 'number' && val >= min && val <= max ? val : value))}
        />
        <span className={styles.number}>
          ({min} - {max})
        </span>
      </div>
    </Container>
  )
}
