import React, { useState } from 'react'

import { Dialog } from '@headlessui/react'
import { Close } from '@mui/icons-material'
import classnames from 'classnames'

import { Modal } from './Modal'
import styles from './Modal.module.scss'

type Props = {
  onClose: () => void
  title: string
  body: React.ReactNode
  isOpen: boolean
  withCloseButton?: boolean
  className?: string
}

type CancelableActionModalProps = Props & { primaryButton: JSX.Element }

const CancelableActionModal = ({
  isOpen,
  onClose,
  title,
  body,
  withCloseButton,
  primaryButton,
  className,
}: CancelableActionModalProps) => (
  <Modal className={className} isOpen={isOpen} onClose={onClose}>
    {withCloseButton && (
      <button onClick={onClose} className={styles.closeButton}>
        <Close />
      </button>
    )}
    <div className={styles.content}>
      <Dialog.Title className={styles.title}>{title}</Dialog.Title>
      <Dialog.Description as="div" className={styles.text}>
        {body}
      </Dialog.Description>
    </div>
    <div className={styles.footer}>
      <button onClick={onClose} className={styles.button}>
        Cancel
      </button>
      {primaryButton}
    </div>
  </Modal>
)

type AsyncActionModalProps = Props & { onAction: () => Promise<unknown> }
export function AsyncActionModal(props: AsyncActionModalProps) {
  const [isLoading, setIsLoading] = useState(false)

  return (
    <CancelableActionModal
      {...props}
      primaryButton={
        <button
          className={classnames(styles.button, styles.primary, { [styles.isLoading]: isLoading })}
          onClick={
            isLoading
              ? undefined
              : () => {
                  setIsLoading(true)
                  props.onAction().then(() => {
                    setIsLoading(false)
                    props.onClose()
                  })
                }
          }>
          {isLoading ? 'Running' : 'Ok'}
        </button>
      }
    />
  )
}
