import { useEffect } from 'react'

import { Error as ErrorIcon, CheckCircle } from '@mui/icons-material'
import toast from 'react-hot-toast'

import styles from './Toast.module.scss'

enum NotificationType {
  Error = 'Error',
  Success = 'Success',
}

type Props = { message: string; type: NotificationType; onClose: () => void }

function ToastIcon({ type }: { type: NotificationType }) {
  switch (type) {
    case NotificationType.Error:
      return <ErrorIcon className={styles.errorIcon} />
    case NotificationType.Success:
      return <CheckCircle className={styles.successIcon} />

    default:
      return null
  }
}

const Toast = ({ message, type, onClose }: Props) => {
  useEffect(() => {
    const timer = setTimeout(onClose, 4000)

    return () => clearTimeout(timer)
  }, [onClose])

  return (
    <div className={styles.toast}>
      <ToastIcon type={type} />
      <div className={styles.toastContent}>
        <span className={styles.toastType}>{NotificationType[type]}</span>
        <span>{message}</span>
      </div>
    </div>
  )
}

export function errorToast(message: string) {
  const toastId = toast.custom(
    <Toast type={NotificationType.Error} onClose={() => toast.remove(toastId)} message={message} />
  )
}

export function successToast(message: string) {
  const toastId = toast.custom(
    <Toast type={NotificationType.Success} onClose={() => toast.remove(toastId)} message={message} />
  )
}
