import { Link } from 'wouter'

import { docsPagePath } from '../../paths'

import { P } from './Components/Components'

export const FleetEfficiencyDoc = () => (
  <>
    <h1>Fleet Efficiency</h1>
    <h2 data-section="overview">Overview</h2>
    <h3>Goal</h3>
    <P>Create a nomination list that can be more efficiently assembled within fleet hubs</P>
    <h3>Description</h3>
    <P>
      This goal primarily concerns how to choose between barges at or soon to be at a fleet hub; this hub is then
      intended as the origin of a new tow. The general premise to minimize the amount of tug time and cost needed to
      build tows. At barge-level, we can think of this as nominating barges that are more easily accessible and require
      fewer touches.
    </P>
    <h2 data-section="goal-profile">Goal profile</h2>
    <h3>Example</h3>
    <P>
      Barges can often be located at facilities very near an Ingram hub, and these need to be brought into the hub for
      consolidating and building into tows. This is typically done by fleet tugs working at the hub, and this requires
      the tug travel to the external facility, wait to make the pick up for a barge, and then travel back to the hub.
      Under the Fleet Efficiency goal we want to keep the amount of barges located nearby but outside the fleet to a
      minimum because these will require using up lots of time for a fleet tug.
    </P>
    <h3>Comparisons to other goals</h3>
    <h4>...compared to Linehaul Turn Time</h4>
    <P>
      <ul>
        <li>
          Fleet Efficiency does <i>not</i> use <i>any</i> time values in optimization... Linehaul Turn Time uses time
          window deadlines
        </li>
        <li>
          Fleet Efficiency focuses on barges in fleet within an origin hub... Linehaul Turn Time focuses on the risks
          from barges on route to or in proximity of an origin hub
        </li>
        <li>
          Fleet Efficiency has a secondary focus on grouping barges by final destination... Linehaul Turn Time gives
          only a minor focus toward this policy
        </li>
      </ul>
    </P>
    <h4>
      ...compared to <Link href={docsPagePath('build-to-destination')}>Build to Destination</Link>
    </h4>
    <P>
      <ul>
        <li>
          Fleet Efficiency has a primary focus on LIFO selection for barges in fleet... Build to Destination has a minor
          focus on FIFO selection
        </li>
        <li>
          Fleet Efficiency has a secondary focus on grouping barges by final destination... Build to Destination makes
          this policy its primary focus
        </li>
      </ul>
    </P>
    <h2 data-section="policies">Policies</h2>
    <P>
      The nomination policies that are used within the Fleet Efficiency goal are list below. In the{' '}
      <Link href={`${docsPagePath('nomination-policies')}/s/policy-details`}>Policy details</Link> section of the
      Nomination Policies page you can find a bit more information on each policy, such as the specific metric used and
      the way that metric is used in the optimization.
    </P>
    <h3 data-section="core-policies">Core policies</h3>
    <h4>Primary</h4>
    <P>
      <ul>
        <li>
          <b>LIFO (Last in–first out)</b> a.k.a. <b>Time in fleet (min)</b> – The most recent barges to enter an origin
          hub fleet are more likely to be easily accessible for building a tow
        </li>
        <li>
          <b>Distance to origin</b> – Barges whose current location is closer to an origin hub point are typically
          easier to efficiently gather for building a tow
        </li>
      </ul>
    </P>
    <h4>Secondary</h4>
    <P>
      <ul>
        <li>
          <b>Least buried barges</b> – Barges whose buried indicator is low (or, nomination lists of barges whose buried
          indicator is low) are more accessible for building a tow
        </li>
        <li>
          <b>Final destinations (min)</b> – Keeping the number of final destinations in a list to a lower number makes
          it more likely the engine will prefer to nominate barges that can travel together for the rest of their
          remaining trip to destination
        </li>
      </ul>
    </P>
    <h3 data-section="other-policies">Other policies</h3>
    <h4>Minor</h4>
    <P>
      <ul>
        <li>
          <b>Stopping cost</b> – Nomination lists should reduce stopping costs during a route from pickups and
          drop-offs. Every list will have at least <i>two</i> stops (tow origin and tow destination), but every
          additional stop that is contained within a list incurs some kind of additional cost.
        </li>
        <li>
          <b>Maximize barge-miles</b> – All else being equal, nomination lists should transport more barges more miles
        </li>
      </ul>
    </P>
    <h4>General</h4>
    <P>
      <ul>
        <li>
          <b>Rake evenness</b> – All else being equal, a nomination list with an even number of rakes is preferable to a
          list with an odd number because it is easier to assemble a hydrodynamic tow when there are even number of
          rakes to use.
        </li>
        <li>
          <b>Rake–box balance</b> – All else being equal, the more balanced the number of rakes and the number of boxes
          in a list, the better.
        </li>
      </ul>
    </P>
    <h2 data-section="advanced">Advanced points</h2>
    <P>
      In the current implementation, the primary policy for Fleet Efficiency is <i>LIFO (Last in–First out)</i>. Why
      this policy chosen in particular is due to a few subtle reasons:
      <ol>
        <li>Recently arrived barges to a fleet hub (last in) should usually be more accessible by tugs.</li>
        <li>
          Barges that recently arrived together in a tow are more likely to still be coupled together with similar
          barges
        </li>
        <li>
          Barges that recently arrived together in a tow will share a similar time in fleet metric, therefore they
          naturally "grouped" together by proxy by the metric
        </li>
        <li>
          The barge buried indicator is very sensitive to the geometric position of individual barges within a fleet,
          which can be misleading to the engine.
          <P>
            As an example, since barges can be linked together in strings and placed in a fleet, the barges at the head
            of the string will get a much higher buried indicator than the one at the stern of the string, even though,
            in reality, it can be just as easy to access all of them if you maintain them in a string.
          </P>
        </li>
      </ol>
    </P>
  </>
)
