import classnames from 'classnames'

import { isAcceptanceEnv } from '../../constants/env-vars'

import styles from './Ribbon.module.scss'

export function Ribbon({ className }: { className?: string }) {
  const text = isAcceptanceEnv ? 'UAT UAT UAT UAT UAT UAT UAT' : 'TEST TEST TEST TEST TEST TEST'

  return <div data-ribbon={text} className={classnames(styles.ribbon, className)} />
}

export const RightBottomRibbon = () => <Ribbon className={styles['right-bottom']} />
