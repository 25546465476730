import React from 'react'

import {
    bargeGroupByKeys,
    BargeGroupByType,
    sortByKeys,
    SortByType,
    sortOrderKeys,
    SortOrderType,
} from '../../ui/Table/TableColumnConfig'
import Selector from '../Header/Selector/Selector'

import styles from './BargeListing.module.scss'

type BargeGroupSortControlsProps = {
    groupBy: BargeGroupByType;
    setGroupBy: (groupBy: BargeGroupByType) => void;
    sortBy: SortByType;
    setSortBy: (sortBy: SortByType) => void;
    sortOrder: SortOrderType;
    setSortOrder: (sortOrder: SortOrderType) => void;
    hiddenColumns: string[];
}

export const BargeGroupSortControls: React.FC<BargeGroupSortControlsProps> = ({
    groupBy,
    setGroupBy,
    sortBy,
    setSortBy,
    sortOrder,
    setSortOrder,
    hiddenColumns,
}) => {
    const filteredSortByOptions = sortByKeys.filter(option => !hiddenColumns.includes(option)) as SortByType[]

    return (
        <div className={styles.selectors}>
            <Selector
                label="Group by"
                currentSelection={groupBy}
                options={bargeGroupByKeys}
                onChange={setGroupBy} 
                tooltipText="Select grouping criteria"
            />
            <Selector
                label="Sort by"
                currentSelection={sortBy}
                options={filteredSortByOptions}
                onChange={setSortBy}  
                tooltipText="Select sorting criteria"
            />
            <Selector
                label="Sort order"
                currentSelection={sortOrder}
                options={sortOrderKeys}
                onChange={setSortOrder}  
                tooltipText="Select sorting order"
            />
        </div>
    )
}
