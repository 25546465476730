import React, { FC } from 'react'

type VesselSelectionSummaryProps = {
  description: string
}

const VesselSelectionSummary: FC<VesselSelectionSummaryProps> = ({ description }) => {
  return <div>{description}</div>
}

export default VesselSelectionSummary
