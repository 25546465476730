import { PropsWithChildren, ReactNode } from 'react'

import { Edit, ReportProblem } from '@mui/icons-material'
import classnames from 'classnames'

import styles from './Admonition.module.scss'

type AdProps = PropsWithChildren<{ title: ReactNode; className?: string }>

const Admonition = ({ title, className, children }: AdProps) => (
  <blockquote className={classnames(styles.ad, className)}>
    <h3>{title}</h3>
    <p>{children}</p>
  </blockquote>
)

export const AdNote = (props: AdProps) => (
  <Admonition
    {...props}
    className={styles.note}
    title={
      <>
        <Edit />
        <span>{props.title}</span>
      </>
    }
  />
)

export const AdWarning = (props: AdProps) => (
  <Admonition
    {...props}
    className={styles.warning}
    title={
      <>
        <ReportProblem />
        <span>{props.title}</span>
      </>
    }
  />
)
