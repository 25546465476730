import { PropsWithChildren } from 'react'

import classnames from 'classnames'

import styles from './LaneFilters.module.scss'

export const Column = ({ children }: PropsWithChildren<{}>) => <div className={styles.column}>{children}</div>

export const ColumnTitle = ({ title }: { title: string }) => <div className={styles.columnTitle}>{title}</div>

export const ColumnEntry = ({
  children,
  columnClassnames = [],
}: PropsWithChildren<{}> & { columnClassnames?: string[] }) => (
  <div className={classnames(styles.columnEntry, ...columnClassnames)}>{children}</div>
)