import { createContext, PropsWithChildren, useContext, useMemo } from 'react'

import { parseDate } from '../Domain/Barge'
import { UserBargeNomination } from '../Domain/Nomination'
import { HubLikeId, LaneId, SavedBargeNominationsQuery, useSavedBargeNominationsQuery } from '../generated/graphql'
import { LoadingSpinner } from '../ui/Spinner/Spinner'

export type State = {
  groupedNominations: Record<string, UserBargeNomination[]>
  nominations: { uuid: string; lane: LaneId; origin: HubLikeId; destination: HubLikeId }[]
}

export const Context = createContext({} as State)

export const useSavedNominationsContext = () => useContext(Context)

function sortByRecordTime(a: { recordTime: Date }, b: { recordTime: Date }) {
  const aTime = a.recordTime.getTime()
  const bTime = b.recordTime.getTime()

  if (aTime === bTime) return 0
  return aTime > bTime ? -1 : 1
}

const groupNominations = (nominations: SavedBargeNominationsQuery['savedBargeNominations']) =>
  nominations.reduce((acc: Record<string, UserBargeNomination[]>, { nomination }) => {
    const { lane, towOrigin, towDestination } = nomination.userRequest.bargeFilters

    const key = `${lane}:${towOrigin}:${towDestination}`

    acc[key] = acc[key] || []
    acc[key].push({ ...nomination, recordTime: parseDate(nomination.recordTime) })
    acc[key].sort(sortByRecordTime)

    return acc
  }, {})

export function SavedNominationsProvider({ children }: PropsWithChildren) {
  const [{ fetching: loading, data }] = useSavedBargeNominationsQuery({ requestPolicy: 'network-only' })

  const groupedNominations = useMemo(() => groupNominations(data?.savedBargeNominations ?? []), [data])

  if (loading || data === undefined) {
    return <LoadingSpinner isFullScreen />
  }

  const state: State = {
    groupedNominations,
    nominations: Object.values(groupedNominations)
      .map(gr => {
        const {
          uuid,
          recordTime,
          userRequest: {
            bargeFilters: { lane, towOrigin, towDestination },
          },
        } = gr[0]

        return { uuid, recordTime, lane, origin: towOrigin, destination: towDestination }
      })
      .flat()
      .sort(sortByRecordTime),
  }

  return <Context.Provider value={state}>{children}</Context.Provider>
}
