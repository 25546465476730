import { useEffect, useState } from 'react'

import { navigate, useLocationProperty } from 'wouter/use-location'

import { RiverLocationLite } from '../../../generated/graphql'

export const setRiverCodeParam = (loc: RiverLocationLite | null) =>
  navigate(loc ? `${window.location.pathname}?mlp=${loc.code}${loc.mileagePoint}` : window.location.pathname)

const riverCodeParamRegex = /^([A-Z]+)([0-9]+\.[0-9]|[0-9]+)$/

function parseRiverCodeParam(param: string) {
  const match = param.match(riverCodeParamRegex)

  if (match) {
    const riverCode: RiverLocationLite = { code: match[1], mileagePoint: parseFloat(match[2]) }
    return riverCode
  }

  return undefined
}

export function useMilePointSelection() {
  const param = useLocationProperty(() => new URLSearchParams(window.location.search).get('mlp'))

  const [milePoint, setMilePoint] = useState<RiverLocationLite>()

  useEffect(() => {
    setMilePoint(param ? parseRiverCodeParam(param) : undefined)
  }, [param])

  return { milePoint }
}
