import { PropsWithChildren } from 'react'

import { Form as FormikForm } from 'formik'

import styles from './Account.module.scss'

export const Form: React.FC<PropsWithChildren> = ({ children }) => (
  <FormikForm className={styles.form}>{children}</FormikForm>
)

type ErrorNotificationProps = { message: string }
export const ErrorNotification = ({ message }: ErrorNotificationProps) => (
  <div className={styles.errorNotification}>
    <span className={styles.note}>*</span>
    <span>{message}</span>
  </div>
)
