import { useState } from 'react'

import { Popover, Transition } from '@headlessui/react'
import { DragHandle, SettingsBackupRestore, ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import Tooltip from '@mui/material/Tooltip/Tooltip'
import classNames from 'classnames'
import { ReactSortable } from 'react-sortablejs'

import { Checkbox } from '../Checkbox/Checkbox'

import styles from './TableColumnConfig.module.scss'

type Config = {
  hidden: string[]
  columns: Array<{ id: string; name: string; defaultOrder: number }>
}

type TableColumnConfigProps = {
  config: Config
  defaultColumns: Config['columns']
  defaultHidden: string[]
  handleApply: (config: Config) => void
}

export enum GroupByType {
  DropOffFacility = 'Drop-off',
  PickupFacility = 'Pickup',
  CurrentLocation = 'Current Location',
  Destination = 'Destination',
  BargeType = 'Type',
  PickupType = 'Pickup Type',
  HullType = 'Hull Type',
  TripStatus = 'Trip Status',
  CurrentLocationAndFleet = 'Fleet',
  None = 'None',
}

export const groupByKeys: Array<GroupByType> = [
  GroupByType.DropOffFacility,
  GroupByType.PickupFacility,
  GroupByType.CurrentLocation,
  GroupByType.Destination,
  GroupByType.BargeType,
  GroupByType.PickupType,
  GroupByType.HullType,
  GroupByType.TripStatus,
  GroupByType.CurrentLocationAndFleet,
  GroupByType.None,
]

export enum SortByTypeN {
  Name = 'Name',
  Cargo = 'Cargo',
  HullType = 'Hull Type',
  Type = 'Type',
  PickupType = 'Pickup Type',
  TripStatus = 'Trip Status',
  Destination = 'Destination',
  Pickup = 'PickUp',
  DropOff = 'Drop Off',
  CurrentLocation = 'Current Location',
  Fleet = 'Fleet',
  IsAvailable = 'Available',
  TboInfo = 'TBO Info',
  IsAtRisk = 'At Risk',
  None = 'None',
}

export const sortByKeysN: Array<SortByTypeN> = [
  SortByTypeN.Name,
  SortByTypeN.Cargo,
  SortByTypeN.HullType,
  SortByTypeN.Type,
  SortByTypeN.PickupType,
  SortByTypeN.TripStatus,
  SortByTypeN.Destination,
  SortByTypeN.Pickup,
  SortByTypeN.DropOff,
  SortByTypeN.CurrentLocation,
  SortByTypeN.Fleet,
  SortByTypeN.IsAvailable,
  SortByTypeN.TboInfo,
  SortByTypeN.IsAtRisk,
  SortByTypeN.None,
]

export enum BargeGroupByType {
  None = 'None',
  Custody = 'Custody',
}

export const bargeGroupByKeys: Array<BargeGroupByType> = [BargeGroupByType.None, BargeGroupByType.Custody]

export enum SortByType {
  Name = 'Name',
  Cargo = 'Cargo',
  Origin = 'Origin',
  Destination = 'Destination',
  BoatName = 'Boat Name',
  NextDropOff = 'Next Drop-Off',
  DaysInFleet = 'Days in Fleet',
  Type = 'Type',
  Hot = 'Hot',
  HullType = 'Hull Type',
  TripStart = 'Trip Start',
  CurrentLocation = 'Current Location',
  CurrentCustody = 'Current Custody',
  FleetName = 'Fleet Name',
  PickupType = 'Pickup Type',
  DropOffFacility = 'Drop-Off Facility',
  Status = 'Status',
  TripStatus = 'Trip Status',
  ActualDraft = 'Actual Draft',
  IsAtRisk = 'Risk Level',
  TboInfo = 'Latest TBO',
  TboDropOffFacility = 'Latest TBO Drop-Off',
  TripStatusTime = 'Trip Status Time',
  PickupFacility = 'Pickup Facility',
  None = 'None',
}

export const sortByKeys: Array<SortByType> = [
  SortByType.Name,
  SortByType.Cargo,
  SortByType.Origin,
  SortByType.Destination,
  SortByType.BoatName,
  SortByType.NextDropOff,
  SortByType.DaysInFleet,
  SortByType.Type,
  SortByType.Hot,
  SortByType.HullType,
  SortByType.TripStart,
  SortByType.CurrentLocation,
  SortByType.CurrentCustody,
  SortByType.IsAtRisk,
  SortByType.FleetName,
  SortByType.PickupType,
  SortByType.DropOffFacility,
  SortByType.Status,
  SortByType.TripStatus,
  SortByType.ActualDraft,
  SortByType.TboInfo,
  SortByType.TboDropOffFacility,
  SortByType.TripStatusTime,
  SortByType.PickupFacility,
  SortByType.None,
]

export enum SortOrderType {
  Ascending = 'ASC',
  Descending = 'DESC',
}

export const sortOrderKeys: Array<SortOrderType> = [SortOrderType.Ascending, SortOrderType.Descending]

export enum ColorByType {
  Hot = 'Hot',
  Empty = 'Empty',
  RiverBasin = 'River Basin',
  None = 'None',
}

export const colorByOptions: ColorByType[] = [
  ColorByType.Hot,
  ColorByType.Empty,
  ColorByType.RiverBasin,
  ColorByType.None,
]

export const getColorClass = (colorType: ColorByType): string => {
  return `color-${colorType.replace(' ', '')}`
}

export function TableColumnConfig({ config, defaultHidden, defaultColumns, handleApply }: TableColumnConfigProps) {
  const [list, setList] = useState(config.columns)
  const [hidden, setHidden] = useState<string[]>(config.hidden)

  return (
    <Popover>
      {({ open, close }) => (
        <Tooltip title="Toggle & Reorder Columns" placement="top">
          <div className={styles.container}>
            <Popover.Button className={styles.button}>
              <span>Edit Table</span>
              {open ? <ArrowDropUp /> : <ArrowDropDown />}
            </Popover.Button>
            <Transition show={open}>
              <Popover.Panel className={classNames(styles.panel, { [styles.open]: open })}>
                <h2 className={styles.heading}>Toggle & Reorder Columns</h2>
                <ReactSortable
                  tag="ol"
                  className={styles.list}
                  list={list}
                  setList={xs => {
                    setList(
                      xs
                        .map((x, index) => ({ ...x, defaultOrder: index }))
                        .sort((a, b) => {
                          if (a.defaultOrder === b.defaultOrder) return 0
                          return a.defaultOrder > b.defaultOrder ? 1 : -1
                        })
                    )
                  }}
                  handle={`.${styles.dragHandle}`}>
                  {list.map(column => (
                    <li key={column.id} className={styles.item}>
                      <span className={styles.itemText}>
                        <Checkbox
                          label={column.name}
                          checked={!hidden.includes(column.id)}
                          onChange={isVisible => {
                            setHidden(hidden.filter(id => id !== column.id).concat(isVisible ? [] : [column.id]))
                          }}
                        />
                      </span>
                      <button type="button" className={styles.dragHandle}>
                        <span className="sr-only">Drag to reorder columns</span>
                        <DragHandle />
                      </button>
                    </li>
                  ))}
                </ReactSortable>
                <button
                  type="button"
                  className={styles.applyBtn}
                  onClick={() => {
                    handleApply({ columns: list, hidden })
                    close()
                  }}>
                  Apply
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setList(defaultColumns)
                    setHidden(defaultHidden)
                  }}
                  className={styles.resetColumnOrderBtn}>
                  Reset Columns
                  <SettingsBackupRestore />
                </button>
              </Popover.Panel>
            </Transition>
          </div>
        </Tooltip>
      )}
    </Popover>
  )
}
