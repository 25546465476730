import { MouseEvent, useCallback, useEffect, useState } from 'react'

import { KeyboardDoubleArrowUp } from '@mui/icons-material'
import { Tooltip } from '@mui/material'

import styles from './ScrollToTop.module.scss'

export function ScrollToTopButton({ byId }: { byId: string }) {
  const [isDisabled, setIsDisabled] = useState(true)

  useEffect(() => {
    const el = document.getElementById(byId)

    function onScroll() {
      setIsDisabled((el?.scrollTop ?? 0) <= 0)
    }

    el?.addEventListener('scroll', onScroll)

    return () => {
      el?.removeEventListener('scroll', onScroll)
    }
  }, [byId])

  const handleClickCallback = useCallback(
    (e: MouseEvent) => {
      e.preventDefault()

      const el = document.getElementById(byId)

      if (el) {
        el.scrollTo({ top: 0, behavior: 'smooth' })
      }
    },
    [byId]
  )

  return (
    <div>
      <button disabled={isDisabled} onClick={handleClickCallback} className={styles.button}>
        <Tooltip title="Back to top">
          <KeyboardDoubleArrowUp />
        </Tooltip>
      </button>
    </div>
  )
}
