import { Link } from 'wouter'

import { docsPagePath } from '../../paths'

import { AdNote } from './Admonition/Admonintion'
import { P } from './Components/Components'

export const TowCompositionRulesDoc = () => (
  <>
    <h1>Tow-composition rules</h1>
    <h2 data-section="overview">Overview</h2>
    <P>
      The tow-composition rules capture certain "best practices" for making a safe and hydrodynamic tow. The rules
      derive from the physics for actual configurations of barges, but the lessons from the actual physics also
      generalize to impact how a planner should nominate a list of barges for a tow.
    </P>
    <h3>Terminology</h3>
    <P>
      Regarding the "strength" of the rules:
      <ul>
        <li>
          <b>
            <i>Soft</i>
          </b>{' '}
          rules are recommendations for a tow's composition that hold in an ideal situation, but in reality conditions
          may frequently lead to violating them.
        </li>
        <li>
          <b>
            <i>Medium</i>
          </b>{' '}
          rules are rules that should hold in ~70-80% of conditions.
        </li>
        <li>
          <b>
            <i>Hard</i>
          </b>{' '}
          rules are rules that should hold in ~90-95% of conditions.
        </li>
      </ul>
      For all three cases, the rules are more likely to be violated if there are not many candidate barges in the pool
      to nominate from for a given route. Some of the hard rules are also implemented as logical requirements for the
      engine, so if they cannot be satisfied with barges from a given pool, then the engine will give an error.
    </P>
    <h3>Scope</h3>
    <P>
      Many of the rules concern the actual configuration of barges within a tow. While the engine does not actually
      consider or recommend any tow configuration, the rules still impact how a nomination list is produced because a
      list that allows for good options for tow configuration is better than a similar list that gives worse options.
    </P>
    <P>
      At the moment, the rules below cover the composition of rake vs box barges and loaded vs empty barges in a tow.
      They do not cover tow requirements for specific routes or vessels.
      <AdNote title="Future development">
        Since barge draft comes in a range of values, it is a bit harder to draw general rules at a list level compared
        to the case of rake vs box hulls, in which there are only two possibilities. Nonetheless, we still plan to add
        rules about the drafts of barges (particularly within barge groups) in upcoming development.
      </AdNote>
    </P>
    <h2 data-section="list-of-rules">List of rules</h2>
    <h3>Head of tow rules</h3>
    <P>
      Soft and hard rules for the head/bow (front) of the tow
      <ul>
        <li>
          <b>[Hard]</b> <i>(Loaded tows on locked rivers, SB on LMR)</i> Rake barges should be across the head of tow
        </li>
        <li>
          <b>[Hard]</b> <i>(Northbound on LMR)</i> Empty barges should be across the head of tow
          <ul>
            <li>
              <i>Note</i>: This is a softer rule than for loaded barges because empty barges do not have much draft in
              the water, and so the hydrodynamic difference between a rake vs a box is an less significant.
            </li>
            <li>
              In other words, empties at the head of tow <i>should</i> ideally all have raked hulls, but it is
              permissible that there are box barges.
            </li>
          </ul>
        </li>
        <li>
          <b>[Medium]</b> <i>(Northbound on LMR + empty tows)</i> When there are empty barges as at the head of tow,
          they should be rake barges
          <ul>
            <li>
              <i>Extension</i>: Combining with the previous medium rule, if there are empty boxes at the head of tow,
              they have to be in the interior of the head.
            </li>
            <li>
              <i>Implementation</i>: The difference between this hard rule and the previous soft rule is that the hard
              rule
              <i>requires</i> there to be at least two empty rake barges in a list, while the soft rule <i>prefers</i>{' '}
              that there are at least as many empty rake barges as could fit across the head of tow.
            </li>
          </ul>
        </li>
      </ul>
    </P>
    <h3>Stern of tow rules</h3>
    <P>
      Soft and hard rules for the stern (back) of the tow
      <ul>
        <li>
          <b>[Medium]</b> <i>(Loaded tows)</i> Rake barges should be across the stern of tow
        </li>
        <li>
          <b>[Soft]</b> <i>(Empty tows)</i> Rake barges should be across the stern of tow
          <ul>
            <li>
              <i>Note</i>: This is a softer rule than for loaded barges because empty barges do not have much draft in
              the water, and so the hydrodynamic difference between a rake vs a box is less significant.
            </li>
          </ul>
        </li>
      </ul>
    </P>
    <h3>Full tow rules</h3>
    <P>
      Soft and hard rules over the entire tow
      <ul>
        <li>
          <b>[Soft]</b> There should be an even number (i.e. multiple of 2) of loaded rakes in the tow.
          <ul>
            <li>
              <i>Extension</i>: If we request an odd number of <i>total</i> loaded barges in a list, the nomination
              output should have an odd number of loaded <i>box</i> barges.
            </li>
          </ul>
        </li>
        <li>
          <b>[Hard]</b>: For each tow-size from 4 to 60, the optimizer is given a hard minimum for the number of rakes
          in a list so that every output list will have at <i>least</i> the minimum number of rakes for the given size.
        </li>
        <li>
          <b>[Soft]</b> <Link href={docsPagePath('barge-groups')}>Groups of barges</Link> in a strings (i.e. a “unit”)
          that share a destination (either final destination, or they may continue on in later tows to same drop-off
          area) should also have an even number of rakes in them.
          <ul>
            <li>
              <i>Note</i>: This is an even <i>softer</i> rule than the previous, since this applies to subsets of a
              list, and is harder to satisfy for every relevant subset than it is for the entire list.
            </li>
            <li>
              <i>Extension</i>: Ideal rake/box mix for groups can form a{' '}
              <Link href={`${docsPagePath('barge-groups')}/s/advanced`}>hydrodynamic ("slick")</Link> string, such as:
              <ul>
                <li>
                  <i>“2-piece unit”</i>: group of 2 barges → 2 × rake
                </li>
                <li>
                  <i>“3-piece unit”</i>: group of 3 barges → 2 × rake, 1 × box
                </li>
                <li>
                  <i>“5-piece unit”</i>: group of 5 barges → 2 × rake, 3 × box
                </li>
              </ul>
            </li>
            <li>
              <i>Example</i>: If we have a tow with a length of 5 (i.e. a tow made of strings of 5 barges), ideally each
              string can be made of either a 5-piece unit group or combination of a 2-piece unit group and 3-piece unit
              group.
            </li>
          </ul>
        </li>
      </ul>
    </P>
  </>
)
