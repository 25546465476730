import { createContext, FC, Context, PropsWithChildren, useContext, useReducer, useCallback } from 'react'

import { LocalStoreKeys, useLocalStorageState } from '../hooks/useLocalStorage'

type FeatureFlagState = {
  experimentalFeaturesEnabled: boolean
}

const initialFeatureFlagState: FeatureFlagState = {
  experimentalFeaturesEnabled: false,
}

interface FeatureFlagProviderInterface extends FeatureFlagState {
  toggleExperimentalFeatures(enabled: boolean): void
}

const stub = (): never => {
  throw new Error('You forgot to wrap your component in <FeatureFlagProvider>.')
}

const initialState: FeatureFlagProviderInterface = {
  ...initialFeatureFlagState,
  toggleExperimentalFeatures: stub,
}

const FeatureFlagContext: Context<FeatureFlagProviderInterface> =
  createContext<FeatureFlagProviderInterface>(initialState)

enum ActionTypes {
  TOGGLE_EXPERIMENTAL_FEATURES = 'TOGGLE_EXPERIMENTAL_FEATURES',
}
type ToggleExperimentalFeaturesPayload = { next: boolean }
type Action = { type: ActionTypes.TOGGLE_EXPERIMENTAL_FEATURES; payload: ToggleExperimentalFeaturesPayload }

const reducer = (state: FeatureFlagState, action: Action) => {
  switch (action.type) {
    case ActionTypes.TOGGLE_EXPERIMENTAL_FEATURES:
      return {
        ...state,
        experimentalFeaturesEnabled: action.payload.next,
      }
    default:
      return state
  }
}

const FeatureFlagProvider: FC<PropsWithChildren> = ({ children }: PropsWithChildren) => {
  const { getItem, setItem } = useLocalStorageState()
  const savedExperimentalFlagState = !!getItem(LocalStoreKeys.EXPERIMENTAL_FEATURES_ENABLED)
  const savedState: FeatureFlagState = { ...initialState, experimentalFeaturesEnabled: savedExperimentalFlagState }

  const [state, dispatch] = useReducer(reducer, savedState)

  const toggleExperimentalFeatures = useCallback(
    (enabled: boolean) => {
      setItem(LocalStoreKeys.EXPERIMENTAL_FEATURES_ENABLED, enabled)
      dispatch({ type: ActionTypes.TOGGLE_EXPERIMENTAL_FEATURES, payload: { next: enabled } })
    },
    [setItem, dispatch]
  )

  const providerOps: FeatureFlagProviderInterface = {
    ...state,
    toggleExperimentalFeatures,
  }

  return <FeatureFlagContext.Provider value={providerOps}>{children}</FeatureFlagContext.Provider>
}

export default FeatureFlagProvider

export const useFeatureFlagProvider: () => FeatureFlagProviderInterface = () => useContext(FeatureFlagContext)
