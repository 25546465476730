import { PropsWithChildren, useEffect, useRef, useState } from 'react'

import classnames from 'classnames'

import styles from './Ticker.module.scss'

export function Ticker({ children }: PropsWithChildren<{}>) {
  const [isActive, setIsActive] = useState(false)
  const [scrollSpace, setScrollSpace] = useState(0)
  const ref = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const el = ref.current
    if (el === null) {
      return undefined
    }

    const resize = () => setScrollSpace(el.scrollWidth - el.clientWidth)

    window.addEventListener('resize', resize)
    resize()

    return () => window.removeEventListener('resize', resize)
  }, [ref])

  return (
    <div
      onMouseEnter={() => setIsActive(true)}
      onMouseLeave={() => setIsActive(false)}
      className={styles.ticker}
      ref={ref}>
      <span
        className={classnames(styles.inner, { [styles.isActive]: isActive, [styles.isOverflowing]: scrollSpace > 0 })}
        style={{
          transform: isActive ? `translateX(-${scrollSpace}px)` : 'translateX(0)',
        }}>
        {children}
      </span>
    </div>
  )
}
