import React, { FC, useMemo } from 'react'

import { FormikErrors } from 'formik'
import * as R from 'ramda'

import { OverviewBarge } from '../../../../Domain/Barge'
import { TowParametersFormValues } from '../types'
import { collectTotals } from '../viewModel'

import { NumberInputSection, NumberInputWithReset } from './FormComponents'

type TowParametersFormProps = {
  data: TowParametersFormValues
  setData: (config: TowParametersFormValues) => void
  errors?: FormikErrors<TowParametersFormValues>
  barges: OverviewBarge[]
}

const TowParametersForm: FC<TowParametersFormProps> = ({ data, setData, errors, barges }) => {
  const { totalNumberOfLoaded, totalNumberOfEmpties } = useMemo(() => collectTotals(data, barges), [data, barges])

  const handleSetLoaded = (value: number) => {
    setData(R.assoc('numberOfLoaded', value, data))
  }

  const handleSetEmpties = (value: number) => {
    setData(R.assoc('numberOfEmpties', value, data))
  }

  const handleSetStrings = (value: number | null) => {
    setData(R.assoc('numberOfStrings', value, data))
  }

  const getMaxNumberOfStrings = (numberOfLoaded: number, numberOfEmpties: number): number => {
    const total = numberOfLoaded + numberOfEmpties
    return R.min(Math.floor(total / 2), 8)
  }

  const maxStrings = getMaxNumberOfStrings(data.numberOfLoaded, data.numberOfEmpties)

  return (
    <>
      <NumberInputSection
        label="Number of loaded"
        value={data.numberOfLoaded ?? 0}
        maxValue={totalNumberOfLoaded}
        onChange={handleSetLoaded}
        disabled={false}
        placeholder="Enter a value"
      />
      <NumberInputSection
        label="Number of empty barges"
        value={data.numberOfEmpties}
        maxValue={totalNumberOfEmpties ?? 0}
        onChange={handleSetEmpties}
        disabled={false}
        placeholder="Enter a value"
      />
      <NumberInputWithReset
        label="Number of strings"
        value={data.numberOfStrings ?? 0}
        minValue={0}
        maxValue={maxStrings}
        onChange={handleSetStrings}
        onClear={() => handleSetStrings(null)}
        placeholder="0"
        disabled={data.numberOfStrings === 0}
        warnings={errors?.numberOfStrings as string | undefined}
      />
    </>
  )
}

export default TowParametersForm
