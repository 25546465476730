import React from 'react'

import { ColorByType } from '../../ui/Table/TableColumnConfig'
import Selector from '../Header/Selector/Selector'

type ColorBySelectorProps = {
  colorBy: ColorByType
  colorByOptions: ColorByType[]
  handleColorByChange: (key: ColorByType) => void
}

const ColorBySelector: React.FC<ColorBySelectorProps> = ({ colorBy, colorByOptions, handleColorByChange }) => {
  return (
    <Selector
      label="Highlight by"
      currentSelection={colorBy}
      options={colorByOptions}
      onChange={handleColorByChange}
      tooltipText="Highlight barges by group"
    />
  )
}

export default ColorBySelector
