import * as React from 'react'

import classnames from 'classnames'

import styles from './DrillDownLevel.module.scss'

export enum DrillDown {
  LEFT = 'SIDE_MENU_LEFT',
  RIGHT = 'SIDE_MENU_RIGHT',
}

interface IDrillDownLevelProps {
  position: DrillDown
  active: boolean
  children: React.ReactNode
}

export const DrillDownLevel = ({ position, active, children }: IDrillDownLevelProps) => {
  const className = classnames(
    styles.drillDownLevel,
    position === DrillDown.LEFT ? styles.drillDownLevelLeft : styles.drillDownLevelRight,
    active ? [styles.active] : undefined
  )

  return <div className={className}>{children}</div>
}
