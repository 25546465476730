import { parseISO, parseJSON } from 'date-fns'

import {
  BargeGroup,
  CustodyStatus,
  GraphqlBargeType as BargeType,
  HullType,
  LaneBargesQuery,
  LoadStatus,
  OverviewBarge as OverviewBargeGql,
  RiskLevel,
  RiverLocationLite,
  OverviewBargeTbo,
  PickupType,
} from '../generated/graphql'

import { CustodyInfo } from './Custody'
import { FacilityLocation } from './Facility'
import { OverviewTrip } from './Trip'

export function parseDate(input: string) {
  // TODO: remove fallback once backend provides us with 100% valid dates
  let date = parseISO(input)

  if (date.toString() !== 'Invalid Date') {
    return date
  }

  date = parseJSON(input)

  if (date.toString() !== 'Invalid Date') {
    return date
  }

  // include seconds if missing
  date = parseJSON(input.replace(/(\d{4}-\d{2}-\d{2}T\d{2}:\d{2})(Z.*)/, '$1:00$2'))

  if (date.toString() !== 'Invalid Date') {
    return date
  }

  throw new Error(`Could not parse date: ${input}`)
}

const maybeParseDate = (input: string | undefined | null) => (input ? parseDate(input) : null)
export type Barge = OverviewBargeGql

export function showBargeType(type: BargeType) {
  switch (type) {
    case BargeType.DeckOpen: {
      return 'Deck'
    }
    case BargeType.HoppOpen: {
      return 'Open'
    }
    case BargeType.HoppSpread: {
      return 'Spread'
    }
    case BargeType.HoppStacked: {
      return 'Stacked'
    }
    case BargeType.TankCaustic: {
      return 'Caustic'
    }
    case BargeType.TankChemical: {
      return 'Chemical'
    }

    case BargeType.TankFuel: {
      return 'Fuel'
    }
    case BargeType.Unknown: {
      return 'UNKN'
    }

    default:
      throw Error(`Unexpected barge type: ${type}`)
  }
}

type OverviewLocation = {
  riverLocation: RiverLocationLite
  locationCode: string | null
}

type NextDropOffDetails = {
  facility: Omit<FacilityLocation, 'facilityCode'> | null
  estimatedDropOffTime: Date | null
  willBeDroppedAtNextStop: boolean
}

export type OverviewBarge = {
  barge: Barge
  pickupFacility: FacilityLocation | null
  dropOffFacility: FacilityLocation | null
  currentTrip: OverviewTrip
  currentLocation: OverviewLocation
  custodyInfo: CustodyInfo
  isHot: boolean
  riskLevel: RiskLevel | null
  status: CustodyStatus
  expectedTripLoadStatus: LoadStatus
  timeInFleet: number | null
  group: BargeGroup | null
  nextDropOffDetails: NextDropOffDetails | null
  latestTBO: OverviewBargeTbo | null
  pickupType?: PickupType | null
}

export const distinctDestinations = (acc: string[], { currentTrip: { destination } }: OverviewBarge) => {
  const des = destination ? destination.code + destination.mileagePoint : undefined

  if (des && !acc.includes(des)) {
    acc.push(des)
  }

  return acc
}

export function sortByTimeInFleet({ timeInFleet: a }: OverviewBarge, { timeInFleet: b }: OverviewBarge) {
  if (a === null && b === null) {
    return 0
  }

  if (a === null || b === null) {
    return a === null ? -1 : 1
  }

  if (a === b) return 0
  return a > b ? -1 : 1
}

export function sortByGroup({ group: a }: OverviewBarge, { group: b }: OverviewBarge) {
  if (a === null && b === null) {
    return 0
  }

  if (a === null || b === null) {
    return a === null ? 1 : -1
  }

  if (a.uuid === b.uuid) return 0
  return a.uuid > b.uuid ? -1 : 1
}

export type BargeTotals = { rakes: number; boxes: number; tank: number; LD: number; MT: number }

export function collectBargeTotals<Acc extends BargeTotals>(acc: Acc, b: OverviewBarge) {
  if (b.barge.hullType === HullType.Box) acc.boxes += 1
  if (b.barge.hullType === HullType.Rake) acc.rakes += 1
  if (b.currentTrip.loadStatus === LoadStatus.Loaded) acc.LD += 1
  if (isEmpty(b)) acc.MT += 1
  if ([BargeType.TankCaustic, BargeType.TankChemical, BargeType.TankFuel].includes(b.barge.type)) acc.tank += 1

  return acc
}

const RAKE_THRESHOLD = 28

export const minNumberOfRakes = (numberOfBarges: number) =>
  Math.ceil(numberOfBarges / (numberOfBarges < RAKE_THRESHOLD ? 7 : 8)) * 2

export const isEmptyLoadStatus = (status: LoadStatus) => status === LoadStatus.Empty

export const isEmpty = (b: OverviewBarge) => isEmptyLoadStatus(b.expectedTripLoadStatus)

export const getBargeNames = (barges: OverviewBarge[]) => barges.map(({ barge: { name } }) => name)

export function getCompleteBargeGroupsIds(barges: OverviewBarge[]) {
  const bargeIds = barges.map(({ barge: { id } }) => id)

  return barges.reduce(
    ({ checked, valid }: { checked: string[]; valid: string[] }, { group }) => {
      if (group !== null && !checked.includes(group.uuid)) {
        checked.push(group.uuid)

        if (group.barges.every(id => bargeIds.includes(id))) {
          valid.push(group.uuid)
        }
      }

      return { valid, checked }
    },
    { checked: [], valid: [] }
  ).valid
}

export const fromBargesQuery = (data: LaneBargesQuery['lanes'][number]['barges']): OverviewBarge[] =>
  data.map(d => {
    const { currentTrip, custodyInfo, nextDropOffDetails, ...rest } = d

    const parsedCurrentTrip = {
      ...currentTrip,
      tripStatusTime: maybeParseDate(currentTrip.tripStatusTime),
    }

    const parsedCustodyInfo: CustodyInfo = {
      ...custodyInfo,
      boat: custodyInfo.boat
        ? {
            ...custodyInfo.boat,
            eta: maybeParseDate(custodyInfo.boat.eta),
          }
        : null,
    }

    const parsedNextDropOffDetails = nextDropOffDetails
      ? {
          ...nextDropOffDetails,
          estimatedDropOffTime: maybeParseDate(nextDropOffDetails.estimatedDropOffTime),
        }
      : null

    return {
      ...rest,
      currentTrip: parsedCurrentTrip,
      custodyInfo: parsedCustodyInfo,
      nextDropOffDetails: parsedNextDropOffDetails,
    }
  })

export const isRiskLevel = (r: any): r is RiskLevel => r in RiskLevel
