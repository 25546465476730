import { useCallback, useEffect, useState } from 'react'

import classnames from 'classnames'
import { Field, Formik, FormikHelpers } from 'formik'
import { useLocation } from 'wouter'
import { object as YupObject, string as YupString } from 'yup'

import { WarningMessage } from '../../ui/MessageBox/MessageBox'

import styles from './Account.module.scss'
import { AuthorizationModes, useAuthorizationContext } from './AuthorizationContext'
import { ErrorNotification, Form } from './Form'
import { Layout } from './Layout'

type NewPasswordFormData = {
  newPassword: string
}

const newPasswordValidationSchema = YupObject().shape(
  {
    newPassword: YupString().required('Required').min(8).label('password'),
  },
  []
)

export function NewPasswordRequiredPage() {
  const [, navigate] = useLocation()
  const { isAuthenticated, mode, forceResetPassword } = useAuthorizationContext()

  const [{ formValues, authenticationError }, setState] = useState<{
    formValues: NewPasswordFormData
    authenticationError: Error | null
  }>({
    formValues: { newPassword: '' },
    authenticationError: null,
  })

  const handleOnSubmit = useCallback(
    (form: NewPasswordFormData, { setSubmitting }: FormikHelpers<NewPasswordFormData>) => {
      forceResetPassword(form.newPassword)
        .then(() => navigate('/'))
        .catch((err: Error) => {
          setState(state => ({
            ...state,
            authenticationError: err,
          }))
          setSubmitting(false)
        })
    },
    [navigate, forceResetPassword]
  )

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/')
    }
  }, [isAuthenticated, navigate])

  useEffect(() => {
    if (mode !== AuthorizationModes.FORCED_PASSWORD_RESET && !isAuthenticated) {
      navigate('/login')
    }
  }, [isAuthenticated, mode, navigate])

  return (
    <Layout>
      <Formik initialValues={formValues} validationSchema={newPasswordValidationSchema} onSubmit={handleOnSubmit}>
        {({ dirty, isValid, isSubmitting, touched, errors }) => (
          <Form>
            <h2>New password required</h2>
            {authenticationError && (
              <WarningMessage
                title="Something went wrong"
                handleClose={() => setState(state => ({ ...state, authenticationError: null }))}>
                {authenticationError.message ? authenticationError.message : ''}
              </WarningMessage>
            )}
            <Field
              id="newPassword"
              name="newPassword"
              className={classnames(styles.input, { [styles.hasError]: touched.newPassword && errors.newPassword })}
              type="password"
              placeholder="New password"
            />
            {touched.newPassword && errors.newPassword && <ErrorNotification message={errors.newPassword} />}
            <span className={styles.clarificationText}>
              Password must contain lowercase and uppercase letters, numbers, and symbols.
            </span>
            <button disabled={!dirty || !isValid || isSubmitting} className={styles.submit} type="submit">
              Submit
            </button>
          </Form>
        )}
      </Formik>
    </Layout>
  )
}
