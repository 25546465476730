import { useEffect, useRef, useState } from 'react'

import classNames from 'classnames'

import styles from './LaneFilters.module.scss'

type Props = {
  maxDraft?: number
  setMaxDraft: (draft?: number) => void
  readOnly: boolean
}

export function MaxDraftFilter({ maxDraft, setMaxDraft, readOnly }: Props) {
  const debounce = useRef<ReturnType<typeof setTimeout>>()
  const [feet, setFeet] = useState(maxDraft ? Math.floor(maxDraft / 12) : null)
  const [inch, setInch] = useState(maxDraft && maxDraft % 12 > 0 ? Math.floor(maxDraft % 12) : null)

  const maxDraftClass = `${styles.nr} ${readOnly ? styles.disabledInput : ''}`

  useEffect(() => {
    if (debounce.current) {
      clearTimeout(debounce.current)
    }

    const draft = feet === null && inch === null ? undefined : (feet ?? 0) * 12 + (inch ?? 0)
    debounce.current = setTimeout(() => setMaxDraft(draft), 350)

    return () => {
      if (debounce.current) {
        clearTimeout(debounce.current)
      }
    }
  }, [feet, inch, setMaxDraft])

  return (
    <div className={`${styles.draught} ${readOnly ? styles.disabled : ''}`}>
      <div className={styles.draughtEntry}>
        <span className={classNames({ [styles.disabledLabel]: readOnly })}>feet</span>
        <input
          onChange={e => {
            const nrVal = e.target.valueAsNumber

            setFeet(Number.isNaN(nrVal) ? null : nrVal)
          }}
          min={0}
          placeholder="0"
          className={maxDraftClass}
          type="number"
          value={feet ?? ''}
          readOnly={readOnly}
        />
      </div>
      <div className={styles.draughtEntry}>
        <span className={classNames({ [styles.disabledLabel]: readOnly })}>inch</span>
        <input
          min={0}
          max={11}
          onChange={e => {
            const nrVal = e.target.valueAsNumber

            setInch(Number.isNaN(nrVal) ? null : nrVal)
          }}
          placeholder="0"
          className={maxDraftClass}
          type="number"
          defaultValue={inch ?? ''}
          readOnly={readOnly}
        />
      </div>
    </div>
  )
}
