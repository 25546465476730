import { useCallback } from 'react'

import { LOCAL_STORAGE_VERSION } from '../constants/env-vars'
import { useAuthorizationContext } from '../Pages/Account/AuthorizationContext'

export enum LocalStoreKeys {
  VERSION = 'version',
  COLUMN_CONFIG = 'columnConfig',
  EXPERIMENTAL_FEATURES_ENABLED = 'experimentalFeaturesEnabled',
}

const DEFAULT_STORE_VERSION = 'v0.6'

type Store = {
  version?: string
  columnConfig?: {
    order: Record<string, number>
    hidden: string[]
  }
  experimentalFeaturesEnabled?: boolean
}

function migrate(store: Store): Store {
  if (store.version !== LOCAL_STORAGE_VERSION) {
    return { version: DEFAULT_STORE_VERSION }
  }

  return store
}

function getStore(rootId: string): Store {
  const root = localStorage.getItem(rootId)

  return root ? migrate(JSON.parse(root)) : {}
}

export const useLocalStorageState = () => {
  const userid = useAuthorizationContext().getUserInfo()?.sub || 'anon'
  const rootId = `barge-nomination-app:${userid}`

  const getItem = useCallback(
    <K extends keyof Store>(key: K) => {
      const store = getStore(rootId)
      return key in store ? store[key] : undefined
    },
    [rootId]
  )

  const setItem = useCallback(
    <K extends keyof Store>(key: K, value: Store[K]) =>
      localStorage.setItem(rootId, JSON.stringify({ ...getStore(rootId), [key]: value })),
    [rootId]
  )

  return {
    setItem,
    getItem,
  }
}
