import { PropsWithChildren } from 'react'

import { ArrowBack } from '@mui/icons-material'
import classnames from 'classnames'

import styles from './SortColumn.module.scss'

export function SortableColumn({
  isSorted,
  children,
}: PropsWithChildren<{ isSorted?: 'asc' | 'desc' }>) {
  return (
    <div className={styles.column}>
      {children}
      <ArrowBack
        className={classnames(styles.sortArrow, {
          [styles.active]: isSorted !== undefined,
          [styles.desc]: isSorted === 'desc',
        })}
      />
    </div>
  )
}
