import { PropsWithChildren } from 'react'

import { Popover } from '@headlessui/react'
import classnames from 'classnames'

import styles from './DropoutMenuDropper.module.scss'

export enum PositionX {
  Left,
  Center,
  Right,
}

export enum PositionY {
  Top,
  Bottom,
}

export type DropoutMenuDropperTheme = Readonly<{ triangleTop?: string; triangleBottom?: string }>

type DropoutMenuDropperProps = Readonly<{
  theme?: DropoutMenuDropperTheme
  className?: string
  positionX: PositionX
  positionY: PositionY
  triangleOffsetLeft?: number | string
  triangleOffsetRight?: number | string
  triangleOffsetTop?: number | string
  triangleOffsetBottom?: number | string
  hasTriangleSpace?: boolean
  hasTriangle?: boolean
  isHorizontal?: boolean
}>

const Dropout = ({
  children,
  className,
  positionX,
  positionY,
  triangleOffsetLeft = 'auto',
  triangleOffsetRight = 'auto',
  triangleOffsetTop = 'auto',
  triangleOffsetBottom = 'auto',
  hasTriangleSpace = false,
  hasTriangle = true,
  isHorizontal = false,
  theme = {},
}: PropsWithChildren<DropoutMenuDropperProps>) => {
  const triangleStyle = isHorizontal
    ? {
        marginTop: triangleOffsetTop,
        marginBottom: triangleOffsetBottom,
      }
    : {
        marginLeft: triangleOffsetLeft,
        marginRight: triangleOffsetRight,
      }

  return (
    <Popover.Panel
      className={classnames(styles.dropoutDropper, {
        [styles.left]: positionX === PositionX.Left,
        [styles.right]: positionX === PositionX.Right,
        [styles.center]: positionX === PositionX.Center,
        [styles.top]: positionY === PositionY.Top,
        [styles.bottom]: positionY === PositionY.Bottom,
        [styles.triangleSpace]: hasTriangleSpace,
        [styles.horizontal]: isHorizontal,
      })}>
      {hasTriangle && (
        <div
          className={classnames(styles.triangle, {
            [styles.offsetLeft]: triangleOffsetLeft !== 'auto' || triangleOffsetBottom !== 'auto',
            [styles.offsetRight]: triangleOffsetRight !== 'auto' || triangleOffsetTop !== 'auto',
            [theme.triangleTop || styles.top]: positionY === PositionY.Top,
            [theme.triangleBottom || styles.bottom]: positionY === PositionY.Bottom,
          })}
          style={triangleStyle}
        />
      )}
      <div className={classnames(styles.content, className)}>{children}</div>
    </Popover.Panel>
  )
}

export type Placement = 'bottom-end' | 'bottom-start' | 'bottom' | 'top-end' | 'top-start' | 'top'

type PositionedMenuDroppperProps = Pick<
  DropoutMenuDropperProps,
  | 'className'
  | 'hasTriangleSpace'
  | 'hasTriangle'
  | 'triangleOffsetLeft'
  | 'triangleOffsetRight'
  | 'triangleOffsetTop'
  | 'triangleOffsetBottom'
  | 'theme'
  | 'isHorizontal'
> & {
  placement: Placement
}

function getPosition(placement: Placement): { x: PositionX; y: PositionY } {
  switch (placement) {
    case 'top':
      return { x: PositionX.Center, y: PositionY.Top }
    case 'top-end':
      return { x: PositionX.Right, y: PositionY.Top }

    case 'top-start':
      return { x: PositionX.Left, y: PositionY.Top }

    case 'bottom':
      return { x: PositionX.Center, y: PositionY.Bottom }

    case 'bottom-start':
      return { x: PositionX.Left, y: PositionY.Bottom }
    case 'bottom-end':
      return { x: PositionX.Right, y: PositionY.Bottom }

    default:
      throw new Error(`Unknown placement: ${placement}`)
  }
}

export function DropoutMenuDropper(props: PropsWithChildren<PositionedMenuDroppperProps>) {
  const { x, y } = getPosition(props.placement)

  return (
    <Dropout {...props} positionY={y} positionX={x}>
      {props.children}
    </Dropout>
  )
}
