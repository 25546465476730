import { useEffect, useMemo, useState } from 'react'

import { ChevronRight } from '@mui/icons-material'
import { Link, useLocation, useRoute } from 'wouter'

import { docsPagePath, docsPath } from '../../paths'

type SectionType = {
  el: Element
  path: string
  sectionId: string
  title: string
}

function useIndexMatch(): [boolean, string | undefined] {
  const [rootMatch] = useRoute(docsPath)
  const [sectionMatch, params] = useRoute(`${docsPath}/s/:section`)

  return [rootMatch || sectionMatch, params?.section]
}

function usePageMatch(): [string | undefined, string | undefined] {
  const [, pageParams] = useRoute(`${docsPath}/:page`)
  const [, sectionParams] = useRoute(`${docsPath}/:page/s/:section`)

  return [pageParams?.page ?? sectionParams?.page, sectionParams?.section]
}

export function SectionNav({ docId }: { docId: string }) {
  const [, setLocation] = useLocation()
  const [sections, setSections] = useState<SectionType[]>([])

  const [indexMatch, indexSection] = useIndexMatch()
  const [page, pageSection] = usePageMatch()

  const section = useMemo(() => indexSection ?? pageSection, [indexSection, pageSection])

  useEffect(() => {
    if (section) {
      const el = document.querySelector(`#${docId} [data-section="${section}"]`)

      if (el) {
        el.scrollIntoView({
          behavior: 'smooth',
        })
      }
    }
  }, [docId, section])

  useEffect(() => {
    const elements = document.querySelectorAll(`#${docId} [data-section]`)

    if (indexMatch) {
      setSections(
        Array.prototype.map.call<NodeListOf<Element>, [(el: Element) => SectionType], SectionType[]>(elements, el => ({
          el,
          path: docsPath,
          sectionId: el.getAttribute('data-section') ?? '',
          title: el.textContent ?? '',
        }))
      )

      return undefined
    }

    if (page) {
      setSections(
        Array.prototype.map.call<NodeListOf<Element>, [(el: Element) => SectionType], SectionType[]>(elements, el => ({
          el,
          path: docsPagePath(page),
          sectionId: el.getAttribute('data-section') ?? '',
          title: el.textContent ?? '',
        }))
      )

      return undefined
    }

    return undefined
  }, [docId, indexMatch, page])

  if (!sections.length) {
    return null
  }

  return (
    <nav>
      <ul>
        {page && (
          <li key="root">
            <Link href={docsPath}>
              <span>Documentation</span>
              <ChevronRight />
            </Link>
          </li>
        )}
        {sections.map(({ path, sectionId, title }) => (
          <li key={sectionId}>
            <a
              href={`#${sectionId}`}
              onClick={e => {
                e.preventDefault()
                setLocation(`${path}/s/${sectionId}`)
              }}>
              {title}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  )
}
