import React, { type FC } from 'react'

import classnames from 'classnames'

import { Journey } from '../../../components/Nomination/Journey'

import LabelValuePair from './LabelValuePair'
import styles from './styles.module.scss'

import type { JourneyData } from './viewModel'

type JourneyBlockProps = {
  readonly journey: JourneyData
}

const JourneyBlock: FC<JourneyBlockProps> = ({ journey }) => {
  const { boat, stops, stopSelectionHandler, maximumBargesInTow, selectedStop } = journey

  return (
    <div className={styles.container}>
      <div className={classnames([styles.section, styles.journey])}>
        <Journey
          stops={stops}
          boat={boat}
          selectedStop={selectedStop ?? undefined}
          handleSelectStop={stopSelectionHandler}
        />
        <LabelValuePair label="Maximum number of barges in tow" value={maximumBargesInTow} />
      </div>
    </div>
  )
}

export default JourneyBlock
