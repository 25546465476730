import { PropsWithChildren } from 'react'

import { ChevronRight } from '@mui/icons-material'
import { Switch, Route, Link } from 'wouter'

import { docsPagePath } from '../../paths'
import { Layout } from '../../ui/Layout/Layout'

import { BargeGroupsDoc } from './BargeGroupsDoc'
import { BuildToDestinationDoc } from './BuildToDestinationDoc'
import { P } from './Components/Components'
import styles from './Docs.module.scss'
import { FleetEfficiencyDoc } from './FleetEfficiencyDoc'
import { LanesDoc } from './LanesDoc'
import { NominationPoliciesDoc } from './NominationPoliciesDoc'
import { OperationalGoalsDoc } from './OperationalGoalsDoc'
import { ScrollToTopButton } from './ScrollToTop/ScrollToTopButton'
import { SectionNav } from './SectionNav'
import { TowCompositionRulesDoc } from './TowCompositionRulesDoc'

const DOC_ID = 'documentation'

const Container = ({ children }: PropsWithChildren<{}>) => (
  <>
    <div className={styles.doc}>{children}</div>
    <SectionNav docId={DOC_ID} />
    <ScrollToTopButton byId={DOC_ID} />
  </>
)

const Main = () => (
  <>
    <h1 data-section="intro">Documentation</h1>
    <h2>Barge Nomination Engine</h2>
    <P>
      This is the index home page for documentation of the Barge Nomination Engine. Below you can find a list of all the
      docs written.
    </P>

    <h2 data-section="doc-pages">Documentation pages</h2>
    <nav className={styles.docLinks}>
      <Link href={docsPagePath('lanes')}>
        <span>Lanes</span>
        <ChevronRight />
      </Link>
      <Link href={docsPagePath('operational-goals')}>
        <span>Operational Goals</span>
        <ChevronRight />
      </Link>
      <Link href={docsPagePath('barge-groups')}>
        <span>Barge groups</span>
        <ChevronRight />
      </Link>
      <Link href={docsPagePath('tow-composition-rules')}>
        <span>Tow-composition rules</span>
        <ChevronRight />
      </Link>
    </nav>
  </>
)

export const Docs = () => (
  <Layout>
    <div className={styles.docContainer} id={DOC_ID}>
      <Switch>
        <Route path={`${docsPagePath('barge-groups')}/s/:section`}>
          <Container>
            <BargeGroupsDoc />
          </Container>
        </Route>
        <Route path={docsPagePath('barge-groups')}>
          <Container>
            <BargeGroupsDoc />
          </Container>
        </Route>
        <Route path={`${docsPagePath('tow-composition-rules')}/s/:section`}>
          <Container>
            <TowCompositionRulesDoc />
          </Container>
        </Route>
        <Route path={docsPagePath('tow-composition-rules')}>
          <Container>
            <TowCompositionRulesDoc />
          </Container>
        </Route>
        <Route path={`${docsPagePath('lanes')}/s/:section`}>
          <Container>
            <LanesDoc />
          </Container>
        </Route>
        <Route path={docsPagePath('lanes')}>
          <Container>
            <LanesDoc />
          </Container>
        </Route>
        <Route path={`${docsPagePath('operational-goals')}/s/:section`}>
          <Container>
            <OperationalGoalsDoc />
          </Container>
        </Route>
        <Route path={docsPagePath('operational-goals')}>
          <Container>
            <OperationalGoalsDoc />
          </Container>
        </Route>
        <Route path={`${docsPagePath('build-to-destination')}/s/:section`}>
          <Container>
            <BuildToDestinationDoc />
          </Container>
        </Route>
        <Route path={docsPagePath('build-to-destination')}>
          <Container>
            <BuildToDestinationDoc />
          </Container>
        </Route>
        <Route path={`${docsPagePath('fleet-efficiency')}/s/:section`}>
          <Container>
            <FleetEfficiencyDoc />
          </Container>
        </Route>
        <Route path={docsPagePath('fleet-efficiency')}>
          <Container>
            <FleetEfficiencyDoc />
          </Container>
        </Route>
        <Route path={`${docsPagePath('nomination-policies')}/s/:section`}>
          <Container>
            <NominationPoliciesDoc />
          </Container>
        </Route>
        <Route path={docsPagePath('nomination-policies')}>
          <Container>
            <NominationPoliciesDoc />
          </Container>
        </Route>
        <Route>
          <Container>
            <Main />
          </Container>
        </Route>
      </Switch>
    </div>
  </Layout>
)
