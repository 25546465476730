import React, { FC } from 'react'


import DateInput from './DateInput'
import styles from './styles.module.scss'
import { Time, TimeInput } from './TimeInput'



type DateTimeSelectorProps = {
  day: Date | null
  time?: Time | null 
  onChange: (day: Date | null, time: Time | null) => void
  isDisabled?: boolean
}

const DateTimeSelector: FC<DateTimeSelectorProps> = ({ day, time, onChange, isDisabled = false }) => {
  const handleDateChange = (date: Date | null) => {
    const newDay = date || null
    onChange(newDay, null) 
  }

  const handleTimeChange = (newTime: Time | null) => {
    onChange(day, newTime)
  }

  return (
    <div className={styles.dateTimeSelector}>
      <div className={styles.formRow}>
        <label className={styles.label}>Day</label>
        <DateInput selectedDate={day} onChange={handleDateChange} isDisabled={isDisabled} />
      </div>
      <div className={styles.formRow}>
        <label className={styles.label}>Time</label>
        <TimeInput
          isDisabled={isDisabled || !day}
          value={time} 
          handleChange={handleTimeChange}
          isDateSelected={!!day}
          placeholderText="HH:MM" 
        />
      </div>
    </div>
  )
}

export default DateTimeSelector
