import { useMemo, useState } from 'react'

import { Combobox } from '@headlessui/react'
import { HighlightOff } from '@mui/icons-material'
import classnames from 'classnames'
import Fuse from 'fuse.js'

import { isNonHub } from '../../../Domain/Hub'
import { showRiverLocation } from '../../../Domain/River'
import { HubLike, HubLikeId } from '../../../generated/graphql'
import { useSettingsContext } from '../../../providers/SettingsProvider'
import {
  ComboboxButton,
  ComboboxOptions,
  ComboboxOption,
  ComboboxWrapper,
  ComboboxInput,
} from '../../../ui/Combobox/Combobox'

import styles from './HubLikeSelector.module.scss'

const HubEntry = ({ hub }: { hub: HubLike }) => (
  <>
    <span className={styles.entryCode}>{showRiverLocation(hub.riverLocation)}</span>
    {hub.label}
  </>
)

const INDEX_OPTIONS: Fuse.IFuseOptions<HubLike> = {
  keys: [
    { name: 'code', getFn: hub => hub.riverLocation.code, weight: 1.5 },
    { name: 'mp', getFn: hub => hub.riverLocation.mileagePoint.toString(), weight: 2.0 },
    { name: 'label' },
  ],
  includeScore: true,
  ignoreFieldNorm: true,
}

export function HubLikeSelector({
  readOnly,
  value,
  placeholder,
  handleChange,
  filter,
  isDisabled = false,
}: {
  readOnly: boolean
  value?: HubLikeId
  placeholder: string
  isDisabled: boolean
  handleChange: (value?: HubLikeId) => void
  filter: (hub: HubLike) => boolean
}) {
  const [query, setQuery] = useState('')
  const { hubs: hubLikes } = useSettingsContext()

  const filteredHubs = useMemo(() => Object.values(hubLikes).filter(filter), [hubLikes, filter])

  const index = useMemo(() => new Fuse<HubLike>(filteredHubs, INDEX_OPTIONS), [filteredHubs])

  const hubs = useMemo(() => {
    if (query) {
      const q = query.toLowerCase()

      return index
        .search(query)
        .map(h => ({
          ...h,
          score:
            (h.score ?? 0) +
            (`${showRiverLocation(h.item.riverLocation)} ${h.item.label}`.toLowerCase().includes(q) ? 1 : 0),
        }))
        .sort((a, b) => {
          if (a.score === b.score) {
            if (a.item.__typename !== b.item.__typename) {
              return isNonHub(a.item) ? 1 : -1
            }

            return 0
          }
          return a.score > b.score ? -1 : 1
        })
        .map(h => h.item)
    }

    return filteredHubs
  }, [query, index, filteredHubs])

  function displayValue(id: HubLikeId | undefined) {
    if (!id) {
      return ''
    }

    const hub = hubLikes[id]
    return `${showRiverLocation(hub.riverLocation)} ${hub.label}`
  }

  return (
    <ComboboxWrapper className={styles.menu}>
      <Combobox disabled={isDisabled} nullable value={value ?? null} onChange={handleChange}>
        <ComboboxInput className={styles.comboboxInput}>
          <label>
            <Combobox.Input
              displayValue={displayValue}
              placeholder={placeholder}
              onChange={({ target }) => setQuery(target.value)}
              autoFocus
            />
            {value && (
              <HighlightOff
                className={styles.clear}
                onClick={e => {
                  e.stopPropagation()
                  setQuery('')
                  handleChange()
                }}
              />
            )}
            <ComboboxButton />
          </label>
        </ComboboxInput>
        <ComboboxOptions
          className={classnames(styles.menuPanel, { [styles.disabled]: readOnly })}
          afterLeave={() => setQuery('')}>
          {hubs.map(h => (
            <ComboboxOption key={h.id} className={styles.menuItem} value={h.id}>
              <span className={classnames(styles.icon, { [styles.hub]: !isNonHub(h) })} />
              <HubEntry hub={h} />
            </ComboboxOption>
          ))}
        </ComboboxOptions>
      </Combobox>
    </ComboboxWrapper>
  )
}
