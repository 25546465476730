// github.com/mixpanel/mixpanel-js/blob/4d0f55b72f250a29548b90b4aede12f9fbb137d8/src/utils.js#L1515

const includes = (str: string, needle: string) => str.indexOf(needle) !== -1

/**
 * This function detects which browser is running this script.
 * The order of the checks are important since many user agents
 * include key words used in later checks.
 */
function browser() {
  const { userAgent, vendor } = navigator

  const opera = 'opera' in window

  if (opera || includes(userAgent, ' OPR/')) {
    if (includes(userAgent, 'Mini')) {
      return 'Opera Mini'
    }
    return 'Opera'
  }
  if (/(BlackBerry|PlayBook|BB10)/i.test(userAgent)) {
    return 'BlackBerry'
  }
  if (includes(userAgent, 'IEMobile') || includes(userAgent, 'WPDesktop')) {
    return 'Internet Explorer Mobile'
  }
  if (includes(userAgent, 'SamsungBrowser/')) {
    // https://developer.samsung.com/internet/user-agent-string-format
    return 'Samsung Internet'
  }
  if (includes(userAgent, 'Edge') || includes(userAgent, 'Edg/')) {
    return 'Microsoft Edge'
  }
  if (includes(userAgent, 'FBIOS')) {
    return 'Facebook Mobile'
  }
  if (includes(userAgent, 'Chrome')) {
    return 'Chrome'
  }
  if (includes(userAgent, 'CriOS')) {
    return 'Chrome iOS'
  }
  if (includes(userAgent, 'UCWEB') || includes(userAgent, 'UCBrowser')) {
    return 'UC Browser'
  }
  if (includes(userAgent, 'FxiOS')) {
    return 'Firefox iOS'
  }
  if (includes(vendor ?? '', 'Apple')) {
    if (includes(userAgent, 'Mobile')) {
      return 'Mobile Safari'
    }
    return 'Safari'
  }
  if (includes(userAgent, 'Android')) {
    return 'Android Mobile'
  }
  if (includes(userAgent, 'Konqueror')) {
    return 'Konqueror'
  }
  if (includes(userAgent, 'Firefox')) {
    return 'Firefox'
  }
  if (includes(userAgent, 'MSIE') || includes(userAgent, 'Trident/')) {
    return 'Internet Explorer'
  }
  if (includes(userAgent, 'Gecko')) {
    return 'Mozilla'
  }
  return ''
}

/**
 * This function detects which browser version is running this script,
 * parsing major and minor version (e.g., 42.1). User agent strings from:
 * http://www.useragentstring.com/pages/useragentstring.php
 */
function browserVersion() {
  const { userAgent } = navigator

  switch (browser()) {
    case 'Internet Explorer Mobile': {
      const matches = userAgent.match(/rv:(\d+(\.\d+)?)/)

      return !matches ? null : parseFloat(matches[matches.length - 2])
    }
    case 'Microsoft Edge': {
      const matches = userAgent.match(/Edge?\/(\d+(\.\d+)?)/)

      return !matches ? null : parseFloat(matches[matches.length - 2])
    }
    case 'Chrome': {
      const matches = userAgent.match(/Chrome\/(\d+(\.\d+)?)/)

      return !matches ? null : parseFloat(matches[matches.length - 2])
    }
    case 'Chrome iOS': {
      const matches = userAgent.match(/CriOS\/(\d+(\.\d+)?)/)

      return !matches ? null : parseFloat(matches[matches.length - 2])
    }
    case 'UC Browser': {
      const matches = userAgent.match(/(UCBrowser|UCWEB)\/(\d+(\.\d+)?)/)
      return !matches ? null : parseFloat(matches[matches.length - 2])
    }
    case 'Safari': {
      const matches = userAgent.match(/Version\/(\d+(\.\d+)?)/)

      return !matches ? null : parseFloat(matches[matches.length - 2])
    }
    case 'Mobile Safari': {
      const matches = userAgent.match(/Version\/(\d+(\.\d+)?)/)
      return !matches ? null : parseFloat(matches[matches.length - 2])
    }
    case 'Opera': {
      const matches = userAgent.match(/(Opera|OPR)\/(\d+(\.\d+)?)/)

      return !matches ? null : parseFloat(matches[matches.length - 2])
    }
    case 'Firefox': {
      const matches = userAgent.match(/Firefox\/(\d+(\.\d+)?)/)
      return !matches ? null : parseFloat(matches[matches.length - 2])
    }
    case 'Firefox iOS': {
      const matches = userAgent.match(/FxiOS\/(\d+(\.\d+)?)/)

      return !matches ? null : parseFloat(matches[matches.length - 2])
    }
    case 'Konqueror': {
      const matches = userAgent.match(/Konqueror:(\d+(\.\d+)?)/)

      return !matches ? null : parseFloat(matches[matches.length - 2])
    }
    case 'BlackBerry': {
      const matches = userAgent.match(/BlackBerry (\d+(\.\d+)?)/)

      return !matches ? null : parseFloat(matches[matches.length - 2])
    }
    case 'Android Mobile': {
      const matches = userAgent.match(/android\s(\d+(\.\d+)?)/)
      return !matches ? null : parseFloat(matches[matches.length - 2])
    }
    case 'Samsung Internet': {
      const matches = userAgent.match(/SamsungBrowser\/(\d+(\.\d+)?)/)

      return !matches ? null : parseFloat(matches[matches.length - 2])
    }
    case 'Internet Explorer': {
      const matches = userAgent.match(/(rv:|MSIE )(\d+(\.\d+)?)/)

      return !matches ? null : parseFloat(matches[matches.length - 2])
    }
    case 'Mozilla': {
      const matches = userAgent.match(/rv:(\d+(\.\d+)?)/)

      return !matches ? null : parseFloat(matches[matches.length - 2])
    }
    default: {
      return null
    }
  }
}

function device() {
  const { userAgent } = navigator
  if (/Windows Phone/i.test(userAgent) || /WPDesktop/.test(userAgent)) {
    return 'Windows Phone'
  }
  if (/iPad/.test(userAgent)) {
    return 'iPad'
  }
  if (/iPod/.test(userAgent)) {
    return 'iPod Touch'
  }
  if (/iPhone/.test(userAgent)) {
    return 'iPhone'
  }
  if (/(BlackBerry|PlayBook|BB10)/i.test(userAgent)) {
    return 'BlackBerry'
  }
  if (/Android/.test(userAgent)) {
    return 'Android'
  }
  return ''
}

function os() {
  const { userAgent } = navigator

  if (/Windows/i.test(userAgent)) {
    if (/Phone/.test(userAgent) || /WPDesktop/.test(userAgent)) {
      return 'Windows Phone'
    }
    return 'Windows'
  }
  if (/(iPhone|iPad|iPod)/.test(userAgent)) {
    return 'iOS'
  }
  if (/Android/.test(userAgent)) {
    return 'Android'
  }
  if (/(BlackBerry|PlayBook|BB10)/i.test(userAgent)) {
    return 'BlackBerry'
  }
  if (/Mac/i.test(userAgent)) {
    return 'Mac OS X'
  }
  if (/Linux/.test(userAgent)) {
    return 'Linux'
  }
  if (/CrOS/.test(userAgent)) {
    return 'Chrome OS'
  }
  return ''
}

export function browserInfo() {
  return {
    browser: browser(),
    browserVersion: browserVersion(),
    device: device(),
    os: os(),
  }
}
