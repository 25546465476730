import { type Context, createContext, type PropsWithChildren, useCallback, useContext, useReducer } from 'react'

import * as R from 'ramda'

import { OverviewNominationVersionType } from '../generated/graphql'

import type { VersionedNominationRequest } from '../models/models'

type NominationRequestBridgeState = {
  nominationId: string | null
  nominationVersionId: string | null
  nominationRequest: VersionedNominationRequest | null
  nominationVersionType: OverviewNominationVersionType | null
}

interface NominationRequestBridgeInterface extends NominationRequestBridgeState {
  setNativeNominationRequest(
    nominationId: string,
    nominationVersionId: string,
    nominationRequest: VersionedNominationRequest
  ): void
  setExternalNominationRequest(
    nominationId: string,
    nominationVersionId: string,
    nominationRequest: VersionedNominationRequest | null
  ): void
  togglePreSelectionFor(bargeId: string): void
  togglePreExclusionFor(bargeId: string): void
  preselectedBarges(): string[]
  excludedBarges(): string[]
  clearNominationRequest(): void
}

const stub = (): never => {
  throw new Error('You forgot to wrap your component in <NominationRequestBridgeProvider>.')
}

const initialNominationRequestState: NominationRequestBridgeState = {
  nominationId: null,
  nominationVersionId: null,
  nominationRequest: null,
  nominationVersionType: null,
}

const initialState: NominationRequestBridgeInterface = {
  ...initialNominationRequestState,
  setNativeNominationRequest: stub,
  setExternalNominationRequest: stub,
  togglePreSelectionFor: stub,
  togglePreExclusionFor: stub,
  preselectedBarges: stub,
  excludedBarges: stub,
  clearNominationRequest: stub,
}

const NominationRequestBridge: Context<NominationRequestBridgeInterface> =
  createContext<NominationRequestBridgeInterface>(initialState)

enum ActionTypes {
  SET_NATIVE_NOMINATION_REQUEST = 'SET_NATIVE_NOMINATION_REQUEST',
  SET_EXTERNAL_NOMINATION_REQUEST = 'SET_EXTERNAL_NOMINATION_REQUEST',
  TOGGLE_PRE_SELECTION = 'TOGGLE_PRE_SELECTION',
  TOGGLE_PRE_EXCLUSION = 'TOGGLE_PRE_EXCLUSION',
  CLEAR = 'CLEAR',
}

type ActionPayload = {
  nominationId: string
  nominationVersionId: string
  nominationRequest: VersionedNominationRequest
}
type ToggleActionPayload = { bargeId: string }
type Action =
  | {
      type: ActionTypes.SET_NATIVE_NOMINATION_REQUEST | ActionTypes.SET_EXTERNAL_NOMINATION_REQUEST
      payload: ActionPayload
    }
  | { type: ActionTypes.TOGGLE_PRE_SELECTION | ActionTypes.TOGGLE_PRE_EXCLUSION; payload: ToggleActionPayload }
  | { type: ActionTypes.CLEAR }

function toggleInCollections<T>(item: T, target: T[], mirror: T[]): [T[], T[]] {
  if (R.indexOf(item, target) >= 0) {
    return [R.reject(R.equals(item), target), mirror]
  }

  return [R.append(item, target), R.reject(R.equals(item), mirror)]
}

function updateInclusions(state: NominationRequestBridgeState, toggledBargeId: string): NominationRequestBridgeState {
  if (!state.nominationRequest) return state

  const { excludeBargeIds, includeBargeIds } = state.nominationRequest.towConfiguration
  const [updatedIncludes, updatedExcludes] = toggleInCollections(toggledBargeId, includeBargeIds, excludeBargeIds)
  return R.pipe(
    R.assocPath<string[], NominationRequestBridgeState>(
      ['nominationRequest', 'towConfiguration', 'includeBargeIds'],
      updatedIncludes
    ),
    R.assocPath<string[], NominationRequestBridgeState>(
      ['nominationRequest', 'towConfiguration', 'excludeBargeIds'],
      updatedExcludes
    )
  )(state)
}

function updateExclusions(state: NominationRequestBridgeState, toggledBargeId: string): NominationRequestBridgeState {
  if (!state.nominationRequest) return state

  const { excludeBargeIds, includeBargeIds } = state.nominationRequest.towConfiguration
  const [updatedExcludes, updatedIncludes] = toggleInCollections(toggledBargeId, excludeBargeIds, includeBargeIds)
  return R.pipe(
    R.assocPath<string[], NominationRequestBridgeState>(
      ['nominationRequest', 'towConfiguration', 'includeBargeIds'],
      updatedIncludes
    ),
    R.assocPath<string[], NominationRequestBridgeState>(
      ['nominationRequest', 'towConfiguration', 'excludeBargeIds'],
      updatedExcludes
    )
  )(state)
}

const reducer = (state: NominationRequestBridgeState, action: Action) => {
  switch (action.type) {
    case ActionTypes.SET_NATIVE_NOMINATION_REQUEST:
      return {
        nominationId: action.payload.nominationId,
        nominationVersionId: action.payload.nominationVersionId,
        nominationRequest: action.payload.nominationRequest,
        nominationVersionType: OverviewNominationVersionType.Native,
      }
    case ActionTypes.SET_EXTERNAL_NOMINATION_REQUEST:
      return {
        nominationId: action.payload.nominationId,
        nominationVersionId: action.payload.nominationVersionId,
        nominationRequest: action.payload.nominationRequest,
        nominationVersionType: OverviewNominationVersionType.External,
      }
    case ActionTypes.TOGGLE_PRE_SELECTION:
      if (!state.nominationRequest) return state

      return updateInclusions(state, action.payload.bargeId)
    case ActionTypes.TOGGLE_PRE_EXCLUSION:
      if (!state.nominationRequest) return state

      return updateExclusions(state, action.payload.bargeId)
    case ActionTypes.CLEAR:
      return initialState
    default:
      return state
  }
}

const NominationRequestBridgeProvider = ({ children }: PropsWithChildren) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const setNativeNominationRequest = useCallback(
    (nominationId: string, nominationVersionId: string, nominationRequest: VersionedNominationRequest) => {
      dispatch({
        type: ActionTypes.SET_NATIVE_NOMINATION_REQUEST,
        payload: { nominationId, nominationVersionId, nominationRequest },
      })
    },
    [dispatch]
  )

  const setExternalNominationRequest = useCallback(
    (nominationId: string, nominationVersionId: string, nominationRequest: VersionedNominationRequest) => {
      dispatch({
        type: ActionTypes.SET_EXTERNAL_NOMINATION_REQUEST,
        payload: { nominationId, nominationVersionId, nominationRequest },
      })
    },
    [dispatch]
  )

  const togglePreSelectionFor = useCallback(
    (bargeId: string) => {
      dispatch({
        type: ActionTypes.TOGGLE_PRE_SELECTION,
        payload: { bargeId },
      })
    },
    [dispatch]
  )

  const togglePreExclusionFor = useCallback(
    (bargeId: string) => {
      dispatch({
        type: ActionTypes.TOGGLE_PRE_EXCLUSION,
        payload: { bargeId },
      })
    },
    [dispatch]
  )

  const clearNominationRequest = useCallback(() => {
    dispatch({ type: ActionTypes.CLEAR })
  }, [dispatch])

  const preselectedBarges = useCallback(() => {
    return state.nominationRequest?.towConfiguration.includeBargeIds || []
  }, [state])

  const excludedBarges = useCallback(() => {
    return state.nominationRequest?.towConfiguration.excludeBargeIds || []
  }, [state])

  const providerOps: NominationRequestBridgeInterface = {
    ...state,
    setNativeNominationRequest,
    setExternalNominationRequest,
    togglePreSelectionFor,
    togglePreExclusionFor,
    preselectedBarges,
    excludedBarges,
    clearNominationRequest,
  }

  return <NominationRequestBridge.Provider value={providerOps}>{children}</NominationRequestBridge.Provider>
}

export default NominationRequestBridgeProvider

export const useNominationRequestBridge: () => NominationRequestBridgeInterface = () =>
  useContext(NominationRequestBridge)
