import { ArrowBack } from '@mui/icons-material'

import styles from './SubMenuHeader.module.scss'

type SubMenuHeaderProps = Readonly<{
  onBack: () => void
}>

export const SubMenuHeader = (props: SubMenuHeaderProps) => (
  <div className={styles.header}>
    <button onClick={props.onBack} className={styles.backButton}>
      <span className="sr-only">Go back</span>
      <ArrowBack />
    </button>
  </div>
)
